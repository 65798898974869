import axios from 'axios';

import * as actionTypes from './actionTypes';
import moment from 'moment'

import { calcMapBoundaries } from '../../utils/calculus';


export const refuelingsSuccess = data => {
    
    let markers = []
    if(Object.entries(data.refuel).length !== 0)
        markers = data.refuel.filter((marker) => {
            if(marker.lon === "" || marker.lat === "") return false;
            return {
                'longitude': marker.lon,
                'latitude': marker.lat
            }
        });

    return {
        type: actionTypes.REFUELINGS_SUCCESS, 
        data: data,
        bounds: markers.length > 0 ? calcMapBoundaries(markers, 0.005) : null
    };
}

export const refuelingsFail = (error) => {
    return {
        type: actionTypes.REFUELINGS_FAIL,
        error: error
    };
};

export const getRefuelings = (deviceId = 0, date_end = 0) => {

    console.log(date_end)

    let url = process.env.REACT_APP_GEB_MIDDLEWARE + '/dettaglio?device_id=' + deviceId
    if(date_end != 0) {
        let tempDateStart = moment(date_end).format('YYYY-MM-DD') + 'T00:00:00+00:00'
        let tempDateEnd = moment(date_end).format('YYYY-MM-DD') + 'T23:59:59+00:00'

        tempDateStart = encodeURIComponent(tempDateStart).replace(/%20/, "+");
        tempDateEnd = encodeURIComponent(tempDateEnd).replace(/%20/, "+");
        url = url + '&date_start='+tempDateStart + '&date_end='+tempDateEnd;
    }
    return dispatch => {
        axios.get(url)
        .then(response => {
            if(response.data.retCode === "000") {
                //console.log(response.data)
                dispatch(refuelingsSuccess(response.data.info));
            } else {
                dispatch(refuelingsFail(response.data.info.error));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(refuelingsFail(err));
        })
    }
}

export const resetRefuelings = () => {
    return {
        type: actionTypes.RESET_REFUELINGS
    };
}
/*
export const selectedRefuelingDataGroup = (selectedGroup = 0) => {
    dispatch({
        return {
            type: actionTypes.FILTER_REFUELING_DATA
        }
    })
}
*/

export const getGestori = () => {
    return dispatch => {
        axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/gestori',{
            headers: {
            'Content-Type': 'application/json',
            }
        })
        .then(response => {
            if(response.data.retCode === "000") {
                console.log(response.data)
                dispatch(gestoriSuccess(response.data.info));
            } else {
                dispatch(gestoriFail(response.data.info.error));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(gestoriFail(err));
        })
    }
}

export const gestoriSuccess = data => {

    let dataArray = Object.values(data.gestore)
    const temp = dataArray.sort(function(a, b) {
        if(a < b) { return -1 }
        if(a > b) { return 1 }
        return false;
    });
    const options = []

    options.push({ label: "Qualsiasi Gestore", value: 0 })

    temp.map((item, index) => {
        options.push({
            value: item,
            label: item
        })
        return false;
        
    });

    return {
        type: actionTypes.GESTORI_SUCCESS, 
        data: options,
    };
}

export const gestoriFail = (error) => {
    return {
        type: actionTypes.GESTORI_FAIL,
        error: error
    };
};

// Used in Cerca Rifornimenti con checkbox
export const cercaRifornimenti = (targa = '0', km = '0', importo = '0', dataStart = null, dataEnd = null, gestore = '0') => {
    return dispatch => {

        let data = {}
        //if(targa != 0) data["targa"] = targa
        //if(gestore != 0) data["gestore"] = gestore
        data = {
            "targa": targa,
            "km": km == '' ? '0' : km,
            "importo": importo == '' ? '0' : importo,
            "data_inizio": dataStart,
            "data_fine": dataEnd,
            "gestore": (gestore == null) ? 0: gestore
        }

        axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/cerca_rifornimenti', data)
        .then(response => {
            if(response.data.retCode === "000") {
                //console.log(response.data)
                dispatch(cercaRifSuccess(response.data.info));
            } else {
                dispatch(cercaRifFail(response.data.info.error));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(cercaRifFail(err));
        })
    }
}

export const cercaRifSuccess = (receivedData) => {
    /*
    receivedData.refuel.map((item) => {
        if(receivedData.carta_carburanti.refuel_id == item.refuel_id)
            item['automatico'] = true
    });
    console.log(receivedData)
    */

    //console.log("DATA: " + receivedData.refuel.length)

    /*
    let tempCercaRif = {}

    tempCercaRif['carta_carburanti'] = receivedData['carta_carburanti'];

    tempCercaRif['refuel'] = receivedData.refuel.filter((refuel) => {
        let refuelPercentage = receivedData.refuel.fuel_stop - receivedData.refuel.fuel_start;
        if(refuel.fuel_station_distance != "" ) {
            // Filtro per eliminare dall'array quelli non importanti. Distanza < 250m e con un rifornimento < X
            if(
                refuel.fuel_station_distance > parseInt(process.env.REACT_APP_LIMITE_DISTANZA_DAL_DISTRIBUTORE)
                || 
                refuelPercentage < parseInt(process.env.REACT_APP_LIMITE_RIFORNIMENTO_PERCENTUALE)
            ) {
                return false;
            } else { return true;}
        }
    });
    */

    //console.log("TEMP: " + tempCercaRif.refuel.length)
    return {
        type: actionTypes.CERCA_RIF_SUCCESS, 
        //data: tempCercaRif, // dati filtrati
        data: receivedData
    };
}

export const cercaRifFail = (error) => {
    return {
        type: actionTypes.CERCA_RIF_FAIL,
        error: error
    };
};

/**/
export const setSpinnerRefuel = (spinner) => {
    return {
        type: actionTypes.SET_SPINNER_REFUEL,
        spinner: spinner
    }
}

export const setManualCheckbox = (vR, checked) => {
    return dispatch => {
    let data = {
            "refuel_id": vR.refuel_id,
            "checked": checked
        }

        axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/update_refuel_manual', data)
        .then(response => {
            if(response.data.retCode === "000") {
                //console.log(response.data)
                dispatch(updateManualSuccess(response.data.info, vR, checked));
            } else {
                dispatch(updateManualFail(response.data.info.error));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(updateManualFail(err));
        })
    }
}


export const updateManualSuccess = (data, vR, checked) => {
    return {
        type: actionTypes.UPDATE_CHECKBOX_SUCCESS, 
        data: data,
        vR: vR,
        checked: checked
    };
}

export const updateManualFail = (error) => {
    return {
        type: actionTypes.UPDATE_CHECKBOX_FAIL,
        error: error
    };
};

export const setCercaFilter = (filterName, filterValue) => {
    //console.log(filterName, filterValue)
    return {
        type: actionTypes.SET_CERCA_RIF_FILTERS,
        filterName: filterName,
        filterValue: filterValue
    }
}


export const setSuspictiousCheckbox = (vR, checked) => {
    return dispatch => {
    let data = {
            "refuel_id": vR.refuel_id,
            "checked": checked
        }

        axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/update_refuel_suspictious', data)
        .then(response => {
            if(response.data.retCode === "000") {
                //console.log(response.data)
                dispatch(updateSuspictiousSuccess(response.data.info, vR, checked));
            } else {
                dispatch(updateSuspictiousFail(response.data.info.error));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(updateSuspictiousFail(err));
        })
    }
}


export const updateSuspictiousSuccess = (data, vR, checked) => {
    return {
        type: actionTypes.UPDATE_SUSPICTIOUS_CHECKBOX_SUCCESS, 
        data: data,
        vR: vR,
        checked: checked
    };
}

export const updateSuspictiousFail = (error) => {
    return {
        type: actionTypes.UPDATE_SUSPICTIOUS_CHECKBOX_FAIL,
        error: error
    };
};


export const saveSpecificLiters = (vR, sL) => {
    return dispatch => {

        let data = {
            "refuel_id": vR.refuel_id,
            "sL": sL
        }

        axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/update_specific_liters  ', data)
        .then(response => {
            if(response.data.retCode === "000") {
                //console.log(response.data)
                dispatch(updateSLSuccess(vR, sL));
            } else {
                dispatch(updateSLFail(response.data.info.error));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(updateSLFail(err));
        })
    }
}


export const updateSLSuccess = (vR, sL) => {
    console.log(sL)
    return {
        type: actionTypes.UPDATE_SPECIFIC_LITERS_SUCCESS, 
        vR: vR,
        sL:  sL === 0 ? "": sL
    };
}

export const updateSLFail = (error) => {
    return {
        type: actionTypes.UPDATE_SPECIFIC_LITERS_FAIL,
        error: error
    };
};
