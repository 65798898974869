import React from 'react';

import GCFLogo from '../../assets/images/logo_gcf.png';

import  classes from './Logo.module.css'

const Logo = (props) => {
    return (
        <div className={classes.Logo} style={{height: props.height}}>
            <img src={GCFLogo} alt="GCF-Logo" />
        </div>
    );
}

export default Logo;