import React, { useState } from 'react'

import { Toast } from 'react-bootstrap'


export const MyToast = (props) => {

    const [show, setShow] = useState(true);
    const toggleShow = () => setShow(!show);

    return (
        <Toast 
            show={show} 
            onClose={toggleShow}
            delay={5000}
            autohide
            style={{
                position: 'absolute',
                top: 20,
                left: 20,
                }}
            >
            <Toast.Header>
                <strong className="mr-auto">Notifica</strong>
            </Toast.Header>
            <Toast.Body>{props.message}</Toast.Body>
        </Toast>
    )
}



export default MyToast