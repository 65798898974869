import React from 'react';
import 'leaflet/dist/leaflet.css';

import { Sidebar, Tab } from "react-leaflet-sidetabs";
import { FiChevronRight, FiSearch } from "react-icons/fi";

import { connect } from 'react-redux';

import FiltroSV from './SV/Filtro/Filtro'
import FiltroCC from './CC/Filtro/Filtro'


const Summary = ({devices}) => {
    return (
        <div style={{float: 'right', width: '55%', fontSize: '14px', border: '1px solid #ced4da', borderRadius: '0.25rem', padding: '0.375rem 0.75rem', backgroundColor: '#e9ecef'}}>
            <div style={{float: 'left', width: '110px'}}>                
                TOTALE MEZZI:
            </div>
            <div style={{float: "right"}}>
                {devices.length} 
            </div>
        </div>
    );
    
}

class SideBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            selected: 'search',
            options: []
        };
        this.options = []
    }

    onClose() {
        this.setState({collapsed: true});
    }
    onOpen(id) {
        this.setState({
        collapsed: false,
        selected: id,
        })
    }


    render() {
        return (
                <Sidebar
                    id="sidebar"
                    position="right"
                    collapsed={this.state.collapsed}
                    closeIcon={<FiChevronRight />}
                    selected={this.state.selected}
                    onOpen={this.onOpen.bind(this)}
                    onClose={this.onClose.bind(this)}
                >
                    <Tab id="search" header="Filtri" icon={<FiSearch />}>
                        <br/>
                        {(this.props.role === "am" || this.props.role === "sv") ? <FiltroSV /> : <FiltroCC />}
                        <br />

                        <Summary devices={this.props.devices}/>


                    </Tab>

                </Sidebar>
          
            );

    }
}

const mapStateToProps = state => {
    return {
        devices: state.posizioni.devices,
        role: state.auth.tokenDecoded.role
    }
}



export default connect(mapStateToProps, null)(SideBar);