import React, { useState, useEffect, useRef, forwardRef } from 'react'
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

import Moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/it';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone'

import { Modal, Card, Button, Table, OverlayTrigger, Tooltip, Row, Col, Alert, Tab, Tabs, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import { FaCheck, FaFolderOpen, FaTrashAlt } from 'react-icons/fa';
import { GrAlert, GrCircleInformation } from 'react-icons/gr'
import { MdAddCircleOutline, MdSyncAlt} from 'react-icons/md'
import { AiOutlineSync, AiOutlineCheckCircle, AiOutlineWarning, AiOutlineInfoCircle } from 'react-icons/ai'
import axios from 'axios';
import { formatBytes, cutName } from '../../utils/utils';

import { BiErrorCircle } from "react-icons/bi";

import classes from './MechanicLogBookModal.module.css'

import MyToast from '../../components/UI/Toast/MyToast'


const  MechanicLogBookModal = (props) => {


  const hiddenFileInput = useRef(null);
  const purchaseHiddenFileInput = useRef(null);

  const [numPratica, setNumPratica] = useState('');

  const [dateAuthorization, setDateAuthorization] = useState(null);
  const [dateInsertAcquisti, setDateInsertAcquisti] = useState(null);

  const [ userAuthorization, setUserAuthorization] = useState(null);
  const [ userInsert, setUserInsert] = useState(null);

  const [fornitore, setFornitore] = useState({value: '0', label: "Altro"});
  const [purchaseFornitore, setPurchaseFornitore] = useState({value: '0', label: "Altro"});

  //const [ dataIntervento, setDataIntervento ] = useState(new Date());
  const [description, setDescription] = useState('');
  const [type, setType] = useState({value: 5, label: "Intervento Mecanico"}); // Tipo intervento
  const [nuovoIntervento, setNuovoIntervento] = useState(''); // Nuovo intervento da inserire quando non è trovato dal utente.
  
  const [amount, setAmount] = useState(0.0);
  const [purchaseAmount, setPurchaseAmount] = useState();

  const [docTypeId, setDocTypeId] = useState({value: 2, label: "Offerta"}); // Fattura, Offerta, Altro...
  const [files, setFiles] = useState([]); // files to be uploded in interventi
  const [purchaseFiles, setPurchaseFiles] = useState([]); // files to be uploded in acquisti
  const [filesPratica, setFilesPratica] = useState([]); // files gia in DB in interventi
  const [fileDunp, setFileDunp] = useState(null)
  const [fileDunpSize, setFileDunpSize] = useState(null)

  const [purchaseFileDunp, setPurchaseFileDunp] = useState(null)
  const [purchaseFileDunpSize, setPurchaseFileDunpSize] = useState(null)

  const [dBOriginCode, setDbOriginCode] = useState(null);
  
  const [error, setError] = useState(null)
  const [warning, setWarning] = useState(null)
  const [info, setInfo] = useState(null)

  const [praticaInfoLoading, setPraticaInfoLoading] = useState(false);

  const [operationOptions, setOperationOptions] = useState(null);

  const [docTypeOptions, setDocTypeOptions] = useState([
    //{label: 'Altro', value: 0},
    {label: 'Fattura', value: 1},
    {label: 'Offerta', value: 2},
  ])
  const [fornitoriOptions, setFornitoriOptions] = useState([
    {label: 'Altro', value: '0'},
    {label: 'Franco Gomme', value: 'Franco Gomme'},
    {label: 'Gala', value: 'Gala'},
    {label: 'Novi Auto snc', value: 'Novi Auto snc'},
    {label: 'Rbm', value: 'Rbm'},
    {label: 'Soa', value: 'Soa'},
    {label: 'Sportline Pneus', value: 'Sportline Pneus'},
    {label: 'Tagliabue Gomme', value: 'Tagliabue Gomme'},
  ])

    const [purchaseFornitoriOptions, setPurchaseFornitoriOptions] = useState([
    {label: 'Altro', value: '0'},
    {label: 'Franco Gomme', value: 'Franco Gomme'},
    {label: 'Gala', value: 'Gala'},
    {label: 'Novi Auto snc', value: 'Novi Auto snc'},
    {label: 'Rbm', value: 'Rbm'},
    {label: 'Soa', value: 'Soa'},
    {label: 'Sportline Pneus', value: 'Sportline Pneus'},
    {label: 'Tagliabue Gomme', value: 'Tagliabue Gomme'},
  ])


  const [numBolla, setNumBolla] = useState('');
  const [purchaseOptions, setPurchaseOptions] = useState([
    {label: 'Altro', value: 0},
    {label: 'Gomme', value: 1}
  ])
  const [purchase, setPurchase] = useState({value: 1, label: "Gomme"}); // Tipo intervento
  const [purchaseDescription, setPurchaseDescription] = useState('');
  const [purchaseDate, setPurchaseDate] = useState(new Date())

  const [quantitaGomme, setQuantitaGomme] = useState('');
  const [codiceGomme, setCodiceGomme] = useState('');
  const [codiceGommeValidity, setCodiceGommeValidity] =  useState(false)
  //const codiceGommeValidity = useRef(false);

  const [showA, setShowA] = useState(false);
  const toggleShowA = (flag) => setShowA(flag);

  const [showConfirmationInterventiModal, setShowConfirmationInterventiModal] = useState(false)
  const [showConfirmationPurchaseModal, setShowConfirmationPurchaseModal] = useState(false)


  const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <button className={classes.dateIntervento} onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  useEffect(() => {
    if(operationOptions === null) getOperations()
  },[operationOptions])

/**/
  useEffect(() => {
    console.log("Dentro useEffect", codiceGommeValidity)
  }, [codiceGommeValidity])

  const onKeyDownHandler = e => {
    if (e.keyCode === 13) {
        getPraticaInfo()
    }
  };



  const getOperations = async (data) => {
    await axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/operations', {
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        if(res.data.retCode === "000") {
            let tempOperations = []
            //tempOperations.push({label: 'Altro', value: 0})
            res.data.info.map(op => {
                    tempOperations.push({
                        value: op.operazione_id,
                        label: op.descrizione_operazione
                    })
            })
            const temp = tempOperations.sort(function(a, b) {
                if(a.label < b.label) { return -1 }
                if(a.label > b.label) { return 1 }
                return false;
            });

            setOperationOptions([...temp])
        }
    }).catch(err => {
        console.error('/operations request fail: ' + err);
    });
  }



  const closeMechanicLogBookModal = (e) => {
    props.onSetMechLogBookModalVisibility(false)
    cleanForm()
    //toggleShowA(false)
  }

  const cleanForm = () => {
    setNumPratica('')
    //setType({label: 'Altro', value: 0})
    setType({value: 5, label: "Intervento Mecanico"})
    setDocTypeId({value: 2, label: "Offerta"})
    setFornitore({value: 0, label: "Altri"})
    setAmount(0.0)
    setDescription('')
    setFiles([])
    setFilesPratica([])
    setFileDunp(null)
    setFileDunpSize(null)
    setError(null)
    setWarning(null)
    setInfo(null)
    setDbOriginCode(null)
    setDateAuthorization(null)
    setDateInsertAcquisti(null)
    setUserAuthorization(null)
    setUserInsert(null)
    setNuovoIntervento('')
  }

    const cleanPurchaseForm = () => {
    setNumBolla('')
    //setType({label: 'Altro', value: 0})
    setPurchaseDate(new Date())
    setPurchaseFornitore({value: 0, label: "Altri"})
    setPurchaseDescription('')
    setPurchaseFiles([])
    setFileDunp(null)
    setFileDunpSize(null)
    setError(null)
    setWarning(null)
    setInfo(null)
    setDbOriginCode(null)
  }

  const checkAmountValidity = (value) => {
    let isValid = true;
    const pattern = /^([0-9]*[\.\,]?[0-9]{0,2}|[\.\,][0-9]+)$/;
    isValid = pattern.test(value) && isValid  
    if(isValid) setAmount(value)
    else setAmount(0.0)
  }
  const checkPurchaseAmountValidity = (value) => {
    let isValid = true;
    const pattern = /^([0-9]*[\.\,]?[0-9]{0,2}|[\.\,][0-9]+)$/;
    isValid = pattern.test(value) && isValid
    if(isValid) setPurchaseAmount(value)
    //else setPurchaseAmount(0.0)
  }

  const checkQuantitaGommeValidity = (value) => {
    let isValid = true;
    //const pattern = /^\d*$/;
    const pattern = /^(?:[1-9]|)$/;
    isValid = pattern.test(value) && isValid
    if(isValid) setQuantitaGomme(value)
    //else setPurchaseAmount(0.0)
  }

  const checkIsNumber = (value) => {
    let isValid = true;
    const pattern = /^[0-9]*$/;
    isValid = pattern.test(value) && isValid
    if(value === '') isValid = false;
    return isValid;
  }

  const upadteDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const purchaseHandleClick = (event) => {
    purchaseHiddenFileInput.current.click();
  };


  const changeHandler = (event) => {
        //setError(null)
        
        // Find if file is present in files Array
        if(isAlreadyPresent(event.target.files[0]))
            setWarning('File è gia presente in questo form')
        else {
            let tempFiles = files;
            tempFiles.push(event.target.files[0]);
            setFiles([...tempFiles]);
        }
  };

  const purchaseChangeHandler = (event) => {
        //setError(null)
        
        // Find if file is present in files Array
        if(isAlreadyPurchasePresent(event.target.files[0]))
            setWarning('File è gia presente in questo form')
        else {
            let tempFiles = purchaseFiles;
            tempFiles.push(event.target.files[0]);
            setPurchaseFiles([...tempFiles]);
        }
  };


  const formatNotAccepted = (format) => {
    if(format !== 'pdf' &&
        format !== 'txt' &&
        format !== 'jpg' &&
        format !== 'jpeg' &&
        format !== 'gif' &&
        format !== 'png')
        return true
    return false
  }

  const sizeNotAccepted = (size, sizeLimit = 20) => {

    const kilobytes = parseFloat(size / 1024).toFixed(0)
    const megabytes = parseFloat(kilobytes / 1024).toFixed(0)

    if(megabytes > sizeLimit) return true
    return false      
  }

  const isAlreadyPresent = (file) => {
    
    const fileFound = files.filter(fileItem => {
        if (file.name === fileItem.name) return true
    })
    if(fileFound.length > 0) return true;
    return false
  }

    const isAlreadyPurchasePresent = (file) => {

    const fileFound = purchaseFiles.filter(fileItem => {
        if (file.name === fileItem.name) return true
    })
    if(fileFound.length > 0) return true;
    return false
  }


  // Function Used for Tooltip Layout
  const checkFileValidity = (file, source) => {

    // Check format
    let extension = ''
    if (source === 1)  { // files to upload
        extension = file.name.split(".")[1]
    } else if (source === 2) {//files that has been uploaded
        extension = file.filename.split(".")[1]
    }

    if(formatNotAccepted(extension)
    ) {
        return (
            <>
                <OverlayTrigger
                key={'top'}
                placement={'right'}
                overlay={
                    <Tooltip id={`tooltip-${'top'}`}>
                        {'Questo file non sarà salvato.'}
                        <br/>
                        {'Formato "' + extension + '" non supportato.'}
                        <br/>
                        {'Formati supportati: pdf, txt, jpg, jpeg, gif, png'}
                    </Tooltip>
                }
                >
                    <BiErrorCircle fill='red'/>
                </OverlayTrigger>
            </>
        );
    }

    // Check size
    if(sizeNotAccepted(file.size)) {
        return (
            <>
                <OverlayTrigger
                key={'top'}
                placement={'right'}
                overlay={
                    <Tooltip id={`tooltip-${'top'}`}>
                        {'Questo file non sarà salvato.'}
                        <br/>
                        {'Limite file superato.'}
                        <br/> 
                        {'Dimensione massima accettata: '}
                        <br/>
                        {'20 MB'}
                    </Tooltip>
                }
                >
                    <BiErrorCircle fill='red'/>
                </OverlayTrigger>
            </>
        );
    }

    return (
        <>
            <OverlayTrigger
                key={'top'}
                placement={'right'}
                overlay={
                    <Tooltip id={`tooltip-${'top'}`}>
                        {'File aggiunto corrrettamente.'}                        
                    </Tooltip>
                }
                >
                    <FaCheck fill='green' />
                </OverlayTrigger>
        </>
    );

  }

  const checkFile = (file) => {
    // Check format
    const extension = file.name.split(".")[1]
    if(formatNotAccepted(extension)) return false;
    if(sizeNotAccepted(file.size)) return false;
    return true;
  }

  const checkFormInterventi = () => {

    //Se l'intervento e cambio gomme -> c'è un documento aggiunto e non c'è bisogno del numero pratica
    if (type.value == 1) return true; 
    if(description === '') return false;
    if(amount === undefined || amount === '' || amount === 0 || amount === '0') return false;
    if(type === undefined || type === '') return false;
    if(numPratica === undefined || type === '') return false;
    //if(files.length === 0) return false;
    return true
  }

  const checkFormPurchase = () => {
    if(purchaseAmount === undefined || purchaseAmount === '' || purchaseAmount === 0 || purchaseAmount === '0') return false;
    if(numBolla === undefined || numBolla === '') return false;
    if(codiceGomme === undefined || codiceGomme === '') return false;
    if(quantitaGomme === undefined || quantitaGomme === '' || quantitaGomme === 0 || quantitaGomme === '0') return false;
    if(!codiceGommeValidity) return false;
    //if(files.length === 0) return false;
    return true
  }


  const sendFormInterventi = (event) => {
    if (!checkFormInterventi()) { return false; }
    const formData = new FormData();
    files.map((file) => {
      if(checkFile(file))
        formData.append('files', file)
    })
    formData.append("device_id", props.device.device_id);
    formData.append("doc_type", "intervento");
    formData.append("doc_type_id", docTypeId.value);
    formData.append("tipo", type.value);
    formData.append("nuovoTipoIntervento", nuovoIntervento)


    let tempAmount = amount
    console.log(typeof(tempAmount))
    let tempAmountString = tempAmount.toString()
    console.log(typeof(tempAmountString))
    var temp = parseFloat(tempAmountString.replace(",", ".")); //Transform virgolete in .

    formData.append("importo", temp);
    formData.append("fat_description", description);
    formData.append("multi_filename", false);
    formData.append("multi_extra", false);

    formData.append('num_pratica', numPratica);
    if(dBOriginCode) formData.append("dBOriginCode", dBOriginCode)
    if(dateAuthorization) formData.append('date_authorization', moment.tz(dateAuthorization, 'Europe/Rome').format())
    if(dateInsertAcquisti) formData.append('date_insert_acquisti', moment.tz(dateInsertAcquisti, 'Europe/Rome').format())

    if(userAuthorization) formData.append('utente_approvazione', userAuthorization)
    if(userInsert) formData.append('utente_inserimento', userInsert)

    formData.append("fornitore", fornitore.value);

    if(dBOriginCode === "000") { //dati già presenti sul DB Amerigo
        formData.append("updatePratica", 1)
    } else {
        formData.delete("updatePratica")
    }
    
    axios.post(
        process.env.REACT_APP_GEB_MIDDLEWARE + '/fat_multiupload',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    ).then(response => {
        if(response.data.retCode === "000") {
            cleanForm()
            props.onSetMechLogBookModalVisibility(false)
            setShowConfirmationInterventiModal(false)
        } else {
            setWarning(response.data.info)

            let tempFiles = files;
            tempFiles.pop();
            setFiles([...tempFiles]);
        }
    })
    .catch(err => {
        console.log(err);
        setError(err)
    })
  }

  const checkCodiceGomme = (value) => {

    let isValid = true;
    //const pattern = /^(\d{1,3}(\.\d)?)(?:[\/])(\d{1,2}(\.\d)?)([R])(\d{1,2}(?:\.\d)?) (\d{1,2}) (\w.?)( [A-Za-z+]{1,3})?$/;
    const pattern = /^(\d{3})\/(\d{2}) ?R(\d{2}(\.\d)?) (\d{2,3}) ([A-Z])(( M\+S)?)$/;
    isValid = pattern.test(value) && isValid
    console.log(value, isValid)
    if ( isValid )
        setCodiceGommeValidity(current => true)
    else setCodiceGommeValidity(current => false)

    setCodiceGomme(value)

  }

  const getPraticaInfo = () => {

    //setType({label: 'Altro', value: 0})
    setType({value: 5, label: "Intervento Mecanico"})
    setDocTypeId({value: 2, label: "Offerta"})
    setFornitore({value: 0, label: "Altri"})
    setAmount(0.0)
    setDescription('')
    setFiles( files => [])
    setFilesPratica([])
    setFileDunp(null)
    setFileDunpSize(null)
    setError(null)
    setWarning(null)
    setInfo(null)
    setDbOriginCode(null)
    setDateAuthorization(null)
    setDateInsertAcquisti(null)
    setUserAuthorization(null)
    setUserInsert()

    if(checkIsNumber(numPratica)) {
        setPraticaInfoLoading(true)
        axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/praticaInfo/' + numPratica, {}, {
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            setPraticaInfoLoading(false)
            if(res.data.retCode === "000") {

                if(res.data.info === "") {
                    console.log("nessuna pratica trovata")
                    setWarning("Numero di pratica non trovata sul server di Acquisti")
                } else {
                
                    if (res.data.info.messageCode == "000") { // Dati carricati dal DB amerigo
                        setWarning(res.data.info.messageDB)
                        
                        setDbOriginCode("000");

                        if (res.data.info.descrizione) setDescription(res.data.info.descrizione)
                        //if (res.data.info.importo) setAmount(res.data.info.importo.toString())
                        if (res.data.info.importo) setAmount(res.data.info.importo)

                        const fornitoreTemp = res.data.info.logbook_extra.fornitore.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
                        if (res.data.info.logbook_extra.fornitore) setFornitore({value: fornitoreTemp, label: fornitoreTemp})

                        if (res.data.info.logbook_extra.data_approvazione) setDateAuthorization(res.data.info.logbook_extra.data_approvazione)
                        if (res.data.info.logbook_extra.data_inserimento) setDateInsertAcquisti(res.data.info.logbook_extra.data_inserimento)

                        const typeTemp = operationOptions.map(option => option.value == res.data.info.operazione_id)
                        if (res.data.info.operazione_id) setType({ value: typeTemp.value, label: typeTemp.label})

                        const docTypeTemp = docTypeOptions.map(option => option.value == res.data.info.logbook_extra.tipo_documento)
                        if (res.data.info.logbook_extra.tipo_documento) setDocTypeId({value: docTypeTemp.value, label: docTypeTemp.label})

                        if(res.data.info.logbook_extra.nuovoIntervento) setNuovoIntervento(res.data.info.logbook_extra.nuovoIntervento)

                        // Se è stata conclusa non si puo piu toccare, no?
                        if(res.data.info.data_approvazione) {
                            
                        }

                        if(res.data.info.files) {
                            let tempFiles = [];
                            res.data.info.files.map(file => {
                                tempFiles.push(file);
                            });
                            //setFilesPratica([...tempFiles]);
                            setFilesPratica([...res.data.info.files]);
                        }

                        if( res.data.info.dunpFile) {
                            if (res.data.info.dunpFile.documento_url) setFileDunp(res.data.info.dunpFile.documento_url)
                            if (res.data.info.dunpFile.documento_mb) setFileDunpSize(res.data.info.dunpFile.documento_mb)
                        }


                    }else if(res.data.info.messageCode === "001") { // db Acquisti
                        setInfo(res.data.info.messageDB)

                        setDbOriginCode("001");
                        if (res.data.info.descrizione) setDescription(res.data.info.descrizione)
                        if (res.data.info.importo) setAmount(res.data.info.importo.toString())
                        
                        if(res.data.info.fornitore) {
                            const fornitoreTemp = res.data.info.fornitore.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
                            setFornitore({value: fornitoreTemp, label: fornitoreTemp})
                        }

                        setType({value: 5, label: "Intervento Mecanico"})
                        setDocTypeId({ value: 2, label: "Offerta"})

                        if (res.data.info.data_approvazione) setDateAuthorization(res.data.info.data_approvazione)
                        if (res.data.info.data_inserimento) setDateInsertAcquisti(res.data.info.data_inserimento)
                        if (res.data.info.utente_approvazione) setUserAuthorization(res.data.info.utente_approvazione)
                        if (res.data.info.utente_inserimento) setUserInsert(res.data.info.utente_inserimento)

                        if (res.data.info.documento_url) setFileDunp(res.data.info.documento_url)
                        if (res.data.info.documento_mb) setFileDunpSize(res.data.info.documento_mb)


                    } else if (res.data.info.messageCode === "002") { // pratica non trovata da nessuna parte
                        setError(res.data.info.messageDB)
                        setDbOriginCode("002");
                        setType({value: 5, label: "Intervento Mecanico"})
                        setDocTypeId({value: 2, label: "Offerta"})
                        setFornitore({value: 0, label: "Altri"})
                        setAmount(0.0)
                        setDescription('')
                        setFiles([])

                        setDateAuthorization(null)
                        setDateInsertAcquisti(null)
                        setUserAuthorization(null)
                        setUserInsert(null)

                        setNuovoIntervento("")

                    }

                }

            } else {
                console.warn('GET /praticaInfo request error: ' + res.data.info);
                setWarning(res.data.info)
            }
        }).catch(err => {
            console.error('GET /praticaInfo request fail: ' + err);
        });
    } else
        setWarning("Il campo Numero Pratica accetta soltanto caratteri numerici")
  }

  const sendFormPurchase = (event) => {
    //if (!checkFormPurchase()) { return false; }
    const formData = new FormData();
    purchaseFiles.map((file) => {
      if(checkFile(file))
        formData.append('files', file);
    });
    formData.append("device_id", props.device.device_id);
    formData.append("doc_type", "acquisto_gomme");
    formData.append("num_bolla", numBolla);
    formData.append("purchase_date", purchaseDate);

    formData.append("purchase_fornitore", purchaseFornitore.value);
    formData.append("codice_gomma", codiceGomme);
    formData.append("quantita_gomme", quantitaGomme);
    //formData.append("purchase_description", purchaseDescription);

    let tempAmount = purchaseAmount
    let tempAmountString = tempAmount.toString()
    var temp = parseFloat(tempAmountString.replace(",", ".")); //Transform virgolete in .
    formData.append("purchase_importo", temp);

    formData.append("fat_description", purchaseDescription);
    formData.append("multi_filename", false);
    formData.append("multi_extra", false);

    formData.append("utente_inserimento", props.tokenDecoded.username);

    axios.post(
        process.env.REACT_APP_GEB_MIDDLEWARE + '/fat_multiupload_purchase',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    ).then(response => {
        if(response.data.retCode === "000") {
            cleanPurchaseForm()
            props.onSetMechLogBookModalVisibility(false)
            setShowConfirmationPurchaseModal(false)
        } else {
            setWarning(response.data.info)

            let tempFiles = files;
            tempFiles.pop();
            setFiles([...tempFiles]);
        }
    })
    .catch(err => {
        console.log(err);
        setError(err)
    })

  }

  const getFileRow = (file, size) => {
    const extension = file.slice(-3);
    const words = file.split('/');
    return (
    <tr key="fileDunp">
        <td>
            <a href={file} target="_blank">{cutName(words[5], 40)}</a>
        </td>
        <td>{extension}</td>
        <td>{formatBytes(size * 1024 * 1024)}</td>
        <td></td>
    </tr>
    )
  }

  const [activeTab, setActiveTab] = useState('interventi');


  return (
    <div>
    <Modal
        show={props.mechLogBookModalVisibility}
        backdrop="static" 
        keyboard={false}
        dialogClassName="modal-50w"
        >
        <Modal.Header>
            <Modal.Title>
                Nuovo Intervento Mecanico per mezzo {props.device && props.device.targa ? (<div className={classes.targa}>{props.device.targa}</div>) : ''}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Tabs 
                defaultActiveKey="interventi"
                id="uncontrolled-tab-example"
                onSelect={(k) => setActiveTab(k)}
                className="mb-3">

                <Tab eventKey="interventi" title="Interventi">

                    <Alert key="primary" variant="primary" show={showA} dismissible={true}>
                        Numero pratica non trovata sul server di acquisti.
                    </Alert>

                    <Row>
                        <Col>
                            {error ? 
                                <div className={classes.error} style={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', padding: '5px', marginBottom: '4px'}}>
                                    <GrAlert style={{marginTop: '4px', marginLeft: '10px'}}/>
                                    <div style={{marginLeft: '15px'}}>
                                        {error? error:''}
                                    </div>
                                    
                                </div>
                            :
                                <></>
                            }
                            {warning ? 
                                <div className={classes.warning} style={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', padding: '5px', marginBottom: '4px'}}>
                                    <GrCircleInformation style={{marginTop: '4px', marginLeft: '10px'}}/>
                                    <div style={{marginLeft: '15px'}}>
                                        {warning? warning:''}
                                    </div>
                                    
                                </div>
                            :
                                <></>
                            }
                            {info ? 
                                <div className={classes.info} style={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', padding: '5px', marginBottom: '4px'}}>
                                    <GrCircleInformation style={{marginTop: '4px', marginLeft: '10px'}}/>
                                    <div style={{marginLeft: '15px'}}>
                                        {info? info:''}
                                    </div>
                                    
                                </div>
                            :
                                <></>
                            }
                        </Col>
                    </Row>

                    <div className={classes.flexContainer_button}>
                        <span style={{marginTop:'12px', marginLeft: '0px', marginRight: '0px'}}>
                            Numero Pratica: 
                        </span>
                        <input
                            type='text'
                            size={7}
                            style={{height: 38, marginTop: 5, borderRadius: '5px', marginLeft: '15px'}}
                            className={classes.text_inputs}
                            onChange={(e) => setNumPratica(e.target.value)}
                            value={numPratica}
                            onKeyDown={(e) => onKeyDownHandler(e)}
                        />
                        &nbsp;&nbsp;
                        

                        <Button 
                            variant="outline-primary" 
                            style={{fontSize: "23px", padding: '0px 7px 0px 7px', marginTop: '-3px'}}
                            size="sm"
                            title="Clicca per autocompilare la pratica."
                        
                            onClick={(e) => {
                                getPraticaInfo()

                            }}
                        >   
                            {praticaInfoLoading ? 
                                <AiOutlineSync  className={classes.spinner} />
                                :
                                <MdSyncAlt />
                            }
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {dateInsertAcquisti ? 
                            (
                            <div style={{display: "inline", fontSize: "12px", fontStyle: "italic"}}>
                                Data Inserimento:
                                &nbsp;&nbsp;
                                {Moment(dateInsertAcquisti).format('D MMM yyyy, HH:mm')}
                            </div>
                            )
                        : ''}
                    </div>

                    <div className={classes.flexContainer}>

                        <span style={{marginTop:'12px'}}>Descrizione:</span>
                        <input
                            type='text'
                            size={67}
                            style={{ height: 38, marginTop: 1, marginBottom: 1, marginLeft: '44px', borderRadius: '5px' }}
                            className={classes.text_inputs}
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                        />

                    </div>

                    {/**/
                    <div className={classes.flexContainer}>
                        <span style={{marginTop:'12px', marginRight: '56px'}}>Fornitore:</span>
                        <Select
                            className={classes.flexItem}
                            options={fornitoriOptions}
                            onChange={(e) => setFornitore(e)}
                            value={fornitore}
                        />

                        <span style={{width: '75px'}}></span>

                        
                        <span style={{marginTop:'12px', marginLeft: '0px', marginRight: '0px'}}>Importo (€): </span>
                        <input
                            type='text'
                            size={6}
                            style={{height: 38, marginTop: 5, borderRadius: '5px', marginLeft: '9px'}}
                            className={classes.text_inputs}
                            onChange={(e) => checkAmountValidity(e.target.value)}
                            value={amount}
                            //value={(docTypeId.value===1 || docTypeId.value===2) ? amount: 0.0}
                            //disabled={docTypeId.value===0 || docTypeId.value === undefined || docTypeId.value === '' ? true:false}
                            //disabled={disableImport()}
                        />

                        <span style={{width: '74px'}}></span>
                        {/** 
                        <Select
                            className={classes.flexItem_tp}
                            options={docTypeOptions}
                            onChange={(e) => setDocTypeId(e)}
                            value={docTypeId}
                        />
                        */}
                        </div>
                    }

                    <div style={{margin: '10px 0px 10px 0px', borderTop: '1px solid #dee2e6'}}></div>


                    <div className={classes.flexContainer}>
                        <span style={{marginTop:'12px', marginRight: '13px'}}>Tipo Intervento:</span>
                        <Select
                            className={classes.flexItem_intervento_tipo}
                            options={operationOptions}
                            onChange={(e) => setType(e)}
                            value={type}
                        />

                        <span style={{width: '22px'}}></span>

                        {
                            type.value === 0 ?
                                <div className={classes.flexContainer}>
                                    <span style={{marginTop:'12px'}}><GrCircleInformation title="Inserisci Nuovo Tipo di Intervento"/></span>
                                    
                                    <input
                                        type='text'
                                        size={40}
                                        style={{ height: 38, marginTop: 5, marginBottom: 1, marginLeft: '3px', borderRadius: '5px' }}
                                        className={classes.text_inputs}
                                        onChange={(e) => setNuovoIntervento(e.target.value)}
                                        value={nuovoIntervento}
                                    />
                                </div>
                            :
                                <></>
                        }
                    </div>

                    
                    <span style={{width: '100%', height: 20, display:'block'}}></span>

                    <Row>
                        <Col>

                            <Table>
                                <thead>
                                    <tr>
                                        <th>Nome File</th>
                                        <th>Formato</th>
                                        <th>Dimensioni</th>
                                        <th style={{textAlign:'center'}}>
                                            <Button 
                                                size='sm' 
                                                onClick={handleClick}
                                                style={{height: 36, width: 36}}
                                                >
                                                <MdAddCircleOutline style={{fontSize:'1.4em', marginLeft: '-1px'}}/>
                                            </Button>
                                            <input
                                                type='file'
                                                name='files'
                                                id='files'
                                                multiple
                                                ref={hiddenFileInput}
                                                onChange={(e) => changeHandler(e)}
                                                style={{ display: 'none' }}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {files && files.length > 0 ?
                                        files.map((file, index) => {
                                            console.log(file)
                                            return (
                                                <tr key={index}>
                                                    <td>{cutName(file.name, 40)}</td>
                                                    <td>{file.type}</td>
                                                    <td>{formatBytes(file.size)}</td>
                                                    <td>{checkFileValidity(file, 1)}</td>
                                                </tr>
                                            );
                                        })
                                        :
                                        <></>
                                    }
                                    {filesPratica && filesPratica.length > 0 ?
                                        filesPratica.map((file, index) => {
                                            console.log(file)
                                            const extension = file.filename.split(".")[1]
                                            return (
                                                <tr key={index}>
                                                    <td>{cutName(file.filename, 40)}</td>
                                                    <td>{file.type ? file.type : extension}</td>
                                                    <td>{file.size ? formatBytes(file.size) : ''}</td>
                                                    <td>{checkFileValidity(file, 2)}</td>
                                                </tr>
                                            );
                                        })
                                        :
                                        <></>
                                    }

                                    {fileDunp ? 
                                        getFileRow(fileDunp, fileDunpSize):<></>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    

                    <span style={{width: '100%', height: 20, display:'block'}}></span>

                </Tab>


                {/* Acquisti*/}
                <Tab eventKey="acquisto_gomme" title="Acquisto gomme">

                    <div className={classes.flexContainer}>
                        <span style={{marginTop:'12px', marginLeft: '0px', marginRight: '0px'}}>
                            Numero Bolla: 
                        </span>
                        <input
                            type='text'
                            size={7}
                            style={{height: 38, marginTop: 5, borderRadius: '5px', marginLeft: '27px'}}
                            className={classes.text_inputs}
                            onChange={(e) => setNumBolla(e.target.value)}
                            value={numBolla}
                            //onKeyDown={(e) => onKeyDownHandler(e)}
                        />
                        <span style={{width: '128px'}}></span>
                       

                        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}>

                            <span style={{marginTop:'12px', marginRight: '20px'}}>Data Acquisto:</span>

                            <DatePicker 
                                locale="it"
                                selected={purchaseDate} 
                                dateFormat='dd/MM/yyyy'
                                style={{textAlign: "center"}}
                                customInput={<CustomDatePickerInput />}
                                onChange={(date) => setPurchaseDate(date)}
                                value={purchaseDate}
                            />

                        </div>
                        <span style={{width: '56px'}}></span>
                        
                        <span style={{marginTop:'12px', marginLeft: '0px', marginRight: '0px'}}>Importo (€): </span>
                        <input
                            type='text'
                            size={7}
                            id="purchaseAmount"
                            style={{height: 38, marginTop: 5, borderRadius: '5px', marginLeft: '30px'}}
                            className={classes.text_inputs}
                            onChange={(e) => checkPurchaseAmountValidity(e.target.value)}
                            value={purchaseAmount}
                            //value={(docTypeId.value===1 || docTypeId.value===2) ? amount: 0.0}
                            //disabled={docTypeId.value===0 || docTypeId.value === undefined || docTypeId.value === '' ? true:false}
                            //disabled={disableImport()}
                        />

                    </div>

                    <div className={classes.flexContainer}>
                        <span style={{marginTop:'12px', marginRight: '56px'}}>Fornitore:</span>
                        <Select
                            className={classes.fornitore_fornitore_gomme}
                            options={purchaseFornitoriOptions}
                            onChange={(e) => setPurchaseFornitore(e)}
                            value={purchaseFornitore}
                        />

                        <span style={{width: '35px'}}></span>

                        <span style={{marginTop:'12px', marginLeft: '0px', marginRight: '0px'}}>
                            Codice:
                            <>
                                <OverlayTrigger
                                    key={'top'}
                                    placement={'right'}
                                    overlay={
                                        <Tooltip id={`tooltip-${'top'}`} html={true}>
                                            {<>
                                            CODICI ESEMPIO: <br />
                                            343/80R23.2 88 Y <br />
                                            343/34R23 88 T <br />
                                            343/34R23 88 H M+S <br />
                                            343/60 R16 82 H M+S
                                            </>}
                                        </Tooltip>
                                    }
                                >
                                    <AiOutlineInfoCircle />
                                </OverlayTrigger>
                            </>

                        </span>
                        <input
                            type='text'
                            size={15}
                            style={{height: 38, marginTop: 5, borderRadius: '5px', marginLeft: '10px'}}
                            //className={classes.text_inputs}
                            className={codiceGommeValidity ? classes.text_inputs + ' ' + classes.valid : classes.text_inputs + ' ' + classes.error}
                            onChange={(e) => checkCodiceGomme(e.target.value)}
                            value={codiceGomme}
                        />


                        {
                        //<div style={{fontSize: "30px", padding: '0px 7px 0px 7px', marginTop: '-3px'}}>
                        //    {codiceGommeValidity ?
                        //        <AiOutlineCheckCircle />
                        //        :
                        //        <AiOutlineWarning title="Codice Non Valido" />
                        //    }
                        //</div>
                            }
                        <span style={{width: '30px'}}></span>

                        <span style={{marginTop:'12px', marginLeft: '0px', marginRight: '0px'}}>
                            Num. Gomme: 
                        </span>
                        <input
                            type='text'
                            size={7}
                            style={{height: 38, marginTop: 5, borderRadius: '5px', marginLeft: '10px'}}
                            className={classes.text_inputs}
                            onChange={(e) => checkQuantitaGommeValidity(e.target.value)}
                            value={quantitaGomme}
                            //onKeyDown={(e) => onKeyDownHandler(e)}
                        />
                    </div>


                    <div className={classes.flexContainer}>

                        <span style={{marginTop:'12px'}}>Descrizione:</span>
                        <input
                            type='text'
                            size={73}
                            style={{ height: 38, marginTop: 1, marginBottom: 1, marginLeft: '40px', borderRadius: '5px' }}
                            className={classes.text_inputs}
                            onChange={(e) => setPurchaseDescription(e.target.value)}
                            value={purchaseDescription}
                        />

                    </div>

                    <span style={{width: '100%', height: 20, display:'block'}}></span>

                    <Row>
                        <Col>

                            <Table>
                                <thead>
                                    <tr>
                                        <th>Nome File</th>
                                        <th>Formato</th>
                                        <th>Dimensioni</th>
                                        <th style={{textAlign:'center'}}>
                                            <Button 
                                                size='sm' 
                                                onClick={purchaseHandleClick}
                                                style={{height: 36, width: 36}}
                                                >
                                                <MdAddCircleOutline style={{fontSize:'1.4em', marginLeft: '-1px'}}/>
                                            </Button>
                                            <input
                                                type='file'
                                                name='purchaseFiles'
                                                id='purchaseFiles'
                                                multiple
                                                ref={purchaseHiddenFileInput}
                                                onChange={(e) => purchaseChangeHandler(e)}
                                                style={{ display: 'none' }}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {purchaseFiles && purchaseFiles.length > 0 ?
                                        purchaseFiles.map((file, index) => {
                                            console.log(file)
                                            return (
                                                <tr key={index}>
                                                    <td>{cutName(file.name, 40)}</td>
                                                    <td>{file.type}</td>
                                                    <td>{formatBytes(file.size)}</td>
                                                    <td>{checkFileValidity(file, 1)}</td>
                                                </tr>
                                            );
                                        })
                                        :
                                        <></>
                                    }
                                    {
                                    //    filesPratica && filesPratica.length > 0 ?
                                    //    filesPratica.map((file, index) => {
                                    //        console.log(file)
                                    //        const extension = file.filename.split(".")[1]
                                    //        return (
                                    //            <tr key={index}>
                                    //                <td>{cutName(file.filename, 40)}</td>
                                    //                <td>{file.type ? file.type : extension}</td>
                                    //                <td>{file.size ? formatBytes(file.size) : ''}</td>
                                    //                <td>{checkFileValidity(file, 2)}</td>
                                    //            </tr>
                                    //        );
                                    //    })
                                    //    :
                                    //    <></>
                                    }

                                    {
                                    //    purchaseFileDunp ?
                                    //    getFileRow(purchaseFileDunp, purchaseFileDunpSize):<></>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    

                    <span style={{width: '100%', height: 20, display:'block'}}></span>
                </Tab>

            </Tabs>

        </Modal.Body>
        <Modal.Footer>
            <button class="btn btn-primary" onClick={(e) => {
                if(activeTab == 'interventi')
                    if(dBOriginCode === "000") setShowConfirmationInterventiModal(true)
                    else sendFormInterventi(e)
                if(activeTab == 'acquisto_gomme')
                    if(checkFormPurchase()) {
                        setShowConfirmationPurchaseModal(true)
                    }
            }}>
                Salva
            </button>
            <button class="btn btn-secondary" onClick={(e) => closeMechanicLogBookModal(e)}>
                Chiudi
            </button>
        </Modal.Footer>
    </Modal>

    <Modal
        show={showConfirmationInterventiModal}
        backdrop="static" 
        keyboard={false}
        dialogClassName="modal-20w"
        >
        <Modal.Header>
            <Modal.Title>
                Confermi salvataggio modifiche?
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Attenzione. Clickando su "Conferma" la pratica sara agiornata su Amerigo.
        </Modal.Body>
        <Modal.Footer>
            <button class="btn btn-primary" onClick={(e) => sendFormInterventi(e)}>
                Conferma
            </button>
            <button class="btn btn-secondary" onClick={(e) => setShowConfirmationInterventiModal(false)}>
                Annulla
            </button>
        </Modal.Footer>
      </Modal>

    <Modal
        show={showConfirmationPurchaseModal}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-20w"
        >
        <Modal.Header>
            <Modal.Title>
                Confermi nuovo acquisto gomme?
            </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
            <button class="btn btn-primary" onClick={(e) => sendFormPurchase(e)}>
                Conferma
            </button>
            <button class="btn btn-secondary" onClick={(e) => setShowConfirmationPurchaseModal(false)}>
                Annulla
            </button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

const mapStateToProps = state => {
    return {
        mechLogBookModalVisibility : state.garage.mechLogBookModalVisibility,
        device: state.garage.device,
        tokenDecoded: state.auth.tokenDecoded,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetMechLogBookModalVisibility: (isVisible) => dispatch(actions.setMechLogBookModalVisibility(isVisible))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (MechanicLogBookModal)
