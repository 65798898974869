import React from 'react'

import { setVehicleIcon } from '../../../utils/gebIcons'

import classes from './Legend.module.css'

import classes_devices from '../Devices.module.css'

import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';


const Legend = (props) => {

    const states = ['In Movimento', 'Mezzo fermo', 'Sconosciuto'];



    const setDeviceClass = i => {
        if(i===0) return classes_devices.class1_active_device;
        if(i===1) return classes_devices.class1_nonActive_device;
        else return classes_devices.class1_unknown_device;
    }

    const countDevicesStates = () => {
      let type_1 = 0; // in movimento
      let type_2 = 0; // fermi
      let type_3 = 0; // sconosciuti
      props.devices.map((device, index) => {
          if((device.stato_sconosciuto)) type_3++;
          else if(device.speed > 0 ) type_1++;
          else type_2++;
          return false;
      });
      return [type_1, type_2, type_3]
    }

    const getDevices = (typeId) => {
        if(props.selectedState === typeId) {
            // desactivate item 
            props.onGetDevices(props.role, props.userMail, props.mailList, 0);
            props.onSetSelectedState(0)
        } else {
            props.onGetDevices(props.role, props.userMail, props.mailList, typeId);
            props.onSetSelectedState(typeId)
        }
    }

    const counter = countDevicesStates();

    return (
        <div style={{position: "absolute", bottom: "10px", left: "10px", zIndex: "9999"}}>
            <div style={{backgroundColor:"white", padding: "7px", borderRadius: "15px", border: "1px solid #ccc", opacity: "0.9"}}>
                {states.map((state, i) => {
                    const isSelected = (props.selectedState === (i+1)) && (props.selectedState > 0) ? true : false
                    //if(isSelected) console.log(state)
                    return (
                        <div 
                            key={i} 
                            style={{padding: "2px", marginBottom: (i === states.length-1) ? "0px" : "10px"}} 
                        >
                            <img 
                                src={setVehicleIcon({'veicolo': 'car'})}
                                width="20"
                                height="25"
                                className={setDeviceClass(i) + ' ' + (isSelected ? classes.selected : '')}
                                onClick={() => getDevices(i+1)}
                                alt="vehicle legend"
                            >
                            </img>
                            <span style={{marginLeft: "10px"}}>
                                {state}
                            </span>
                            <span style={{float: "right"}}> 
                                &nbsp;&nbsp;&nbsp;
                                <span style={{backgroundColor: "#ccc", padding: "4px", borderRadius: "4px"}}>{counter[i]}</span>
                            </span> 
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        role: state.auth.tokenDecoded.role,
        userMail: state.auth.tokenDecoded.username,
        mailList: state.posizioni.mailList,
        devices: state.posizioni.devices,
        selectedState: state.posizioni.selectedState

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetDevices: (role, userMail, mailList, selectedState) => dispatch(actions.getDevices(role, userMail, mailList, selectedState)),
        onSetSelectedState: (selectedState) => dispatch(actions.setSelectedState(selectedState))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Legend);
