import React, { Fragment } from 'react';
import {Marker, Tooltip} from 'react-leaflet';

import { Icon } from "leaflet";

import { setVehicleIcon } from '../../utils/gebIcons'

import MarkerPopup from './MarkerPopup/MarkerPopup'

import classes from './Devices.module.css'

import * as actions from '../../store/actions/index';

import { connect } from 'react-redux';


const Devices = (props) => {

    const { devices, selectedDevice, zoom} = props;

    const setDeviceClass = (device= null) => {
        if(device.veicolo === null || device.stato_sconosciuto) return classes.class1_unknown_device;
        if(device.speed === 0) return classes.class1_nonActive_device;
        else return classes.class1_active_device;
    }

    if(devices && devices.length > 0) {
        return (
            devices.map((device, index) => {

                const isSelected = (selectedDevice && selectedDevice.deviceId === device.deviceId ? true : false)

                const capo = props.cc.filter(capo => capo.email === device.cc)[0]
                
                const initMarker = ref => {
                    if (ref && isSelected) {
                        ref.leafletElement.openPopup()
                        props.onSetSelectedDevice(null)
                    }
                }
                return(
                    
                    <Marker
                        ref={initMarker}
                        key={device.deviceId}
                        position={[device.latitude, device.longitude]}
                        icon={new Icon({
                            iconUrl: setVehicleIcon(device),
                            iconSize: [25, 41],
                            iconAnchor: [25, 41],
                            popupAnchor: [-10, -40],
                            className: setDeviceClass(device)
                        })}
                    >
                        <MarkerPopup cc={capo} device={device} role={props.role}/>
                        { true && zoom >= props.customZoom &&<Tooltip direction="bottom" permanent={true}>
                            {device.targa}
                        </Tooltip>}
                    </Marker>

                );
            })
        )
    }
    else return <Fragment></Fragment>
                        
}

const mapStateToProps = state => {
    return {
        cc: state.posizioni.cc,
        role: state.auth.tokenDecoded.role,
        customZoom: state.posizioni.customZoom
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetSelectedDevice: (device) => dispatch(actions.setSelectedDevice(device)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Devices);