import React from 'react';
import 'leaflet/dist/leaflet.css';

import { Sidebar, Tab as SideTab } from "react-leaflet-sidetabs";
import { FiChevronRight, FiSearch } from "react-icons/fi";

/*
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
*/

import classes from './MySideBar.module.css'

import MyStoricoFilter from './MyStoricoFilter/MyStoricoFilter'

import moment from 'moment'

import { withRouter } from "react-router";

class MySideBar extends React.Component {

        state = {
            collapsed: false,
            selected: 'search',
            pickDateFrom: moment().format('M/D/YYYY'),
            pickDateTo: moment().format('M/D/YYYY'),
            pickDate: new Date()
        };

    onClose() {
        this.setState({collapsed: true});
    }
    onOpen(id) {
        this.setState({
        collapsed: false,
        selected: id,
        })
    }

    onApplyDateRange(event, picker) {
        //TODO: put spinner
        //console.log(picker.startDate.format())
        //console.log(picker.endDate.format())
        //this.props.onInitDeviceMarkers(this.props.deviceId)
        this.props.onInitDeviceMarkers(this.props.deviceId, picker.startDate, picker.endDate)
        /*this.setState({
            startDate: picker.startDate.format('M/D/YYYY'),
            endDate: picker.endDate.format('M/D/YYYY')
        });        
        this.getDeviceMarkers(picker.startDate, picker.endDate)
        
        */

    }

    render() {
        return (

            <Sidebar
                id="sidebar"
                position="right"
                collapsed={this.state.collapsed}
                closeIcon={<FiChevronRight />}
                selected={this.state.selected}
                onOpen={this.onOpen.bind(this)}
                onClose={this.onClose.bind(this)}
                style={{width: "20px important!"}}
                className={classes.Sidebar}
            >
                <SideTab id="search" header="Filtri" icon={<FiSearch />}>

                    <MyStoricoFilter />                    

                </SideTab>

            </Sidebar>
        )      
    
    }
}

export default withRouter(MySideBar);
