import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import axios from 'axios';
import * as actions from '../../store/actions/index';

import NumberFormat from 'react-number-format';

import Moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/it';


import classes from './InterventiSelectedDevice.module.css'


const InterventiSelectedDevice = (props) => {

  const [deviceData, setDeviceData] = useState({});
  const [totalImport, setTotalImport] = useState(0.0);

  useEffect(() => {
    if(props.selectedDevice && props.selectedDevice.value != 0)
        fetchDeviceDetails(props.selectedDevice.label.split(" ")[0])
  }, [props.selectedDevice])
 
  const fetchDeviceDetails = async (targa) => {
    await axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/deviceDetails/' + targa, {
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        if(res.data.retCode === "000") {
            setDeviceData(res.data.info)
        }
    }).catch(err => {
        console.error('/operations request fail: ' + err);
        setDeviceData({})
    });
  }

  useEffect(() => {
    if (props.interventi && props.selectedDevice) {
        let tempImport = 0.0
        props.interventi.map((item) => {
            tempImport = tempImport + item.importo
        });
        setTotalImport(tempImport);
    }
  }, [props.selectedDevice, props.interventi]);

  if (props.selectedDevice && props.selectedDevice.value != 0)
    return (
        <>
            <div 
                className={classes.deviceContainer}

            >
                <Row>
                    <Col xs={1}>
                        <div style={{
                            display: 'flex', 
                            flexFlow: 'row wrap', 
                            flexDirection: 'column',
                            }}
                        >
                            {deviceData && deviceData.targa ?
                            <span style={{width: '250px', marginLeft: '10px', marginTop: '15px'}}>
                                <div className={classes.targa}>{deviceData.targa}</div>
                            </span>
                            : ''}
                        </div>
                    </Col>

                    <Col>
                        <div style={{
                            display: 'flex', 
                            flexFlow: 'row wrap', 
                            flexDirection: 'column',
                            marginBottom: "5px"
                            }}
                        >
                            <span style={{width: '250px', margin: 'auto', marginBottom: "5px"}}>
                                Capo Cantiere:&nbsp;
                                {deviceData.cognome + ' ' + deviceData.nome}
                            </span>
                            <span style={{width: '250px', margin: 'auto'}}>
                                Responsabile:&nbsp;
                                {deviceData.responsabile}
                            </span>
                        </div>
                    </Col>

                    <Col>
                        <div style={{
                            display: 'flex', 
                            flexFlow: 'row wrap', 
                            flexDirection: 'column',
                            marginBottom: "5px"
                            }}
                        >
                            <span style={{width: '250px', margin: 'auto', marginBottom: "5px"}}>
                                Stato: 
                                { deviceData.error_code !== ''
                                    ? <span className={classes.scaduta}>errore</span>
                                    : Moment(deviceData.fixtime) > Moment().subtract('days', 7) ? <span className={classes.regolare}>&nbsp;regolare</span> : <span className={classes.nd}>&nbsp;obsoleto</span>
                                }
                            </span>
                            <span style={{width: '250px', margin: 'auto'}}>Modello: {deviceData.marca + ' ' + deviceData.modello}</span>
                        </div>
                    </Col>

                    <Col>
                        <div style={{
                            display: 'flex', 
                            flexFlow: 'row wrap', 
                            flexDirection: 'column',
                            marginBottom: "5px"
                            }}
                        >
                            <span style={{width: '250px', margin: 'auto', marginBottom: "5px"}}>Posti: &nbsp;{deviceData.posti ? deviceData.posti : '?'}</span>
                            <span style={{width: '250px', margin: 'auto'}}>Tipo: {deviceData.veicolo}</span>
                            <span style={{width: '250px', margin: 'auto', marginBottom: "5px"}}>
                                Immatricolazione: &nbsp;
                                    {
                                        deviceData.immatricolazione && deviceData.immatricolazione !== "" ?
                                            Moment(deviceData.immatricolazione).format('D MMM yyyy')
                                        :
                                            <span className={classes.nd} title="Dato non disponibile">n/d</span>
                                    }

                            </span>
                        </div>
                    </Col>

                    <Col>
                        <div style={{
                            display: 'flex', 
                            flexFlow: 'row wrap', 
                            flexDirection: 'column',
                            marginBottom: "5px"
                            }}
                        >
                            <span style={{width: '250px', margin: 'auto', marginBottom: "5px"}}>
                                Km Totali: &nbsp;
                                { deviceData.can_errors
                                    ? <span className={classes.nd} title="Dato non disponibile">n/d</span>
                                    : <><NumberFormat value={Math.round(deviceData.km)} displayType={'text'} thousandSeparator={true} /> Km</>
                                }
                            </span>
                            <span style={{width: '250px', margin: 'auto', marginBottom: "5px"}}>
                                Km Media:&nbsp;
                                { deviceData.can_errors
                                    ? <span className={classes.nd} title="Dato non disponibile">n/d</span>
                                    : <><NumberFormat value={Math.round(deviceData.km_annui)} displayType={'text'} thousandSeparator={true} /> Km</>
                                }
                            </span>
                            <span style={{width: '250px', margin: 'auto', marginBottom: "5px"}}>
                                Km Ultimo Mese:&nbsp;
                                { deviceData.km_errors || deviceData.can_errors
                                    ? <span className={classes.nd} title="Dato non disponibile">n/d</span>
                                    : <><NumberFormat value={Math.round(deviceData.km_month)} displayType={'text'} thousandSeparator={true} /> Km</>
                                }
                            </span>
                        </div>
                    </Col>

                    <Col>
                        <div style={{
                            display: 'flex', 
                            flexFlow: 'row wrap', 
                            flexDirection: 'column',
                            marginBottom: "5px"
                            }}
                        >

                            <span style={{width: '250px', margin: '15px 0px 0px 0px', fontSize: 20 }}>
                                Importo Totale: &nbsp; 
                                <span style={{color: '#ff4d4d'}}>{totalImport} €</span>
                                { /*totalImport
                                    ? <span className={classes.nd} title="Dato non disponibile">0 €</span>
                                    : 
                                        <>
                                            <NumberFormat value={Math.round(totalImport)} displayType={'text'} thousandSeparator={true} />
                                                €
                                        </>
                                    */
                                }
                            </span>
                        </div>
                    </Col>

                </Row>
            </div>
        </>
    )
    else return <></>
}


const mapStateToProps = (state) => {
    console.log(state.interventi.selectedDevice)
    return {
        selectedDevice: state.interventi.filterTarga,
        interventi: state.interventi.interventi
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //onFetchDeviceInfo: (targa) => dispatch(actions.fetchDeviceInfo(targa))
    }
}

export default connect(mapStateToProps, null)(InterventiSelectedDevice);
