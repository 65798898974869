import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import InterventiFilters from './InterventiFilters'
import InterventiCharts from './InterventiCharts'
import InterventiSelectedDevice from './InterventiSelectedDevice'
import InterventiTable from './InterventiTable'

import AcquistoGommeTable from './AcquistoGommeTable'

import * as actions from '../../store/actions/index';

import { Container, Tabs, Tab } from 'react-bootstrap'

const Interventi = (props) => {

  const [activeTab, setActiveTab] = useState('interventi');


  useEffect(() => {
    props.onGetOperations()
  },[]);

  return (
    <Container fluid>
        <InterventiFilters />

        <div style={{height: '5px'}}></div>
        
        <Tabs 
          defaultActiveKey="interventi"
          id="uncontrolled-tab-example"
          onSelect={(k) => setActiveTab(k)}
          className="mb-3">

          <Tab eventKey="interventi" title="Interventi">
            <InterventiCharts />
            <InterventiSelectedDevice />
            <InterventiTable />
          </Tab>

          <Tab eventKey="acquisti_gomme" title="Acquisti Gomme">
            <AcquistoGommeTable />
          </Tab>

        </Tabs>

    </Container>
  )
}



const mapDispatchToProps = dispatch => {
  return {
    onGetOperations: () => dispatch(actions.getOperations())
  }
}

export default connect(null, mapDispatchToProps)(Interventi);
