import * as actionTypes from '../actions/actionTypes';

const initialState = {
    mechLogBookModalVisibility: false,
    device: null
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_MECHLOGBOOK_VISIBILITY:
            return {
                ...state,
                mechLogBookModalVisibility: action.isVisible,
                device: action.device
            }
        default:
            return state;
    }
}

export default reducer;