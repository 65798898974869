import axios from 'axios';
import {setAuthToken, decodeToken} from '../../utils/token'


import * as actionTypes from './actionTypes';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authFirstAccessStart = () => {
    return {
        type: actionTypes.AUTH_FIRST_ACCESS_START
    };
};

export const authSuccess = (token, password = null) => {
    const tokenDecoded = decodeToken(token);
    setAuthToken(token)

    window.dispatchEvent(new CustomEvent('loginSuccess', {
        detail: {token: token}
    }));

    if(tokenDecoded.state === 0) localStorage.removeItem('tempPassword')
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        tokenDecoded: tokenDecoded,
        tempPassword: (tokenDecoded.state === 1) ? password: null,
        isFirstAccess: tokenDecoded.state === 1

    };
};

export const firstAccesSuccess = (token) => {
    const tokenDecoded = decodeToken(token);
    //console.log(tokenDecoded)
    setAuthToken(token)
    return {
        type: actionTypes.FIRST_ACCESS_SUCCESS,
        idToken: token,
        tokenDecoded: tokenDecoded,
        isFirstAccess: false
    };
};

export const changePasswordSuccess = (token) => {
    const tokenDecoded = decodeToken(token);
    setAuthToken(token)
    return {
        type: actionTypes.CHANGE_PASSWORD_SUCCESS,
        idToken: token,
        tokenDecoded: tokenDecoded
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const firstAccessFail = (error) => {
    return {
        type: actionTypes.FIRST_ACCESS_FAIL,
        error: error
    };
};

export const changePasswordFail = (error) => {
    return {
        type: actionTypes.CHANGE_PASSWORD_FAIL,
        error: error
    };
};


export const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('username')
    localStorage.removeItem('tempPassword')
    setAuthToken()
    return {
        type: actionTypes.AUTH_LOGOUT
    }
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout())
        }, expirationTime * 1000)
    }
};

export const authFirstAccess = (username, tempPassword, newPassword, repeatPassword) =>{
    return dispatch => {
        dispatch(authFirstAccessStart())
        const params = new URLSearchParams();
        params.append('username', username)
        params.append('password', tempPassword)
        params.append('new_password', newPassword)
        params.append('repeat_password', repeatPassword)
        params.append('scope', 'amerigo')
        params.append('origin', 'geb-web')
        params.append('format', 'json')

    axios.post(process.env.REACT_APP_GEB_AUTH + '/password' , params, {
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        .then(response => {
            if(response.data.info.status === "success") {
                const expirationDate = new Date(new Date().getTime() + process.env.REACT_APP_TOKEN_EXPIRATION_TIME * 1000);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('token', response.data.info.token);

                //Next line is used in case user refreshes page while in firstLogin Form
                localStorage.removeItem('tempPassword')

                dispatch(firstAccesSuccess(response.data.info.token));


            } else {
                dispatch(firstAccessFail(response.data.info.error));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(authFail(err.response.info.data.error));
        })
    }
};

export const authChangePassword = (username, oldPassword, newPassword, repeatPassword) =>{
    return dispatch => {
        dispatch(authFirstAccessStart())
        const params = new URLSearchParams();
        params.append('username', username)
        params.append('password', oldPassword)
        params.append('new_password', newPassword)
        params.append('repeat_password', repeatPassword)
        params.append('scope', 'amerigo')
        params.append('origin', 'geb-web')
        params.append('format', 'json')

    axios.post(process.env.REACT_APP_GEB_AUTH + '/password' , params, {
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        .then(response => {
            if(response.data.info.status === "success") {
                const expirationDate = new Date(new Date().getTime() + process.env.REACT_APP_TOKEN_EXPIRATION_TIME * 1000);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('token', response.data.info.token);

                dispatch(changePasswordSuccess(response.data.info.token));


            } else {
                dispatch(changePasswordFail(response.data.info.error));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(authFail(err.response.info.data.error));
        })
    }
};


export const auth = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        const params = new URLSearchParams();
        params.append('username', username)
        params.append('password', password)
        params.append('origin', 'geb-web')
        params.append('scope', 'amerigo')
        params.append('format', 'json')

        axios.post(process.env.REACT_APP_GEB_AUTH + '/auth', params, {
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        .then(response => {
            if(response.data.info.status === "success") {
                const expirationDate = new Date(new Date().getTime() + process.env.REACT_APP_TOKEN_EXPIRATION_TIME * 1000);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('token', response.data.info.token);

                localStorage.setItem('tempPassword', password);
            
                dispatch(authSuccess(response.data.info.token, password));


            } else {
                dispatch(authFail(response.data.info.error));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(authFail(err));
        })

    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');

        if(!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if ( expirationDate <= new Date ()) {
                dispatch(logout());
            } else {
                //const username = localStorage.getItem('username');
                dispatch(authSuccess(token));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime())/1000 ));
            }
        }
    };
};

