// Autorizations
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

// First Access
export const AUTH_FIRST_ACCESS_START = 'AUTH_FIRST_ACCESS_START';
export const FIRST_ACCESS_SUCCESS = 'FIRST_ACCESS_SUCCESS';
export const FIRST_ACCESS_FAIL = 'FIRST_ACCESS_FAIL';

// Change Password
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL'

// Posizioni
export const POS_SUCCESS = 'POS_SUCCESS';
export const POS_FAIL = 'POS_FAIL';
export const CC_SUCCESS = 'CC_SUCCESS';
export const CC_FAIL = 'CC_FAIL';
export const FLY_TO_DEVICE = 'FLY_TO_DEVICE'
export const SET_MAIL_LIST = 'SET_MAIL_LIST'
export const CC_DEVICES_COUNTER_SUCCESS = 'CC_DEVICES_COUNTER_SUCCESS'
export const CC_DEVICES_COUNTER_FAIL = 'CC_DEVICES_COUNTER_FAIL'
export const SET_SELECTED_STATE = 'SET_SELECTED_STATE'
export const SET_PREFERENCES_OK = 'SET_PREFERENCES_OK'
export const SET_PREFERENCES_FAIL = 'SET_PREFERENCES_FAIL'

// Percorsi
export const SET_MAP_DATA = 'SET_MAP_DATA';
export const DEVICE_MARKERS_FAIL = 'DEVICE_MARKERS_FAIL';


// Storico
export const STORICO_SUCCESS = 'STORICO_SUCCESS';
export const STORICO_FAILED = 'STORICO_FAILED';
export const STORICO_LAST_POS_SUCCESS = 'STORICO_LAST_POS_SUCCESS';
export const STORICO_LAST_POS_FAIL = 'STORICO_LAST_POS_FAIL';

// Refuelings
export const REFUELINGS_SUCCESS = 'REFUELINGS_SUCCESS';
export const REFUELINGS_FAIL = 'REFUELINGS_FAIL';
export const RESET_REFUELINGS = 'RESET_REFUELINGS';
export const GESTORI_SUCCESS = 'GESTORI_SUCCESS';
export const GESTORI_FAIL = 'GESTORI_FAIL';
export const CERCA_RIF_SUCCESS = 'CERCA_RIF_SUCCESS';
export const CERCA_RIF_FAIL = 'CERCA_RIF_FAIL';
export const UPDATE_CHECKBOX_SUCCESS = 'UPDATE_CHECKBOX_SUCCESS';
export const UPDATE_CHECKBOX_FAIL = 'UPDATE_CHECKBOX_FAIL';
export const SET_SPINNER_REFUEL = 'SET_SPINNER_REFUEL';
export const SET_CERCA_RIF_FILTERS = 'SET_CERCA_RIF_FILTERS';
export const UPDATE_SUSPICTIOUS_CHECKBOX_SUCCESS = 'UPDATE_SUSPICTIOUS_CHECKBOX_SUCCESS';
export const UPDATE_SUSPICTIOUS_CHECKBOX_FAIL = 'UPDATE_SUSPICTIOUS_CHECKBOX_FAIL';
export const UPDATE_SPECIFIC_LITERS_SUCCESS = 'UPDATE_SPECIFIC_LITERS_SUCCESS';
export const UPDATE_SPECIFIC_LITERS_FAIL = 'UPDATE_SPECIFIC_LITERS_FAIL';

// Refunds
export const REFUNDS_SUCCESS = 'REFUNDS_SUCCESS';
export const REFUNDS_FAIL = 'REFUNDS_FAIL';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

// Movimenti
export const SET_DATE = 'SET_DATE';
export const SET_CALENDAR_DATE = 'SET_CALENDAR_DATE';
export const SET_CLICKED_TARGA = 'SET_CLICKED_TARGA';
export const SET_CC = 'SET_CC';
export const SET_TARGA = 'SET_TARGA';
export const SET_SPINNER = 'SET_SPINNER';
export const FETCH_MOVIMENTI_OK = 'FETCH_MOVIMENTI_OK';
export const FETCH_MOVIMENTI_FAIL = 'FETCH_MOVIMENTI_FAIL';
export const SET_MOVIMENTI_FILTER_PROMISCUOS = 'SET_MOVIMENTI_FILTER_PROMISCUOS';
export const SET_SINGLE_CC_DATA_OK = 'SET_SINGLE_CC_DATA_OK';
export const SET_SINGLE_CC_DATA_FAIL = 'SET_SINGLE_CC_DATA_FAIL';
export const CC_TARGHE_SUCCESS = 'CC_TARGHE_SUCCESS';
export const CC_TARGHE_FAIL = 'CC_TARGHE_FAIL';
export const RESET_TARGHE_OPTIONS = 'RESET_TARGHE_OPTIONS';

// Garage
export const SET_MECHLOGBOOK_VISIBILITY = 'SET_MECHLOGBOOK_VISIBILITY';

// Interventi
export const INTERVENTI_FILTER_SET_TARGA = 'INTERVENTI_FILTER_SET_TARGA';
export const INTERVENTI_FILTER_SET_DATE_START = 'INTERVENTI_FILTER_SET_DATE_START';
export const INTERVENTI_FILTER_SET_DATE_END = 'INTERVENTI_FILTER_SET_DATE_END';
export const INTERVENTI_FILTER_SET_INTERVENTO = 'INTERVENTI_FILTER_SET_INTERVENTO';
export const INTERVENTI_FILTER_SET_SORTING = 'INTERVENTI_FILTER_SET_SORTING';
export const INTERVENTI_FILTER_SET_MODELLO = 'INTERVENTI_FILTER_SET_MODELLO';
export const INTERVENTI_FETCH_INTERVENTI_OK = 'INTERVENTI_FETCH_INTERVENTI_OK';
export const INTERVENTI_FETCH_INTERVENTI_FAIL = 'INTERVENTI_FETCH_INTERVENTI_FAIL';
export const INTERVENTI_FETCH_TIPI_INTERVENTI_OK = 'INTERVENTI_FETCH_TIPI_INTERVENTI_OK';
export const INTERVENTI_FETCH_TIPI_INTERVENTI_FAIL = 'INTERVENTI_FETCH_TIPI_INTERVENTI_FAIL';
export const INTERVENTI_MODIFY_MODAL_VISIBILITY = 'INTERVENTI_MODIFY_MODAL_VISIBILITY';
export const INTERVENTI_CHANGE_INTERVENTO_AUTHORIZATION = 'INTERVENTI_CHANGE_INTERVENTO_AUTHORIZATION';
export const INTERVENTI_FILTER_SET_AUTHORIZATION = 'INTERVENTI_FILTER_SET_AUTHORIZATION';
export const INTERVENTI_FETCH_MODELLI_OK = 'INTERVENTI_FETCH_MODELLI_OK';
export const INTERVENTI_FETCH_MODELLI_FAIL = 'INTERVENTI_FETCH_MODELLI_FAIL';
export const INTERVENTI_FILTER_SET_NUM_PRATICA = 'INTERVENTI_FILTER_SET_NUM_PRATICA';
export const INTERVENTI_FILTER_SET_CODETYPE = 'INTERVENTI_FILTER_SET_CODETYPE';
