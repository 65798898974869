import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import classes from './Manual.module.css'

export const Manual = () => {

    const azzurro = {
        color: '#007bff'
    };

    const index = {
        position: 'sticky',
        top: '80px',
        backgroundColor: '#ddd',
        padding: '8px'
    };

    const anchor = {
        display: 'block',
        position: 'relative',
        top: '-80px',
        visibility: 'hidden'
    };

    return (
        <div class="container-fluid">
            <div class="text-center">
                <h1>Manuale Operativo Amerigo</h1>
                <p>Amerigo è il software per la gestione del parco mezzi su gomma di GCF e GEFER.</p>
                <p>&nbsp;</p>
            </div>
            <div class="row">
                <div class="col-2">
                    <div style={index}>
                        <h2>Indice</h2>

                        <ul>
                            <li>
                                <a href="#anchor_responsabile">Aggiornamento responsabile mezzo</a>
                            </li>
                            <li>
                                <a href="#anchor_scadenze">Aggiornamento scadenze</a>
                            </li>
                            <li>
                                <a href="#anchor_trasferimento">Trasferimento di un mezzo ad un altro cantiere</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-10">
                    <div style={anchor} id="anchor_responsabile"></div>
                    <h2>Aggiornamento responsabile mezzo</h2>
                    <p>La funzione ha l&#39;obiettivo di aggiornare il responsabile del mezzo.</p>

                    <p>Una volta effettuato il login, per modificare il responsabile di un mezzo, è necessario seguire i seguenti passaggi:</p>
                    <ol>
                        <li>cliccare sulla funzione Garage dal menù in alto a destra</li>
                        <li>cercare il mezzo interessato tramite l'utilizzo degli appositi filtri (Targa e Marca/modello)</li>
                        <li>
                             cliccare sul nome dell'attuale responsabile che compare in <span style={azzurro}>azzurro</span> sotto la targa
                             in corrispondenza del mezzo scelto (se non c'è un responsabile assegnato, comparià il testo <span style={azzurro}>n/a</span>)
                        </li>
                        <li>compilare il popup che compare e cliccare sul tasto "Salva"</li>
                    </ol>

                    <h4>Video esempio:</h4>
                    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/h4qRNpNtHBo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>


                    <br/><br/>
                    <div style={anchor} id="anchor_scadenze"></div>
                    <h2>Aggiornamento scadenze</h2>
                    <p>La funzione ha l&#39;obiettivo di aggiornare le date di scadenza relative a:</p>
                    <ul>
                        <li>revisione del mezzo</li>
                        <li>tassa di possesso (bollo auto)</li>
                        <li>assicurazione</li>
                    </ul>

                    <p>Una volta effettuato il login, per aggiornare le scadenze di un mezzo, è necessario seguire i seguenti passaggi:</p>
                    <ol>
                        <li>cliccare sulla funzione Garage dal menù in alto a destra</li>
                        <li>cercare il mezzo interessato tramite l'utilizzo degli appositi filtri (Targa e Marca/modello)</li>
                        <li>cliccare sulla scritta azzurra <span style={azzurro}>Aggiorna scadenze</span> che compare a destra in corrispondenza del mezzo</li>
                        <li>inserire le date che si vuole aggiornare e cliccare sul tasto "Salva"</li>
                    </ol>

                    <h4>Video esempio:</h4>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/7DfRgDKNVOc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><p>&nbsp;</p>


                    <br/><br/>
                    <div style={anchor} id="anchor_trasferimento"></div>
                    <h2>Trasferimento di un mezzo ad un altro cantiere</h2>
                    <p>La funzione ha l&#39;obiettivo di trasferire la gestione di un mezzo ad un altro cantiere</p>

                    <p>Una volta effettuato il login, per richiedere il trasferimento di un mezzo ad un altro capo cantiere, è necessario seguire i seguenti passaggi:</p>
                    <ol>
                        <li>cliccare sulla funzione Garage dal menù in alto a destra</li>
                        <li>cercare il mezzo interessato tramite l'utilizzo degli appositi filtri (Targa e Marca/modello)</li>
                        <li>cliccare sulla scritta azzurra <span style={azzurro}>Trasferisci ad altro cantiere</span> che compare a destra in corrispondenza del mezzo</li>
                        <li>inserire le informazioni (cognome e nome) del capo cantiere verso cui trasferire il mezzo nell'apposito campo</li>
                        <li>cliccare sul capo cantiere dalla lista che compare immediatamente sotto</li>
                        <li>cliccare sul tasto "Invia richiesta"</li>
                    </ol>

                    <p>
                        Se, invece, si è ricevuta una richiesta di trasferimento mezzo da un altro cantiere, subito dopo aver effettuato il Login, comparirà un apposito popup
                        con i dettagli del mezzo coinvolto. A questo punto è possibile scegliere, tramite i relativi pulsanti, se:
                    </p>
                    <ul>
                        <li>Accettare il trasferimento: il mezzo ora viene trasferito al capo cantiere che ha accettato la richiesta</li>
                        <li>Rifiutare il trasferimento: nessun mezzo viene trasferito</li>
                        <li>Postporre la richiesta di trasferimento: nessun mezzo viene trasferito, ma la richiesta verrà ripresentata al prossimo Login</li>
                    </ul>

                    <p>Sia in caso di accettazione che in caso di rifiuto, l'esito del trasferimento verrà notificato al capo cantiere che ha effettuato la richiesta</p>

                    <h4>Video esempio:</h4>
                    <p>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/-nIynoRg1XM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </p>

                </div>
            </div>
        </div>
    )
}

export default Manual;