import * as actionTypes from '../actions/actionTypes';
import Moment from 'moment';

const initialState = {
    year: parseInt(Moment().format('Y')),
    month: parseInt(Moment().format('M')),
    selectedDate: null,
    selectedTarga: {label: "Qualsiasi Targa", value: 0},
    selectedCC: {label: "Qualsiasi Capo Cantiere", value: 0},
    clickedTarga: null,
    spinner: false,
    movimenti: {
        days: {},
        movements: {}
    },
    colorFactor: 0,
    promiscMode: true,
    //questi 3 parametri seguenti sono usati per quando entra un utente tipo CC. Non ci sono CC da selezionare, dunque l'utente è il cc selezionato
    ccId: null,
    ccName: null,
    ccSurname: null,
    selectOptions: []
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_CALENDAR_DATE:
            return {
                ...state,
                year: action.year,
                month: action.month
            }
        case actionTypes.SET_DATE:
            return {
                ...state,
                selectedDate: action.selectedDate
            }
        case actionTypes.SET_CLICKED_TARGA:
            //console.log(action.clickedTarga)
            return {
                ...state,
                clickedTarga: action.clickedTarga
            }
        case actionTypes.SET_TARGA:
            return {
                ...state,
                selectedTarga: action.selectedTarga
            }
        case actionTypes.SET_CC:
            return {
                ...state,
                selectedCC: action.selectedCC
            }
        case actionTypes.SET_SPINNER:
            return {
                ...state,
                spinner: action.spinner
            }
        case actionTypes.FETCH_MOVIMENTI_OK:
            return {
                ...state,
                movimenti: action.movimenti,
                spinner: false,
                colorFactor: action.colorFactor
            }
        case actionTypes.FETCH_MOVIMENTI_FAIL:
            return {
                ...state,
                movimenti: {
                    days: {},
                    movements: {}
                },
                spinner: false,
                colorFactor: 0
            }
        case actionTypes.SET_MOVIMENTI_FILTER_PROMISCUOS:
            /*
            console.log(state.movimenti)
            let tempMovements = state.movimenti.movements.filter(item => {
                if(action.promiscMode && item.promiscuo) return true;
                //if(action.promiscMode && !item.promiscuo) return false;
                return false
            })

            

            let tempDays = state.movimenti.days;

            let movimenti = {}
            movimenti['movements'] = tempMovements;
            movimenti['days'] = state.movimenti.days;
            */
            return {
                ...state,
                promiscMode: action.promiscMode,
                //spinner: true,
                //movimenti: movimenti
            }
        case actionTypes.SET_SINGLE_CC_DATA_OK:
            return {
                ...state,
                ccId: action.capo_cantiere_id,
                ccName: action.nome,
                ccSurname: action.cognome,
            }
        case actionTypes.SET_SINGLE_CC_DATA_FAIL:
            return {
                ...state,
                ccId: null
            }
        case actionTypes.CC_TARGHE_SUCCESS:
            return {
                ...state,
                selectOptions: action.selectOptions
            }
        case actionTypes.CC_TARGHE_FAIL:
            return {
                ...state,
                selectOptions: []
            }
        case actionTypes.RESET_TARGHE_OPTIONS:
            return {
                ...state,
                selectOptions: action.selectOptions
            }
        default:
            return state;
    }
}

export default reducer;