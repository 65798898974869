import React from 'react';

import './NavigationItems.scss';
import NavigationItem from './NavigationItem/NavigationItem';

import { IconContext } from "react-icons";
import { GiHomeGarage } from "react-icons/gi";
import { FaUserAlt, FaHistory, FaMapMarkerAlt, FaCalendarAlt, FaQuestionCircle } from "react-icons/fa";
import { IoIosLogOut } from 'react-icons/io'
import { FiLogOut } from 'react-icons/fi'
import { MdLocalGasStation } from 'react-icons/md'
import { FaWrench } from 'react-icons/fa'

const navigationItems = (props) => (
    <ul class="nav-items">
        { props.isAuthenticated
            ? <NavigationItem link="/">
                    <FaMapMarkerAlt style={{fontSize: "1.4em"}} />
                    <div class="nav-item">Posizioni</div>
              </NavigationItem>
            : null
        }
        { props.isAuthenticated
            ? <NavigationItem link="/percorsi">
                    <FaHistory style={{fontSize: "1.4em"}}/>
                    <div class="nav-item">Storico</div>
              </NavigationItem>
            : null
        }
        { props.isAuthenticated
            ? <NavigationItem link="/garage">
                    <GiHomeGarage style={{fontSize: "1.6em"}} />
                    <div class="nav-item">Garage</div>
              </NavigationItem>
            : null
        }
        { /**/
            props.isAuthenticated
            ? <NavigationItem link="/refuelings_checkbox">
                    <MdLocalGasStation style={{fontSize: "1.6em"}} />
                    <div class="nav-item">Rifornimenti</div>
              </NavigationItem>
            : null
            
        }
        { /**/ props.isAuthenticated
            ? <NavigationItem link="/movimenti">
                    <FaCalendarAlt style={{fontSize: "1.4em"}} />
                    <div class="nav-item">Movimenti</div>
              </NavigationItem>
            : null
            
        }
        { /**/ props.isAuthenticated
            ? <NavigationItem link="/interventi">
                    <FaWrench style={{fontSize: "1.4em"}} />
                    <div class="nav-item">Interventi Mec.</div>
              </NavigationItem>
            : null
            
        }
        { props.isAuthenticated
            ? <NavigationItem link="/settings">
                    <FaUserAlt style={{fontSize: "1.4em"}} />
                    <div class="nav-item">Profilo</div>
              </NavigationItem>
            : null
        }
        { props.isAuthenticated
            ? <NavigationItem link="/manual" title={"Manuale"}>
                    <FaQuestionCircle style={{fontSize: "1.4em"}} />
                    <div class="nav-item">Manuale</div>
            </NavigationItem>
            : null
        }
        { props.isAuthenticated
            ? <NavigationItem link="/logout" title={"Log Out"}>
                <FiLogOut style={{color: "orange", fontSize: "1.4em"}} />
            </NavigationItem>
            : null
        }
    </ul>
);

export default navigationItems;