import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Tabs, Tab, Card, Row, Col, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import Plot from 'react-plotly.js';
import { connect } from 'react-redux'
import Moment from 'react-moment';
import Select from 'react-select'

import classes from './Refuelings.module.css'
//import './Refuelings.scss'
//import styled from 'styled-components'

import * as actions from '../../store/actions/index';

import 'leaflet/dist/leaflet.css';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import { Icon } from "leaflet";

import RefuelingIcon from "../../assets/images/icons/refueling_icon_stroke_2.png"

//import { renderToStaticMarkup } from 'react-dom/server';
//import { divIcon } from 'leaflet';
import { FaArrowRight } from "react-icons/fa";

import Spinner from '../../components/UI/Spinner/Spinner';

import { getPetrolStationIcon } from '../../utils/petrolStationIcons'


const Grafici = (props) => {

  return (
    <Fragment>
      <div>
        {props.graph_1 ? 
          <Plot
          data={[JSON.parse(props.graph_1).data[0]]}
          layout={JSON.parse(props.graph_1).layout}
        />
        :
        <></>
        }
        
      </div>
        {props.graph_2 ? 
          <Plot
          data={[JSON.parse(props.graph_2).data[0]]}
          layout={JSON.parse(props.graph_2).layout}
        />
        :
        <></>
        }
      <div>

      </div>
        {props.graph_3 ? 
          <Plot
          data={[JSON.parse(props.graph_3).data[0]]}
          layout={JSON.parse(props.graph_3).layout}
        />
        :
        <></>
        }
      <div>

      </div>
    </Fragment>
  );
};


const RefuelingDetails = (props) => {

  let refuel = props.refuel;

  if(refuel.carta_carburante_id != '' && refuel.refuel_id != '') // verdi. Inseriti a mano e trovati sul DB dei rifornimenti
    return (

      <Row>
        <Col lg="6">
          <div>
            Km:&nbsp;
            <span style={{float: 'right'}}>
                <NumberFormat value={Math.round(refuel.km_start)} displayType={'text'} thousandSeparator={true} />
            </span>
          </div>
          <div>
            Serbatoio:&nbsp;
            <span style={{float: 'right'}}>
                {refuel.fuel_start*refuel.serbatoio/100 + " -> " + refuel.fuel_stop * refuel.serbatoio/100} litri
            </span>
          </div>
          <div>
            Litri inseriti:&nbsp;
            <span style={{float: 'right'}}>
                {refuel.fuel_diff_litri} litri
            </span>
          </div>
          <div>
            Indirizzo:&nbsp;
            <span style={{textAlign: 'right', fontSize: "90%", maxWidth: "70%", display: "inline-block", right: 0, float: "right"}}>
                {refuel.indirizzo}
            </span>
          </div>
        </Col>

        <Col lg="6">
          <div style={{marginTop: '5px', height: '125px'}}>
            <div style={{width:"229px", position: 'absolute', right: '179px'}}>
              <Card>
                <Card.Header style={{padding: '2px 10px 2px 10px'}}>Rifornimento Trovato</Card.Header>
                <Card.Body style={{padding: '2px'}}>
                  <Table>   
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td style={{padding: '2px 10px 2px 10px', borderTop: 'none'}}>Qta</td>
                        <td align="right" style={{ padding: '2px 5px 2px 5px', borderTop: 'none'}}>
                          <span style={{color:'grey'}}>
                            {refuel.qta ? refuel.qta : '-'} litri
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '2px 10px 2px 10px'}}>Prezzo/litro</td>
                        <td align="right" style={{padding: '2px 10px 2px 10px'}}>
                          <span style={{color:'grey'}}>
                            {refuel.qta != "" && refuel.prezzo_totale != "" ? (refuel.prezzo_totale / refuel.qta).toFixed(2) : '-'} €/l
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '2px 10px 2px 10px', borderBottom: 'none'}}>Totale</td>
                        <td align="right" style={{padding: '2px 10px 2px 10px', borderBottom: 'none'}}>
                            <span style={{color:'grey'}}>
                                {refuel.prezzo_totale ? refuel.prezzo_totale : '-'} €
                            </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>

            <div 
              style={{width:"76px", position: 'absolute', right: '90px'}}
              title={refuel.fuel_start + ' % a ' + refuel.fuel_stop + ' %'}
            >
              <div className={classes.fuel_bar}> 
                <div 
                  className={classes.mask}
                  style={{ 
                    position: 'absolute', width: 'calc(100% - 4px)', 
                    overflow: 'hidden', transform: 'rotate(180deg)', 
                    bottom: '0', left: '2px', 
                    height: refuel.fuel_start 
                  }}
                >
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'red'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                </div>
              </div>
              <div 
                class="fuel_bar_arrow" 
                style={{
                  display: 'inline-block',
                  verticalAlign: 'top',
                  lineHeight: '106px',
                  fontWeight: 'bold'
                }}
              >
                <FaArrowRight />
              </div>
              <div className={classes.fuel_bar}> 
                <div 
                  className={classes.mask}
                  style={{ 
                    position: 'absolute', width: 'calc(100% - 4px)', 
                    overflow: 'hidden', transform: 'rotate(180deg)', 
                    bottom: '0', left: '2px', 
                    height: refuel.fuel_stop
                  }}
                >
                  <div className={classes.tacca} style={{height: '10px', marginTop: '1px', backgroundColor: 'red'}}></div>
                  <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px  ', marginTop: '2px', backgroundColor: 'green'}}></div>
                </div>
              </div>  

            </div>

            <div style={{width: '73px', position: 'absolute', right: '0px'}}>
              <div style={{ width: "60px", border: "1px solid #ccc", radius: "5px", padding: "5px" }} >

                <OverlayTrigger
                  key={'top'}
                  placement={'top'}
                  overlay={
                    <Tooltip id={`tooltip-${'top'}`}>
                      {refuel.gestore ? refuel.gestore : 'Gestore non disponibile'}
                    </Tooltip>
                  }
                >
                  <img 
                    src={getPetrolStationIcon(refuel.gestore.toLowerCase())}
                    width="50"
                    height="50"
                    alt={refuel.gestore}
                  />
                </OverlayTrigger>


              </div>
            </div>

          </div>

        </Col>

      </Row>


    );

  else if (refuel.carta_carburante_id != '' && refuel.refuel_id == '') // gialli. non trovati sul sistema. Sono stati inseriti a mano
    return (

      <Row>
        <Col lg="6">
         
        </Col>

        <Col lg="6">
          <div style={{marginTop: '5px', height: '125px'}}>
            <div style={{width:"229px", position: 'absolute', right: '179px'}}>
              <Card>
                
                <Card.Body style={{padding: '2px'}}>
                  <Table>   
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td style={{padding: '2px 10px 2px 10px', borderTop: 'none'}}>Km</td>
                        <td align="right" style={{ padding: '2px 5px 2px 5px', borderTop: 'none'}}>
                          <span style={{color:'grey'}}>
                            {refuel.cc_km ? refuel.cc_km : '-'} km
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '2px 10px 2px 10px'}}>Qta</td>
                        <td align="right" style={{ padding: '2px 5px 2px 5px'}}>
                          <span style={{color:'grey'}}>
                            {refuel.qta ? refuel.qta : '-'} litri
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '2px 10px 2px 10px'}}>Prezzo/litro</td>
                        <td align="right" style={{padding: '2px 10px 2px 10px'}}>
                          <span style={{color:'grey'}}>
                            {refuel.qta != "" && refuel.prezzo_totale != "" ? (refuel.prezzo_totale / refuel.qta).toFixed(2) : '-'} €/l
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '2px 10px 2px 10px', borderBottom: 'none'}}>Totale</td>
                        <td align="right" style={{padding: '2px 10px 2px 10px', borderBottom: 'none'}}>
                            <span style={{color:'grey'}}>
                                {refuel.prezzo_totale ? refuel.prezzo_totale : '-'} €
                            </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>

            <div style={{width: '73px', position: 'absolute', right: '0px'}}>
              <div style={{ width: "60px", border: "1px solid #ccc", radius: "5px", padding: "5px" }}>
                <OverlayTrigger
                  key={'top'}
                  placement={'top'}
                  overlay={
                    <Tooltip id={`tooltip-${'top'}`}>
                      {refuel.gestore ? refuel.gestore : 'Gestore non disponibile'}
                    </Tooltip>
                  }
                >
                  <img 
                    src={getPetrolStationIcon(refuel.gestore)}
                    width="50"
                    height="50"
                    alt={refuel.gestore}
                  />
                </OverlayTrigger>
              </div>
            </div>

          </div>

        </Col>

      </Row>
    );

  else if (refuel.carta_carburante_id == '' && refuel.refuel_id != '') // Rifornimenti sensa rimborso associato (i.e: Ci sono riforniti in una pompa GCF)
    return (

      <Row>
        <Col lg="6">
          <div>
            Km:&nbsp;
             <span style={{float: 'right'}}>
                <NumberFormat value={Math.round(refuel.km_start)} displayType={'text'} thousandSeparator={true} />
             </span>
          </div>
          <div>
            Serbatoio:&nbsp;
             <span style={{float: 'right'}}>
                {refuel.fuel_start*refuel.serbatoio/100 + " -> " + refuel.fuel_stop * refuel.serbatoio/100} litri
            </span>
          </div>
          <div>
            Litri inseriti:&nbsp;
             <span style={{float: 'right'}}>
                {refuel.fuel_diff_litri} litri
            </span>
          </div>
          <div>
            Indirizzo:&nbsp;
            <span style={{textAlign: 'right', fontSize: "90%", maxWidth: "70%", display: "inline-block", right: 0, float: "right"}}>
                {refuel.indirizzo}
            </span>
          </div>
        </Col>

        <Col lg="6">
          <div style={{marginTop: '5px', height: '125px'}}>

            <div 
              style={{width:"76px", position: 'absolute', right: '90px'}}
              title={refuel.fuel_start + ' % a ' + refuel.fuel_stop + ' %'}
            >
              <div className={classes.fuel_bar}> 
                <div 
                  className={classes.mask}
                  style={{ 
                    position: 'absolute', width: 'calc(100% - 4px)', 
                    overflow: 'hidden', transform: 'rotate(180deg)', 
                    bottom: '0', left: '2px', 
                    height: refuel.fuel_start 
                  }}
                >
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'red'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                </div>
              </div>
              <div 
                class="fuel_bar_arrow" 
                style={{
                  display: 'inline-block',
                  verticalAlign: 'top',
                  lineHeight: '106px',
                  fontWeight: 'bold'
                }}
              >
                <FaArrowRight />
              </div>
              <div className={classes.fuel_bar}> 
                <div 
                  className={classes.mask}
                  style={{ 
                    position: 'absolute', width: 'calc(100% - 4px)', 
                    overflow: 'hidden', transform: 'rotate(180deg)', 
                    bottom: '0', left: '2px', 
                    height: refuel.fuel_stop
                  }}
                >
                  <div className={classes.tacca} style={{height: '10px', marginTop: '1px', backgroundColor: 'red'}}></div>
                  <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                  <div className={classes.tacca} style={{height: '9px  ', marginTop: '2px', backgroundColor: 'green'}}></div>
                </div>
              </div>  

            </div>

            <div style={{width: '73px', position: 'absolute', right: '0px'}}>
              <div style={{ width: "60px", border: "1px solid #ccc", radius: "5px", padding: "5px" }}>
                <img 
                  src={getPetrolStationIcon(refuel.gestore)}
                  width="50"
                  height="50"
                  title={refuel.gestore}
                />
              </div>
            </div>

          </div>

        </Col>

      </Row>
    );
  else return <></>

};


const RifornimentiList = (props) => {
  
  const  heightDiv = window.innerHeight - (window.innerHeight * 29 / 100)

  const selectedGroup = props.selectedRefuelingGroup != null ? props.selectedRefuelingGroup.value : 0

  const [tempRefuelings, setTempRefuelings] = useState([])

  useEffect(() => {

    if(Object.entries(props.refuelings).length !== 0)
      
      setTempRefuelings(props.refuelings.filter((refuel) => {

        if(!props.checkBoxGCF && refuel.gestore ==='GCF') return false;

        let refuelPercentage = refuel.fuel_stop - refuel.fuel_start;
        /**/
        if(refuel.fuel_station_distance != "" ) {
          if(refuel.fuel_station_distance > parseInt(process.env.REACT_APP_LIMITE_DISTANZA_DAL_DISTRIBUTORE)
            || 
            refuelPercentage < parseInt(process.env.REACT_APP_LIMITE_RIFORNIMENTO_PERCENTUALE)
          ) return false;
        }
        

        switch(selectedGroup) {
          case 0:
            return true
          case 1:
            if(refuel.carta_carburante_id != '' && refuel.refuel_id != '') return true
            return false
          case 2:
            if (refuel.carta_carburante_id != '' && refuel.refuel_id == '') return true
            return false
          case 3:
            if (refuel.carta_carburante_id == '' && refuel.refuel_id != '') return true
            return false
          default:
            return true
        }

      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedRefuelingGroup, props.checkBoxGCF]);

  /*
  const iconMarkup = renderToStaticMarkup(<FaMapMarkerAlt style={{fontSize: "1.6rem"}}/>);
  const customMarkerIcon = divIcon({
    html: iconMarkup,
  });
  */
  if(props.refuelings && props.refuelings.length > 0)
    return (
      <div>
        <Row>
          <Col>
            <div style={{height: heightDiv, border: '1px solid black'}}>
              <div style={{margin: "2px 0px"}}>
                <ul style={{ listStyleType: "none", padding: "0px 10px", height: "99%", position: "absolute", overflow: "auto", width: "95%"}}>
                
                {tempRefuelings.map((refuel) => {

                    //console.log(typeof(refuel.refuel_id), typeof(refuel.fuel_station_distance), typeof(refuelPercentage))
                    
                    return (
                      <li style={{width: "auto", marginTop: "10px", marginBottom: "10px", border: "1px solid #ccc", padding: "5px", borderRadius: "5px"}}>
                        
                        <div style={{height: "25px", backgroundColor: "#ddd", paddingLeft: "5px"}}>
                          <div style={{float: "left", width: "250px"}}>Rifornimento Progressivo: <strong>{refuel.refuel_id}</strong></div>
                          <div style={{float: "right", width: "130px"}}><Moment format="DD/MM/YYYY HH:mm">{refuel.fixtime_start}</Moment></div>
                        </div>
                      
                        <div style={{margin: "5px 2px 0px 0px"}}>
                            <RefuelingDetails refuel={refuel} />                        
                        </div>

                      </li>
                    )
                  })
                }
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div style={{height: heightDiv, border: '1px solid grey'}}>
              <Map
                style={{ width: '100%', height: '100%', zIndex: '90' }}
                maxZoom={19}
                scrollWheelZoom={true}
                bounds={props.bounds}
              >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />  

                {tempRefuelings.map((refuel) => {

                  if(refuel.car_lon === "" || refuel.car_lat === "") return false;
                  return (
                    <Marker 
                      position={[refuel.car_lat, refuel.car_lon]}
                      icon={new Icon({
                            iconUrl: RefuelingIcon,
                            iconSize: [31, 50],
                            iconAnchor: [25, 41],
                            popupAnchor: [-10, -40],
                            className: classes.refuel_icon
                        })}
                    >
                      <Popup>
                          Rifornimento Progressivo: <strong>{refuel.refuel_id}</strong>
                      </Popup>
                    </Marker>
                  )
                  })
                }
              
              </Map>
            </div>
          </Col>
        </Row>
      </div>
    );
  else
    return <Fragment>Dati non presenti nel sistema</Fragment>
  
};

// Style for Select Menu.
const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 999999
  }),

}


const Refuelings = props => {

  const [selectedRefuelingGroup, setSelectedRefuelingGroup] = useState(null)

  const [checkBoxGCF, setCheckBoxGCF] = useState(false)

  const checkboxRef = useRef(null);


  useEffect(() => {
    props.onResetRefuelings();
    console.log(props.match.params)
    const deviceId = props.match.params.device_id
    //const refuelId = props.match.params.refuel_id ?  props.match.params.refuel_id : 0
    const dateStart = props.match.params.date_start ?  props.match.params.date_start : 0
    const dateEnd = props.match.params.data_fine ?  props.match.params.data_fine : 0
    //let dateTempTz = moment(dateTemp).tz('Europe/Rome').format('YYYY-MM-DD')
    console.log(dateEnd)
    props.onGetRefuelings(deviceId, dateEnd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSelect = (e) => { setSelectedRefuelingGroup(e) }

  const handleChangeCheckbox = (e, sourceId) => { 
    if(sourceId === 1) setCheckBoxGCF(e.target.checked);
    if(sourceId === 2) { checkboxRef.current.checked = !checkBoxGCF; setCheckBoxGCF(!checkBoxGCF); }
  }

  if(props.refuelingsData != null) {

    if(props.refuelingsData.length != 0) {
      return (
        <Fragment>
          <div style={{padding: "20px 30px"}}>

            <Card>
              <Card.Body style={{paddingBottom: '0px', paddingTop: "8px"}}>

                <Row>
                  <Col>
                    <div className={classes.targa}>
                      {props.refuelingsData.info.show_targa}
                    </div>
                  </Col>
                  <Col>
                    Modello: {props.refuelingsData.info.marca + ' ' + props.refuelingsData.info.modello}
                    <br/>
                    Codice mezzo: {props.refuelingsData.info.codice_mezzo ? props.refuelingsData.info.codice_mezzo : '-'}
                  </Col>
                  <Col>
                    C.C: {props.refuelingsData.info.nome + ' ' + props.refuelingsData.info.cognome }  
                    <br/>
                    Ultimi 3 mesi
                  </Col>
                  
                  <Col xs="1">
                      <div className="input-group" style={{marginBottom:"0.35rem"}}>
                          <div className="input-group-prepend">
                              <div className={"input-group-text"} >
                                  <input 
                                      type="checkbox"
                                      ref={checkboxRef}
                                      label={"GCF"}
                                      defaultChecked={false}
                                      onChange={(e) => handleChangeCheckbox(e, 1)}
                                  />
                              </div>
                          </div>
                          <div 
                              className={"form-control"} style={{cursor: 'default'}}
                              onClick={(e) => handleChangeCheckbox(e, 2)}
                          >
                              {"GCF"}
                          </div>
                          
                      </div>
                  </Col>
                  
                  <Col>
                    <div>
                      <Select
                        placeholder="Filtra risultato"
                        options={[
                          {value: 0, label: "Tutti"},
                          {value: 1, label: "Verificati"},
                          {value: 2, label: "Non Trovati"},
                          {value: 3, label: "Non Verificati"}]
                        }
                        onChange={(e) => handleChangeSelect(e)}
                        styles={customStyles}
                        value={selectedRefuelingGroup}
                      />
                    </div>
  
                  </Col>

                </Row>  

              </Card.Body>
          </Card>
          </div>
          <div style={{padding: "20px 30px"}}>
            <Tabs defaultActiveKey="rifornimenti" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="rifornimenti" title="Rifornimenti">
                <RifornimentiList 
                  refuelings={props.refuelingsData.refuel ? props.refuelingsData.refuel : null} 
                  bounds={props.bounds} 
                  selectedRefuelingGroup={selectedRefuelingGroup}
                  checkBoxGCF={checkBoxGCF}
                />
              </Tab>
              <Tab eventKey="grafici" title="Grafici">
                <Grafici 
                  graph_1={props.refuelingsData.json_km_car}
                  graph_2={props.refuelingsData.json_serbatoio_car}
                  graph_3={props.refuelingsData.json_serbatoio_km}
                />
              </Tab>
            </Tabs>
          </div>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <div style={{padding: "20px 30px"}}>
            <Card>
              <Card.Body style={{paddingBottom: '0px', paddingTop: "8px"}}>

                <Row>
                  <Col>
                  
                  </Col>
                  <Col>
                    Modello: 
                    <br/>
                    Codice mezzo:
                  </Col>
                  <Col>
                    C.C: 
                    <br/>
                    Ultimi 3 mesi
                  </Col>
                  <Col>
                    km min: 
                    <br/>
                    km max: 
                  </Col>
                </Row>  

              </Card.Body>
          </Card>
          </div>
          <div style={{padding: "20px 30px"}}>
            <Tabs defaultActiveKey="rifornimenti" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="rifornimenti" title="Rifornimenti">
                <RifornimentiList refuelings={props.refuelingsData.refuel ? props.refuelingsData.refuel : null} bounds={props.bounds} />
              </Tab>
              <Tab eventKey="grafici" title="Grafici">

              </Tab>
            </Tabs>
          </div>
        </Fragment>
      )
    }

  } else return <Spinner />

}

const mapStateToProps = state => {
  return {
    refuelingsData: state.refuelings.refuelingsData,
    bounds: state.refuelings.bounds
  }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetRefuelings: (deviceId, dateEnd) => dispatch(actions.getRefuelings(deviceId, dateEnd)),
        onResetRefuelings: () => dispatch(actions.resetRefuelings())
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(Refuelings);
