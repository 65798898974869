//---------------------------------------------------------------------------------------------------
// ICONS
//---------------------------------------------------------------------------------------------------

import iconEmpty from "../assets/images/icons/petrol_stations/noimage.png"
import agipIcon from "../assets/images/icons/petrol_stations/agip.png"
import aquilaIcon from "../assets/images/icons/petrol_stations/aquila.png"
import axpoIcon from "../assets/images/icons/petrol_stations/axpo.png"
import beyfinIcon from "../assets/images/icons/petrol_stations/beyfin.png"
import costantinIcon from "../assets/images/icons/petrol_stations/costantin.png"
import driweIcon from "../assets/images/icons/petrol_stations/driwe.png"
import dufercoIcon from "../assets/images/icons/petrol_stations/duferco.png"
import ekomobilIcon from "../assets/images/icons/petrol_stations/ekomobil.png"
import emobitalyIcon from "../assets/images/icons/petrol_stations/emobitaly.png"
import enercoopIcon from "../assets/images/icons/petrol_stations/enercoop.png"
import eniIcon from "../assets/images/icons/petrol_stations/eni.png"
import essoIcon from "../assets/images/icons/petrol_stations/esso.png"
import europamIcon from "../assets/images/icons/petrol_stations/europam.png"
import evwayIcon from "../assets/images/icons/petrol_stations/evway.png"
import gcfIcon from "../assets/images/icons/petrol_stations/gcf.png"
import gewissIcon from "../assets/images/icons/petrol_stations/gewiss.png"
import goelectricstationsIcon from "../assets/images/icons/petrol_stations/ges.png"
import ipIcon from "../assets/images/icons/petrol_stations/ip.png"
import keropetrolIcon from "../assets/images/icons/petrol_stations/keropetrol.png"
import neogyIcon from "../assets/images/icons/petrol_stations/neogy.png"
import petrolcompanyIcon from "../assets/images/icons/petrol_stations/petrolcompany.png"
import q8Icon from "../assets/images/icons/petrol_stations/q8.png"
import repowerIcon from "../assets/images/icons/petrol_stations/repower.png"
import repsolIcon from "../assets/images/icons/petrol_stations/repsol.png"
import retitaliaIcon from "../assets/images/icons/petrol_stations/retitalia.png"
import sarnioilIcon from "../assets/images/icons/petrol_stations/sarnioil.png"
import shellIcon from "../assets/images/icons/petrol_stations/shell.png"
import smpIcon from "../assets/images/icons/petrol_stations/smp.png"
import tamoilIcon from "../assets/images/icons/petrol_stations/tamoil.png"
import vegaIcon from "../assets/images/icons/petrol_stations/vega.png"

import noImageIcon from "../assets/images/icons/petrol_stations/noimage.png"


//---------------------------------------------------------------------------------------------------
// FUNCTIONS
//---------------------------------------------------------------------------------------------------
/**/
    export function getPetrolStationIcon (pS = null) {
        if(pS === null) return iconEmpty;
        pS = pS.toLowerCase()
        switch(pS) {
            case 'agip':
                return agipIcon;
            case 'aquila':
                return aquilaIcon;
            case 'axpo':
                return axpoIcon;
            case 'beyfin':
                return beyfinIcon;
            case 'costantin':
                return costantinIcon;
            case 'driwe':
                return driweIcon;
            case 'duferco':
                return dufercoIcon;
            case 'ekomobil':
                return ekomobilIcon;
            case 'emobitaly':
                return emobitalyIcon;
            case 'enercoop':
                return enercoopIcon;
            case 'eni':
                return eniIcon;
            case 'esso':
                return essoIcon;
            case 'europam':
                return europamIcon;
            case 'evway':
                return evwayIcon;
            case 'gewiss':
                return gewissIcon;
            case 'gcf':
                return gcfIcon;
            case 'goelectricstations':
                return goelectricstationsIcon;
            case 'ip':
                return ipIcon;
            case 'keropetrol':
                return keropetrolIcon;
            case 'neogy':
                return neogyIcon;
            case 'petrolcompany':
                return petrolcompanyIcon;
            case 'q8':
                return q8Icon;
            case 'repower':
                return repowerIcon;
            case 'repsol':
                return repsolIcon;
            case 'retitalia':
                return retitaliaIcon;
            case 'sarnioil':
                return sarnioilIcon;
            case 'shell':
                return shellIcon;
            case 'smp':
                return smpIcon;
            case 'tamoil':
                return tamoilIcon;
            case 'vega':
                return vegaIcon;
            default:
                return noImageIcon;
        }
    }