import { Table, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import axios from 'axios';

import Moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/it';

import { FaFileDownload } from 'react-icons/fa';

import classes from './AcquistoGommeTable.module.css'


export const AcquistiGommeTable = (props) => {

    const downloadPurchaseFile = (fat_uuid, filename) => {
        axios({
                url: process.env.REACT_APP_GEB_MIDDLEWARE + '/download_purchase_file/' + fat_uuid,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                //link.setAttribute('download', fat_uuid + '_' + file.filename); //or any other extension
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(err => {
                console.error('/files request fail: ' + err);
            });
    }

    return (
      <div className={classes.fixTableHead}>


        <Table>
            <thead style={{textAlign:'center'}}>
                <tr>
                <th width={100} style={{backgroundColor: "#f5faff", zIndex: '99'}}>Targa</th>
                <th width={200}>Data Inserimento</th>
                <th width={150}>Numero Bolla</th>
                <th width={150}>Importo (€)</th>
                <th width={150}>Codice Gomma</th>
                <th width={150}>Qta Gomme</th>
                <th width={150}>Fornitore</th>
                <th>Descrizione</th>
                <th width={150}>File</th>
                </tr>
            </thead>
            <tbody>
            {props.purchases.map((item, index) => {
                return (
                <tr key={index}>
                    <td style={{textAlign:'left'}}>
                    <div className={classes.targa}>{item.show_targa}</div>
                    </td>
                    <td >
                        {
                        Moment(item.logbook_ts).format('D MMM yyyy, HH:mm')
                        }
                    </td>

                    <td>
                    {
                        item.logbook_extra.numero_bolla ? 
                        item.logbook_extra.numero_bolla
                        :
                        <span className={classes.nd}>n/d</span>
                    }
                    </td>

                    <td>
                    {item.importo}
                    </td>
                    <td width='10%'>{item.logbook_extra.codice_gomma}</td>
                    <td>{item.logbook_extra.quantita_gomme}</td>
                    <td>
                    {item.logbook_extra.fornitore != 0 ? item.logbook_extra.fornitore : '-'}
                    </td>
                    <td>{item.descrizione.toLowerCase()}</td>
                    <td>
                        {item.fat_uuid ? 
                            <Button
                                variant="outline-primary" 
                                style={{fontSize: "22px"}}
                                size="sm"
                                onClick={() => downloadPurchaseFile(item.fat_uuid, item.filename)}
                            >
                                <FaFileDownload />
                            </Button>
                        :
                            <></>
                        }
                    </td>
                
                </tr>
                )
            })}
            </tbody>

        </Table>

      </div>


    );

}



const mapStateToProps = (state) => {
  return {
    purchases: state.interventi.purchases
  }
}

export default connect(mapStateToProps, null)(AcquistiGommeTable);