import React, { useEffect, useRef, useState } from 'react'


import Select from 'react-select'
import { Row, Col, Card, Button } from 'react-bootstrap';

import { connect } from 'react-redux';
import classes from './Filtro.module.css'
import * as actions from '../../../../../store/actions/index';

const Reparti = (props) => {
    let colCounter;
    const rowsContainer = []

    let rows = Math.ceil(props.reparti.length / 2);
    for (var i = 0; i < rows; i++) {
        colCounter = 0;
        rowsContainer.push (
            <Row key={i}>
                {props.reparti.map((reparto, index) => {

                    let ccRepartoCounter = 0;

                    if (props.ccDevicesCounter && props.ccDevicesCounter.reparto) {
                        let ccRepartoFiltered = props.ccDevicesCounter.reparto.filter(counter => counter.reparto_id === reparto.reparto_id);
                        if (ccRepartoFiltered.length > 0)
                            ccRepartoCounter = ccRepartoFiltered.count;
                        else
                            ccRepartoCounter = 0;
                    }

                    if(index === (i*2)+colCounter && colCounter < 2) {
                        colCounter++;
                        return (
                            <Col xs={12} sm={12} md={12} lg={6} key={reparto.reparto_id}>
                                <div className="input-group" style={{marginBottom:"0.35rem"}}>
                                    <div className="input-group-prepend">
                                        <div className={"input-group-text " + classes.checkbox} >
                                            <input 
                                                type="checkbox"                 
                                                ref={ ref => {props.refRepartiArray.current[reparto.reparto_id] = ref} }
                                                label={reparto.reparto}
                                                defaultChecked={true}
                                                value={reparto.reparto_id}
                                                onChange={(e) => props.onCheckboxInputClick(e, 1, reparto.reparto_id)}
                                            />
                                        </div>
                                    </div>
                                    <div 
                                        className={"form-control " + classes.unselectable}
                                        onClick={(e) => props.onCheckboxNameClick(e, 1, reparto.reparto_id)}
                                    >
                                        {reparto.reparto}&nbsp;<sup>{ccRepartoCounter}</sup>
                                        <span style={{float: "right"}}>{/*ccRepartoCounter*/}</span>
                                    </div>
                                </div>
                            </Col>
                        );
                    } else return false;
                    
                })}
            </Row>
        );
    }

    return <>{rowsContainer}</>
}

const CapiCantieri = (props) => {
    let rows = Math.ceil(props.cc.length / 2);
    let colCounter;
    const rowsContainer = []
    
    for (var i = 0; i < rows; i++) {
        colCounter = 0;
        rowsContainer.push (
            <Row key={i}>
                {props.cc.map((person, index) => {
                    let ccDevicesCounter = 0;

                    if( props.ccDevicesCounter && props.ccDevicesCounter.cc ) {

                        var tmp = props.ccDevicesCounter.cc.filter(counter => counter.capo_cantiere_id === person.capo_cantiere_id);
                        if (tmp.length > 0)
                            ccDevicesCounter = tmp[0].count;
                        else
                            ccDevicesCounter = 0;
                    }

                    if(index === (i*2)+colCounter && colCounter < 2) {
                        //console.log("i: " + i + " Col:" + colCounter)
                        colCounter++;
                        //return (<Col><Person person={props.cc[(i*2)+colCounter-1]} /></Col>)
                        return (
                            <Col xs={12} sm={12} md={12} lg={6} key={person.capo_cantiere_id}>
                                <div className="input-group" style={{marginBottom:"0.35rem"}}>
                                    <div className="input-group-prepend">
                                        <div className={"input-group-text " + classes.checkbox} >
                                            <input 
                                                type="checkbox"                 
                                                ref={ ref => {props.refCcArray.current[person.capo_cantiere_id] = ref} }
                                                label={person.cognome}
                                                defaultChecked={true}
                                                value={person.capo_cantiere_id}
                                                onChange={(e) => props.onCheckboxInputClick(e, 2, person.capo_cantiere_id)}
                                            />
                                        </div>
                                    </div>
                                    <div 
                                        className={"form-control " + classes.unselectable}
                                        onClick={(e) => props.onCheckboxNameClick(e, 2, person.capo_cantiere_id)}
                                    >
                                        {person.cognome}&nbsp;<sup>{ccDevicesCounter}</sup>
                                        
                                        <span style={{float: "right"}}>{/*ccDevicesCounter*/}</span>
                                    </div>
                                    
                                </div>
                            </Col>
                        );
                    } else return false;
                    
                })}
            </Row>
        );
    }
    return <>{rowsContainer}</>
}

const Filtro = (props) => {

    const refRepartiArray = useRef([]);
    
    const refCcArray = useRef([]);

    const [ selectedOption, setSelectedOption ] = useState('')

    const minutes = 1; //update time in minutes

    useEffect(() => {
        props.onGetCapiCantieri();
        props.onGetCcDevicesCounter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(props.cc.length > 0) {
            props.onGetDevices(props.role, props.userMail, setMailList(), props.selectedState);
            const interval = setInterval(() => {             
                props.onGetDevices(props.role, props.userMail, setMailList(), props.selectedState);
            }, minutes * 60 * 1000) //minutes * seconds * miliseconds
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cc, props.selectedState]);

    /*
    useEffect(() => {
        if(props.devices.length > 0)
            //console.log("SELECT options changed!", props.devices.length)
            //reset Dropdown menu select delle targhe
            //setSelectedOption(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectOptions])
    */

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            //console.log(`Selected Label: ${selectedOption.label}`);
            //console.log(`Selected Value: ${selectedOption.value}`);
            let tempDevice = props.devices.filter(device => selectedOption.value === device.deviceId)
            if(tempDevice.length > 0) {
                props.onSetSelectedDevice(tempDevice[0])
            }
        }
    };

    const processUserInput = (e) => {
        const newVal = e.replace(/\s/g, '');
        setSelectedOption(newVal)
        return newVal
    }

    // function is executed when user clicks on a checkbox input
    const onCheckboxInputClick = (e, selectedFilter, selectedOption) => {
        /*
        console.log(e.target.value)
        console.log(selectedFilter)
        console.log(selectedOption)
        */
        const isChecked = e.target.checked;

        // Disable or enable all cc related to this reparto_id
        if (selectedFilter === 1)
            props.cc.map((person) => {
                if(person.reparto_id === selectedOption) {
                    refCcArray.current[person.capo_cantiere_id].disabled = !isChecked
                    refCcArray.current[person.capo_cantiere_id].checked = isChecked
                }
                return false;
            })           

        props.onGetDevices(props.role, props.userMail, setMailList(), props.selectedState);
    };

    // Function executed when user clicks on Checkbox name.
    // The result is that if clicked on Riparto, only vehicles from that reparto must be visible
    // If clicked on capo_cantiere Name, only veicles related to this capo_cantieri must be visible
    const onCheckboxNameClick = (e, selectedFilter, selectedOption) => {
        if(selectedFilter === 1) // a Reparto filter has been selected
        {
            props.reparti.map((rep) => {
                refRepartiArray.current[rep.reparto_id].checked = (rep.reparto_id === selectedOption) ? true : false
                return false;
            });

            props.cc.map((person) => {
                refCcArray.current[person.capo_cantiere_id].checked = (person.reparto_id === selectedOption) ? true: false
                refCcArray.current[person.capo_cantiere_id].disabled = (person.reparto_id === selectedOption) ? false: true    
                return false;
            });

        }
        if(selectedFilter === 2) // a CC filter has been selected
        {
            // Buscar su padre y encenderlo.
            // Apagar los demas padres
            const repartoCc = props.cc.filter((person) => person.capo_cantiere_id === selectedOption )[0].reparto_id;
            props.reparti.map((rep) => {
                refRepartiArray.current[rep.reparto_id].checked = (repartoCc === rep.reparto_id) ? true : false
                return false;
            });

            // De todos los cc, deseleccionar todos los demas, y desabilitar los que no sean del grupo
            props.cc.map((person) => {
                refCcArray.current[person.capo_cantiere_id].checked = (person.capo_cantiere_id === selectedOption) ? true : false
                refCcArray.current[person.capo_cantiere_id].disabled = (person.reparto_id === repartoCc) ? false: true
                return false;
            });
        }

        props.onGetDevices(props.role, props.userMail, setMailList(), props.selectedState);
    }


    const setMailList = () => {

        let tempMailList = [];

        refCcArray.current.map((item) => {

            const itemNumber = parseInt(item.value);

            let chosenCC = props.cc.filter((person) => {
                if(itemNumber === person.capo_cantiere_id && item.checked && !item.disabled) {
                    return true
                }
                return false;
            })
            
            if(chosenCC[0]) tempMailList.push(chosenCC[0].email)

            return false;
        });

        props.onSetReduceMailList(tempMailList)

        return tempMailList;
    }

    const resetFiltri = () => {
        props.reparti.map((rep, index) => {
            refRepartiArray.current[rep.reparto_id].checked = true;
            return false;
        })
        props.cc.map((person) => {
            refCcArray.current[person.capo_cantiere_id].checked = true;
            refCcArray.current[person.capo_cantiere_id].disabled = false;
            return false;
        });
        props.onSetSelectedState(0)
        props.onGetDevices(props.role, props.userMail, setMailList(), 0);
    }

    return (
        <>
            <Card>
                <Card.Header className={classes.filtroSv_cardHeader}>
                    Reparto
                    <span style={{float: "right"}}>
                        <Button 
                            variant="outline-primary" 
                            style={{fontSize: "12px"}}
                            size="sm"
                            onClick={() => resetFiltri()}
                        >
                            Reset filtri
                        </Button>
                    </span>
                </Card.Header>
                <Card.Body style={{paddingBottom: '0px', paddingTop: "8px"}}>

                    <Reparti
                        reparti={props.reparti}
                        ccDevicesCounter={props.ccDevicesCounter}
                        refRepartiArray={refRepartiArray}
                        onCheckboxNameClick={onCheckboxNameClick}
                        onCheckboxInputClick={onCheckboxInputClick}
                    />    

                </Card.Body>
            </Card>
            <br/>
            <Card>
                <Card.Header className={classes.filtroSv_cardHeader}>
                    Capo Cantiere
                </Card.Header>
                <Card.Body style={{paddingBottom: '0px', paddingTop: "8px"}}>

                    <CapiCantieri
                        cc={props.cc}
                        ccDevicesCounter={props.ccDevicesCounter}
                        refCcArray={refCcArray}
                        onCheckboxNameClick={onCheckboxNameClick}
                        onCheckboxInputClick={onCheckboxInputClick}
                    />    

                </Card.Body>
            </Card>    
            <br/>
            <Row>
                <Col>
                    <Select
                        placeholder="Seleziona veicolo"
                        options={props.selectOptions}
                        onChange={(e) => handleChange(e)}
                        onInputChange={(e) => processUserInput(e)}
                        inputValue={selectedOption}
                    />
                </Col>
            </Row>

        </>
    )
}


const mapStateToProps = state => {
    return {
        role: state.auth.tokenDecoded.role,
        userMail: state.auth.tokenDecoded.username,
        devices: state.posizioni.devices,
        selectOptions: state.posizioni.selectOptions,
        cc: state.posizioni.cc,
        reparti: state.posizioni.reparti,
        ccDevicesCounter: state.posizioni.ccDevicesCounter,
        selectedState: state.posizioni.selectedState
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onGetDevices: (role, userMail, mailList, selectedState) => dispatch(actions.getDevices(role, userMail, mailList, selectedState)),
        onGetCapiCantieri: () => dispatch(actions.getCapiCantieri()),
        onGetCcDevicesCounter: () => dispatch(actions.getCcDevicesCounter()),
        onSetSelectedDevice: (selectedDevice) => dispatch(actions.setSelectedDevice(selectedDevice)),
        onSetReduceMailList: (mailList) => dispatch(actions.setReduceMailList(mailList)),
        onSetSelectedState: (selectedState) => dispatch(actions.setSelectedState(selectedState))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtro);
