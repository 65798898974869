import React from 'react';

import classes from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

const toolbar = ( props ) => (
    <header className={classes.Toolbar}>
        {props.isAuth ? <DrawerToggle clicked={props.drawerToggleClicked} /> : null}
        <div className={classes.Logo}>
            <Logo />
        </div>

        { props.isAuth ?
            <div className={classes.Username}>
                Utente:&nbsp;<b>{props.userEmail}</b>
            </div>
        : '' }
        
        <nav className={classes.DesktopOnly}>
            <NavigationItems isAuthenticated={props.isAuth} />
        </nav>
    </header>
);

export default toolbar;