import React , { Fragment, useEffect, useState, useRef } from 'react';
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/it';
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import {Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

import MovimentiFilter from './MovimentiFilter';
import MovimentiResults from './MovimentiResults';

import Spinner from '../../components/UI/Spinner/Spinner'


const Movimenti = (props) => {

    const [ ccOptions, setCcOptions] = useState([])


    useEffect(() => {
        const temp = props.cc.sort(function(a, b) {
            if(a.cognome < b.cognome) { return -1 }
            if(a.cognome > b.cognome) { return 1 }
            return false;
        });
        const options = []
        options.push({value:0, label: "Qualsiasi Capo Cantiere"})
        temp.map((item) => {
            options.push({
                value: item.capo_cantiere_id,
                label: item.cognome + ' ' + item.nome
            })
            return false;
        });
        setCcOptions(options);

    }, [props.cc])
    


    return (
        <>
            <MovimentiFilter />
            {
                (!props.spinner) ?
                    (
                    (props.movimenti) ?
                        <MovimentiResults history={props.history}/>
                        :
                        (<></>)
                    )
                :
                    <Spinner />
            }
        </>
    );


}


const mapStateToProps = state => {
    const options = []
    options.push({value:0, label: "Qualsiasi Targa"})
    state.posizioni.selectOptions.map((item) => {

        options.push({
            value: item.value,
            label: item.label
        })
        return false;
    });

    return {
        tokenDecoded: state.auth.tokenDecoded,
        //selectOptions: state.posizioni.selectOptions,
        selectOptions: options,
        devices: state.posizioni.devices,
        cc: state.posizioni.cc,
        year: state.movimenti.year,
        month: state.movimenti.month,
        selectedTarga: state.movimenti.selectedTarga,
        selectedCC: state.movimenti.selectedCC,
        spinner: state.movimenti.spinner,
        movimenti: state.movimenti.movimenti,
        colorFactor: state.movimenti.colorFactor,
        promiscMode: state.movimenti.promiscMode
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetSelectedDevice: (selectedDevice) => dispatch(actions.setSelectedDevice(selectedDevice)),
        onSetSelectedDate: (date) => dispatch(actions.setSelectedDate(date)),
        onSetSelectedTarga: (targa) => dispatch(actions.setSelectedTarga(targa)),
        onSetSelectedCC: (cc) => dispatch(actions.setSelectedCC(cc)),
        onSetClickedTarga: (targa) => dispatch(actions.setClickedTarga(targa)),
        onSetSpinner: (spinner) => dispatch(actions.setSpinner(spinner)),
        onFetchMovimenti: (selectedCC, year, month, promiscMode) => dispatch(actions.fetchMovimenti(selectedCC, year, month, promiscMode)),
        onSetFilterPromiscMode: (checked) => dispatch(actions.setFilterPromiscMode(checked)),
        //onFilterUsingPromiscMode: (promiscMode) => dispatch(actions.filterUsingPromiscMode(promiscMode))
    };
};

export default connect ( mapStateToProps, mapDispatchToProps ) (Movimenti);