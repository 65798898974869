import axios from 'axios';

import * as actionTypes from './actionTypes';

export const posSuccess = (devices) => {

    let devicesTemp = devices.filter(device => {
        if(device.device_id === "") return false
        if(device.latitude === null || device.longitude === null) return false;
        if(device.latitude === 0 || device.longitude === 0) return false;
        return true
    });

    const temp = devicesTemp.sort(function(a, b) {
        if(a.targa < b.targa) { return -1 }
        if(a.targa > b.targa) { return 1 }
        return false;
    });
    const options = []

    //options.push({ label: "Qualsiasi Targa", value: 0 })

    temp.map((item) => {
        options.push({
            label: item.targa + ' ' + (item.codice_mezzo ? item.codice_mezzo : '###'),
            value: item.deviceId
        })
        return false;
    });

    //console.log(devicesTemp.length)

    return {
        type: actionTypes.POS_SUCCESS, 
        devices: devicesTemp,
        selectOptions: options,
    };
};

export const posFail = (error) => {
    return {
        type: actionTypes.POS_FAIL,
        error: error
    };
};

export const ccSuccess = (cc) => {
    
    // Sort cc by cc-name

    const reparti = []

    cc.map(ccRep => {

        const newRepartoFound = reparti.some(r => {
            return r.reparto_id === ccRep.reparto_id
        });

        if(!newRepartoFound)
            reparti.push({reparto_id: ccRep.reparto_id, reparto: ccRep.reparto})

        return false;
    });

    const repartiOrdered = reparti.sort(function(a, b) {
        if(a.reparto < b.reparto) { return -1 }
        if(a.reparto > b.reparto) { return 1 }
        return false;
    });
    
    return {
        type: actionTypes.CC_SUCCESS, 
        cc: cc,
        reparti: repartiOrdered
    };
};

export const ccFail = (error) => {
    return {
        type: actionTypes.CC_FAIL,
        error: error
    };
};

export const ccDevicesCounterSuccess  = (ccDevicesCounter) => {
    return {
        type: actionTypes.CC_DEVICES_COUNTER_SUCCESS, 
        ccDevicesCounter: ccDevicesCounter
    };
};

export const ccDevicesCounterFail = (error) => {
    return {
        type: actionTypes.CC_DEVICES_COUNTER_FAIL,
        error: error
    };
};

export const getDevices= (role, email, mailList = [], selectedState = 0) => {
    let data = {}
    if(role === "sv" || role === "am")
        if(mailList.length === 0) 
            return dispatch => {
                dispatch(posFail("Choose at least 1 CC"));
            }
    return dispatch => {
        //let data = {}
        if (selectedState > 0 ) data['stato_mezzo'] = selectedState;
        if(role === "sv" || role === "am") data['cc'] = mailList;

        //let email = "kk@test.es"
        //axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/actual', { params }, {
        //axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/actual', data, { params: {"role":role, "email":email}})
        axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/actual', data)
        .then(response => {
            if(response.data.retCode === "000") {
                dispatch(posSuccess(response.data.info));
            } else {
                dispatch(posFail(response.data.info[1] + ' - ' + response.data.info[2]));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(posFail(err));
        })
    }
}

export const getCapiCantieri = () => {
        return dispatch => {
        axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/cc', {}, {
            headers: {
            'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.data.retCode === "000") {
                dispatch(ccSuccess(response.data.info));
            } else {
                dispatch(ccFail(response.data.info[1] + ' - ' + response.data.info[2]));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(ccFail(err));
        })
    }
}

export const getCcDevicesCounter = () => {
        return dispatch => {
        axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/cc_stats', {}, {
            headers: {
            'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.data.retCode === "000") {
                dispatch(ccDevicesCounterSuccess(response.data.info));
            } else {
                dispatch(ccDevicesCounterFail(response.data.info[1] + ' - ' + response.data.info[2]));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(ccFail(err));
        })
    }
}


export const setSelectedDevice = (device) => {
    return {
        type: actionTypes.FLY_TO_DEVICE,
        selectedDevice: device
    };
}

export const setReduceMailList =  (mailList) => {
    return {
        type: actionTypes.SET_MAIL_LIST,
        mailList: mailList
    }
}

// This function is used for the legend Filter.
export const setSelectedState = (selectedState) => {
    return {
        type: actionTypes.SET_SELECTED_STATE,
        selectedState: selectedState
    }
}

export const getUserPreferences = () => {
    return dispatch => { 
        axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/getUserPreferences', {}, {
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            if(res.data.retCode === "000") {
                console.log('/getUserPreferences request ok');
                dispatch(getUserPreferencesOK(res.data.info))
            } else {
                console.warn('/getUserPreferences request error: ' + res.data.info);
                dispatch(getUserPreferencesFail())
            }
        }).catch(err => {
            console.error('/getUserPreferences request fail: ' + err);
            dispatch(getUserPreferencesFail())
        });
    }
}


export const getUserPreferencesOK = (data) => {
    console.log(data)
    return {
        type: actionTypes.SET_PREFERENCES_OK,
        customZoom: data.posizioni_zoom ? data.posizioni_zoom : process.env.REACT_APP_POSITIONS_DEFAULT_ZOOM
    }
}


export const getUserPreferencesFail = () => {
    return {
        type: actionTypes.SET_PREFERENCES_FAIL,
        customZoom: process.env.REACT_APP_POSITIONS_DEFAULT_ZOOM
    }
}