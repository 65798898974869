import * as actionTypes from '../actions/actionTypes';

const initialState = {
    targa: null,
    deviceMarkers: null,
    bounds: null,
    error: false,
    loading: false,
    msgInfo: '',
    lastPos: null // last Known Position
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.STORICO_SUCCESS:
            return {
                ...state,
                deviceMarkers: action.deviceMarkers,
                lastPos: null,
                bounds: action.bounds,
                error: false,
                msgInfo: (action.deviceMarkers.length === 0) ? 'Nessuno spostamento rilevato per la data/ora indicata':''
            }
        case actionTypes.STORICO_FAILED:
            return {
                ...state,
                error: true
            }
        case actionTypes.STORICO_LAST_POS_SUCCESS:
            return {
                ...state,
                deviceMarkers: null,
                lastPos: action.lastPos,
                bounds: action.bounds,
                error: false,
            }
        case actionTypes.STORICO_LAST_POS_FAIL:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}

export default reducer;