import React, {useState, useEffect, forwardRef, Fragment} from 'react';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

import classes from './MyStoricoFilter.module.css';

//import './MyStoricoFilter.css';

import * as actions from '../../../store/actions/index';

import Select from 'react-select'

import moment from 'moment'

import { Row, Col, Card, Table } from 'react-bootstrap';
import { connect } from 'react-redux'

import it from "date-fns/locale/it";
registerLocale("it", it);




const Summary = props => {

  if(props.deviceMarkers && props.deviceMarkers.length > 0) {

    const startDate = moment(props.deviceMarkers[0].fixtime)
    const endDate = moment(props.deviceMarkers[props.deviceMarkers.length-1].fixtime)
    
    let delta = endDate.diff(startDate, 'seconds')

    // calculate (and subtract) whole days
    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    const startDistance = props.deviceMarkers[0].km
    const endDistance = props.deviceMarkers[props.deviceMarkers.length-1].km
    let distance = endDistance - startDistance

    let vMax = 0
    let vAvg = 0

    props.deviceMarkers.map(( marker, index ) => {

      if(marker.gps_speed > vMax) vMax = marker.gps_speed
      vAvg = vAvg + marker.gps_speed;
      return false
    })
    vAvg = Math.round(vAvg / (props.deviceMarkers.length - 1))
    vMax = Math.round(vMax)
    return (
      <Row>
        <Col>

          <Card>
              <Card.Header className={classes.filtroStorico_cardHeader}
                >Riepilogo Percorso
              </Card.Header>
              <Card.Body style={{paddingTop: '0px', paddingBottom: '0px', fontSize: '12px'}}>
                <Table style={{minWidth: '200px'}}>
                    <tbody>
                        <tr>
                          <td>Marca e Modello</td>
                          <td align="right">
                            <span style={{color:'grey'}}>
                              {props.deviceMarkers[0].marca + ' ' + props.deviceMarkers[0].modello}
                            </span>
                          </td>
                        </tr>
                        {
                        (props.role === 'sv' || props.role === 'am') ?
                        <Fragment>
                          <tr>
                            <td>Capo Cantiere</td>
                            <td align="right">
                              <span style={{color:'grey'}}>
                                {props.deviceMarkers[0].nome + ' ' + props.deviceMarkers[0].cognome}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Responsabile</td>
                            <td align="right">
                              <span style={{color:'grey'}}>
                                {props.deviceMarkers[0].responsabile}
                              </span>
                            </td>
                          </tr>
                        </Fragment>
                        :
                        ''
                        }
                        <tr>
                          <td>Reparto</td>
                          <td align="right">
                            <span style={{color:'grey'}}>
                              {props.deviceMarkers[0].reparto}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Data/ora Partenza</td>
                          <td align="right">
                            <span style={{color:'grey'}}>
                              {startDate.format('DD-MM-YYYY HH:mm')}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Data/ora Arrivo</td>
                          <td align="right">
                            <span style={{color:'grey'}}>
                              {endDate.format('DD-MM-YYYY HH:mm')}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Durata</td>
                          <td align="right">
                            <span style={{color:'grey'}}>
                              {hours + ' ore, ' + minutes + ' minuti'}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Km percorsi</td>
                          <td align="right">
                            <span style={{color:'grey'}}>
                              {distance} km
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Velocità max (GPS)</td>
                          <td align="right">
                            <span style={{color:'grey'}}>
                              {vMax} km/h
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Velocità media (GPS)</td>
                          <td align="right">
                            <span style={{color:'grey'}}>
                              {vAvg} km/h
                            </span>
                          </td>
                        </tr>
                    </tbody>
                </Table>
                
            </Card.Body>
        </Card>

        </Col>
      </Row>
    );
  }

  /*  Nel caso la casella sia 0, facciamo vedere l'ultima posizione*/
  if(props.lastPos && props.lastPos.length > 0) {
    
    const startDate = moment(props.lastPos[0].fixtime)

    return (
      <Row>
        <Col>

          <Card>
              <Card.Header className={classes.filtroStorico_cardHeader}
                >Ultima posizione
              </Card.Header>
              <Card.Body style={{paddingTop: '0px', paddingBottom: '0px', fontSize: '12px'}}>
                <Table style={{minWidth: '200px'}}>
                    <tbody>
                        <tr>
                          <td>Marca e Modello</td>
                          <td align="right">
                            <span style={{color:'grey'}}>
                              {props.lastPos[0].marca + ' ' + props.lastPos[0].modello}
                            </span>
                          </td>
                        </tr>
                        {
                        (props.role === 'sv' || props.role === 'am') ?
                        <Fragment>
                          <tr>
                            <td>Capo Cantiere</td>
                            <td align="right">
                              <span style={{color:'grey'}}>
                                {props.lastPos[0].nome ? props.lastPos[0].nome + ' ' + props.lastPos[0].cognome : '-'}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Responsabile</td>
                            <td align="right">
                              <span style={{color:'grey'}}>
                                {props.lastPos[0].responsabile ? props.lastPos[0].responsabile : '-'}
                              </span>
                            </td>
                          </tr>
                        </Fragment>
                        :
                        ''
                        }
                        <tr>
                          <td>Reparto</td>
                          <td align="right">
                            <span style={{color:'grey'}}>
                              {props.lastPos[0].reparto ? props.lastPos[0].reparto : '-'}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Ultima Posizione</td>
                          <td align="right">
                            <span style={{color:'grey'}}>
                              {startDate.format('DD-MM-YYYY HH:mm')}
                            </span>
                          </td>
                        </tr>
                
                    </tbody>
                </Table>
                
            </Card.Body>
        </Card>

        </Col>
      </Row>
    );
  } // end if

  
  return (<></>)
}


const ErrorMessage = (props) => {
  if(props.error)
    return (
      <Row className="justify-content-md-center">
        <Col md="auto">
          <div style={{color: '#ff9933', border: '1px solid #669999', padding: '5px'}}>{props.error}</div>
        </Col>
      </Row>
    )
  else return <></>
}



const MyStoricoFilter = (props) => {

  const [targa, setTarga] = useState(null)
  const [searchDate, setSearchDate] = useState(new Date());

  const [hourStartInput, setHourStartInput] = useState(0);
  const [hourStopInput, setHourStopInput] = useState(24);

  const [hourStartSlider, setHourStartSlider] = useState(0);
  const [hourStopSlider, setHourStopSlider] = useState(24);

  const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <button className="btn btn-outline-primary btn-sm" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));


  useEffect(() => {
    /*
    console.log("1")
    console.log(targa)
    console.log(searchDate)
    */
    let targaTemp = null
    let searchDateTemp = null
    
    if(props.movimentiClickedTarga && targa === null) { // targa that comes from Movimenti
      //console.log(2)
      setTarga(props.movimentiClickedTarga)
      targaTemp = props.movimentiClickedTarga
      
      //console.log(props.movimentiSelectedDate) // 2021-08-03
      setSearchDate(Date.parse(props.movimentiSelectedDate))
      
      //props.onGetDeviceMarkers(props.movimentiClickedTarga, moment(props.movimentiSelectedDate).format('YYYY-MM-DD'), hourStartSlider, hourStopSlider)
      //searchDateTemp = props.movimentiSelectedDate

    } 
    
    if(targa !== null && searchDate !== null) {
      //console.log(3)
      /*
      console.log(targa)
      console.log(searchDate)
      console.log(hourStartSlider)
      console.log(hourStopSlider)
      */
      props.onGetDeviceMarkers(targa, moment(searchDate).format('YYYY-MM-DD'), hourStartSlider, hourStopSlider)
      //props.onSetSelectedMovimentiTarga(null)
      // Test Params
      //props.onGetDeviceMarkers('FE567HC', '2021-04-19', 0, 1)
      targaTemp = targa
      searchDateTemp = moment(searchDate).format('YYYY-MM-DD')
    }
    


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targa , searchDate, hourStartSlider, hourStopSlider]);


  /*
  useEffect(() => {
    console.log(21)

    if(props.movimentiClickedTarga) {
      console.log(22)
      setTarga(props.movimentiClickedTarga)
      setSearchDate(Date.parse(props.movimentiSelectedDate))
      props.onGetDeviceMarkers(props.movimentiClickedTarga, moment(props.movimentiSelectedDate).format('YYYY-MM-DD'), hourStartSlider, hourStopSlider)
    }
  }, [props.movimentiClickedTarga])
  */


  const handleSelectChange = (e) => {
    const targa = e.label.split(' ')
    setTarga(targa[0])
  }

  const handeDateChange = (date) => {
    setSearchDate(date)
  }

  // activated on mouseUp
  const sliderValuesChanged = (values) => {
    setHourStartSlider(values[0])
    setHourStopSlider(values[1])
  }

  // Activated on every step
  const setInputValues = (values) => {
    setHourStartInput(values[0])
    setHourStopInput(values[1])  }

  return (
    <>
        <div style={{height:'8px'}}></div>
        <div className={classes.Container}>

          <Row>
            <Col>

              <Row>
                <Col>
                  <Select
                      placeholder="Seleziona Targa"
                      options={props.selectOptions}
                      onChange={(e) => handleSelectChange(e)}
                      value= {
                        props.movimentiClickedTarga ? props.selectOptions.filter(option => {
                          const myArr = option.label.split(" ");
                          if( myArr[0] === props.movimentiClickedTarga ) return true;
                          return false
                        }): 
                        props.selectOptions.filter(option => {
                          const myArr = option.label.split(" ");
                          if( myArr[0] === targa ) return true;
                          return false
                        })
                      }
                  />
                </Col>
              </Row>

              <div style={{height:'8px'}}></div>

              

              <Card>
                <Card.Body style={{paddingTop: '6px', paddingBottom: '0px', fontSize: '12px'}}>

                  <Row xs={1} md={2}>
                    <Col md="4">
                      <div style={{float:'left', top: '5px', position: 'absolute'}}>
                        <span style={{paddingRight: '5px'}}>Min: </span>
                        <span style={{padding: "5px", backgroundColor: "#ccc", borderRadius: "3px"}}>{hourStartInput}</span>
                      </div>
                    </Col>
                  

                    <Col md="4">

                      <div>
                        <DatePicker 
                          locale="it"
                          selected={searchDate} 
                          onChange={(date) => handeDateChange(date)} 
                          dateFormat='dd/MM/yyyy'
                          style={{textAlign: "center"}}
                          customInput={<CustomDatePickerInput />}
                        />
                      
                      </div>

                    </Col>


                  <Col md="4">
                    <div style={{float:'right', top: '5px', right: '14px', position: 'absolute'}}>
                      <span style={{paddingRight: '5px'}}>Max: </span>
                      <span style={{padding: "5px", backgroundColor: "#ccc", borderRadius: "3px"}}>{hourStopInput}</span>
                    </div>
                  </Col>
                  <Col>
                    
                  </Col>
                </Row>
              
                <div style={{height:'8px'}}></div>

                <Row>
                  <Col>
                    <Range 
                      onAfterChange={(e) => sliderValuesChanged(e)} 
                      min={0} 
                      max={24} 
                      draggableTrack={true} 
                      defaultValue={[0, 24]}
                      allowCross={false}
                      pushable={1}
                      onChange={(e) => setInputValues(e)}
                    />
                  </Col>
                </Row>
                <div style={{height:'8px'}}></div>
              </Card.Body></Card>


            </Col>
          </Row>

          <div style={{height:'8px'}}></div>
          <ErrorMessage error={props.msgInfo}/>
          <Summary deviceMarkers={props.deviceMarkers} role={props.role} lastPos={props.lastPos} />      


        </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    role: state.auth.tokenDecoded.role,
    userMail: state.auth.tokenDecoded.username,
    devices: state.posizioni.devices,
    selectOptions: state.posizioni.selectOptions,
    cc: state.posizioni.cc,
    msgInfo: state.storico.msgInfo,
    deviceMarkers: state.storico.deviceMarkers,
    movimentiClickedTarga: state.movimenti.clickedTarga,
    movimentiSelectedDate: state.movimenti.selectedDate,
    lastPos: state.storico.lastPos
  }
}


const mapDispatchToProps = dispatch => {
    return {
        onGetDevices: (role, userMail, mailList) => dispatch(actions.getDevices(role, userMail, mailList)),
        onGetDeviceMarkers: (targa, searchDate, hourStart, hourStop) => dispatch(actions.getDeviceMarkers(targa, searchDate, hourStart, hourStop)),
        onSetSelectedMovimentiTarga: (selectedMovimentiTarga) => dispatch(actions.setSelectedTarga(selectedMovimentiTarga))
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (MyStoricoFilter);