import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
//import './index.module.css';
import App from './App';
import authReducer from './store/reducers/auth'
import posReducer from './store/reducers/posizioni'
import storicoReducer from './store/reducers/storico'
import refuelingsReducer from './store/reducers/refuelings'
import refundsReducer from './store/reducers/refunds'
import movimentiReducer from './store/reducers/movimenti'
import interventiReducer from './store/reducers/interventi'
import garageReducer from './store/reducers/garage'
import reportWebVitals from './reportWebVitals';


const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  posizioni: posReducer,
  storico: storicoReducer,
  refuelings: refuelingsReducer,
  refunds: refundsReducer,
  movimenti: movimentiReducer,
  interventi: interventiReducer,
  garage: garageReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));


const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render( app, document.getElementById( 'root' ) );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
