import React, { useState } from 'react';
import { Popup } from 'react-leaflet';
import Moment from 'react-moment';

import { Card, Table } from 'react-bootstrap';

import classes from './MarkerPopup.module.css';
import 'bootstrap/dist/css/bootstrap.css';

import { capitalizeTheFirstLetterOfEachWord } from '../../../utils/utils'

import {calcCardinal}  from '../../../utils/calculus';

const DateFormat = (props) => {
    if(props.device.stato_sconosciuto)
        return (
            <span style={{color: "red"}}>
                <Moment format="DD/MM/YYYY">{props.device.fixTime}</Moment>
            </span>
    )
    return <Moment format="DD/MM/YYYY">{props.device.fixTime}</Moment> 

}

const MarkerPopup = (props) => {
    const { device } = props;
    const [hidden, setHidden] = useState(true);
    /*
    const toggleHidden = () => {
        console.log(hidden)
        setHidden(!hidden)
    };
    */
/*
    const roundCSQ = (csq) => {
        if(csq !== undefined) return Math.round((( csq * 3.1) + 0.00001) * 100) / 100
        else return 0
    }

    const roundGSM = (rssi) => {
        if( rssi !== undefined ) return Math.round(rssi * 20)
        else return 0
    }
 */   
    return (
        <Popup>
           
            <Card className={classes.markerCard}>
                <Card.Header className={classes.header}>
                    
                    <div className="mb-0">
                        <span className={classes.markerTitle}>
                            { device.targa } - {device.codice_mezzo}
                        </span>

                    </div>
                </Card.Header>
                <Card.Body className={classes.card_body}>
                    <Table style={{minWidth: '200px'}}>
                        <thead></thead>
                        <tbody>
                            <tr><td>Marca<br/>Modello</td><td align="right"><span style={{color:'grey'}}>{capitalizeTheFirstLetterOfEachWord(device.marca)}<br/> {capitalizeTheFirstLetterOfEachWord(device.modello)}</span></td></tr>
                            <tr><td>Reparto</td><td align="right"><span style={{color:'grey'}}>{device.azienda} / {device.reparto}</span></td></tr>
                            <tr>
                                <td>Aggiornato il</td>
                                <td align="right">
                                    <span style={{color:'grey'}}>
                                        <DateFormat device={device}/>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>Velocità</td>
                                <td align="right">
                                    <span style={{color:'grey'}}>
                                        {Math.round(device.speed)} km/h
                                    </span>
                                    {(device.speed !== 0) ? 
                                        <span>&nbsp;&nbsp;&nbsp; {calcCardinal(device.course)}</span>
                                        :
                                        ''
                                    }
                                    
                                </td>
                            </tr>
                            {(props.role === "sv" || props.role === 'am') ? 
                                <tr><td>C.C.</td><td align="right"><span style={{color:'grey'}}>{props.cc.nome + ' ' + props.cc.cognome}</span></td></tr>
                                :
                                ''
                            }
                            {(props.role === "sv" || props.role === 'am') ? 
                                <tr><td>Responsabile</td><td align="right"><span style={{color:'grey'}}>{props.device.responsabile !== '' ? props.device.responsabile : 'n/d'}</span></td></tr>
                                :
                                ''
                            }
                            {!hidden?<tr><td>Latitude</td><td align="right"><span style={{color:'grey'}}>{device.latitude}</span></td></tr>:''}
                            {!hidden?<tr><td>Longitude</td><td align="right"><span style={{color:'grey'}}>{device.longitude}</span></td></tr>:''}
                        </tbody>
                    </Table>
                    
                </Card.Body>
            </Card>
            {/*<p></p>
            <Link to={'/percorsi/'+device.deviceId}>Vedere Percorso</Link>
            */}
                   
        </Popup>
    )
}

export default MarkerPopup
 