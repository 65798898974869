import React, { useEffect, Fragment } from "react";

import * as L from 'leaflet';

import classes from './MyMap.module.css';
import { measureDistance } from '../../utils/calculus';
import { setTrackIcon, setVehicleIcon } from '../../utils/gebIcons'
import "leaflet-polylinedecorator";

import { connect } from 'react-redux';

import classesLegend from './Legend.module.css'

import { Icon } from "leaflet";


/*
Per fare funzionare bene i leaflet-polylineDecorator si deve fare l'update di questa libreria alla versione 1.6.1. Al momento il repositorio non lo permete (26-03-2021),
pero si puo scaricare l'ultima versione in atto (versione 1.6.0 ) e modificare il codice della libreria nodes_module/leaflet-polylinedecorator/dist/leaflet.PolylineDecorator.js
La modificha da fare si trovano su:
https://github.com/bbecquet/Leaflet.PolylineDecorator/pull/87/commits/05cc9877200ec346281306c56bf8bd039808afda

*/

const MAP_TILE =
  "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

const MyMap = (props) => {

  // Define the styles that are to be passed to the map instance:
  const mapStyles = {
    overflow: "hidden",
    width: "100%",
    height: "100%"
  };

  // Some layers that will be applied to our map:
  const layers = {
    markers: L.layerGroup(),
    polylines: L.layerGroup(),
    polylinesDeco: L.layerGroup()
  };

  // Create our map tile layer:
  const map_tile = L.tileLayer(MAP_TILE, {
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  });

  // Define the base maps and overlays object that will be
  // passed to our layer control:
  
  const overlays = {
    markers: layers.markers,
    polylines: layers.polylines,
    polylinesDeco: layers.polylinesDeco
  };
  const baseMaps = {
    OpenStreetMap: map_tile
  };
  
  // Define the params that will be passed to the map:
  const mapParams = {
    center: [42.04742983, 12.36848],
    zoom: 6,
    zoomControl: false,
    //maxBounds: L.latLngBounds(L.latLng(-150, -240), L.latLng(150, 240)),
    closePopupOnClick: false,
    layers: [
      map_tile,
      layers.markers,
      layers.polylines,
      layers.polylinesDeco
    ]
  };

  const arrow = [
  {
    offset: 25, repeat: 100,
        symbol: L.Symbol.arrowHead({
            pixelSize: 12,
            polygon: true,
            pathOptions: {
                stroke: true,
                color: '#000000',
                weight: 1,
                fillColor: 'grey',
                fillOpacity: 1
                }
            }
        )
    }
  ];

  const buildLegend = (map) =>{

    const getColor = speed => {
    if (speed >= 0 && speed <= 50)
      return 'green'
    if (speed > 50 && speed <= 130)
      return 'blue'
    if (speed > 130)
      return 'red'
    else return 'grey'
    };

    const legend = L.control({ position: "bottomleft" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", classesLegend.info + ' ' + classesLegend.legend);
      const grades = [0, 50, 130];
      let labels = [];
      let from;
      let to;

      for (let i = 0; i < grades.length; i++) {
        from = grades[i];
        to = grades[i + 1];

        labels.push(
          '<div>' +
            '<i>' +
              '<i style="width: 100%; margin: auto; margin-top: 40%; margin-bottom: 5%; border-top: 4px solid ' +
                getColor(from + 1) + '"></i>' + 
            '</i>' +
            '<span style="font-weight: bold;">' + from + '</span>' +
            (to ? " <span style=\"color:#666666; font-size: 11px\">a</span> " +
            '<span style="font-weight: bold;">' +  to + '</span>' : "+") + 
            ' <span style="color:#666666; font-size: 11px">km/h</span>' + 
          '</div>'
        );
      }
      div.innerHTML = labels.join("<br>");      
      return div;
    };

    legend.addTo(map);

  };

  useEffect(() => {
    const map = L.map("map", mapParams)
    if(props.deviceMarkers && props.deviceMarkers.length > 0 ) {
      updateMarkers(map, props.deviceMarkers)
      buildLegend(map)
    }
    if(props.lastPos && props.lastPos.length > 0) {
      //updateMarkers(map, props.lastPos)
      updateLastPos(map, props.lastPos)
      //buildLegend(map)
    }

    //if(props.deviceMarkers && props.deviceMarkers.length > 0)
    //  buildLegend(map)

    return function cleanup() {
      map.remove();
      cleanMap()
    }
  }, [ props.deviceMarkers ]);

  const cleanMap = () => {
      layers['markers'].clearLayers()
      layers['polylines'].clearLayers()
      layers['polylinesDeco'].clearLayers()    
  }

  const parseDate = (dateToParse) => {

    /*
    let userTimeZone = null;
    userTimeZone = moment.tz.guess()
    if(!userTimeZone) userTimeZone = "Europe/Rome"
    marker.fixTime = moment(marker.fixTime).tz(userTimeZone).format('DD-MM-YYYY HH:MM')
    */

    const tempArray = dateToParse.split("+")
    const tempArray2 = tempArray[0].split("T")
    const newTimeString = tempArray2[0] + ' ' + tempArray2[1]
    return newTimeString;
  }

  const getPathColor = (speed) => {
    if (speed >= 0 && speed <= 50)
      return 'green'
    if (speed > 50 && speed <= 130)
      return 'blue'
    if (speed > 130)
      return 'red'
    else return 'grey'
  }

  const setDeviceClass = (device= null) => {
      if(device.veicolo === null || device.stato_sconosciuto) return classes.class1_unknown_device;
      if(device.speed === 0) return classes.class1_nonActive_device;
      else return classes.class1_active_device;
  }

  const updateLastPos = (map, marker) => {
    cleanMap()

    L.control.zoom({ position: "topleft" }).addTo(map);
    if(marker && marker.length > 0) {
      map.fitBounds(props.bounds)

      const positionMarker = L.marker(
          [marker[0].latitude, marker[0].longitude],
          {
              icon: new Icon({
                        iconUrl: setVehicleIcon(marker[0]),
                        iconSize: [25, 41],
                        iconAnchor: [25, 41],
                        popupAnchor: [-10, -40],
                        className: setDeviceClass(marker[0])
                    })
          }
      )
      .addTo(layers.markers)
    }
  }


  const updateMarkers = ( map, markers ) => {
    cleanMap()

    // Create the layer control:
    //const layerControl = L.control.layers(baseMaps, overlays).addTo(map);
    
    // Add a zoom control:
    L.control.zoom({ position: "topleft" }).addTo(map);
    if(markers && markers.length > 0) {
        map.fitBounds(props.bounds)

        const maxDistanceToDraw = 1;
        let accumDistance = 0;
        let lastPosition = [markers[0].latitude, markers[0].longitude]; 

        markers.map(( marker, index ) => {

            let speed = 0;

            if(marker.speed !== null) {
              if(marker.speed > marker.gps_speed) speed = Math.round(marker.speed);
              else {
                if (marker.gps_speed !== null)
                  speed = Math.round(marker.gps_speed)
              }
            } else {
              if (marker.gps_speed !== null)
                speed = Math.round(marker.gps_speed);
            }

            marker.fixTime = parseDate(marker.fixtime)
            
            if(marker.latitude === null || marker.longitude === null) return false;
            let polylinePoints = [
                lastPosition,
                [marker.latitude, marker.longitude],
            ];
            let distance = measureDistance(polylinePoints[0][0], polylinePoints[0][1], polylinePoints[1][0], polylinePoints[1][1]);
            accumDistance = accumDistance + distance;

            let drawMarker = false;
            if(accumDistance > 1 ) {
                drawMarker = true;
                accumDistance = 0;
            }

            lastPosition = [marker.latitude, marker.longitude];

            if( 0 < index  && index < markers.length - 1) {
                const polyline = L.polyline(polylinePoints, {
                    color: getPathColor(speed),
                    weight: 6,
                    opacity: 0.5,
                    smoothFactor: 1
                })
                .addTo(layers.polylines)
                .bindTooltip('<strong>DATA:</strong> ' + marker.fixTime + '<br/>' + '<strong>VELOCITA:</strong> ' + speed + ' km/ora');
                /*
                const interMarkers = L.marker(
                    [marker.latitude, marker.longitude],
                    {
                        icon: L.icon({
                        iconUrl: setTrackIcon(marker, 0, markers.length-1),
                        iconSize: [25, 41],
                        iconAnchor: [25, 41],
                        popupAnchor: [-10, -40]
                        })
                    }
                )
                .addTo(layers.markers)
                */
                if(drawMarker) {
                    var decorator = L.polylineDecorator(polylinePoints, {
                        patterns: arrow
                    })
                    .addTo(layers.polylinesDeco)
                    .bindTooltip('<strong>DATA:</strong> ' + marker.fixTime + '<br/>' + '<strong>VELOCITA:</strong> ' + speed + ' km/ora');
                }
            
            } else {
                let edgeTitle;
                if(index === 0) edgeTitle = "Inizio Percorso"
                else edgeTitle = "Fine Percorso"
                
                const edgesMarkers = L.marker(
                    [marker.latitude, marker.longitude],
                    {
                        icon: L.icon({
                        iconUrl: setTrackIcon(marker, index, markers.length-1),
                        iconSize: [25, 41],
                        iconAnchor: [25, 41],
                        popupAnchor: [-10, -40]
                        })
                    }
                )
                .addTo(layers.markers)
                .bindTooltip('<div style="font-size: 18px;border-bottom: 1px solid #ccc">' + edgeTitle +'</div><div style="padding-top: 4px"><strong>DATA:</strong> ' + marker.fixTime + '<br/>' + '<strong>VELOCITA:</strong> ' + speed + ' km/ora</div>');

            }
            
        });
    }
  }


  return (
    
    <Fragment>

        <div className={classes.container}>
            <div className={classes.map}>
                <div id="map" style={mapStyles} />
            </div>
        </div>

    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    deviceMarkers: state.storico.deviceMarkers,
    lastPos: state.storico.lastPos,
    bounds: state.storico.bounds
  }
}

export default connect(mapStateToProps, null)(MyMap);