import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Card , Row, Col, Table, Modal} from 'react-bootstrap'
import { FaTrashAlt, FaFileDownload } from "react-icons/fa";

import Moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/it';


const FileManager = (props) => {

  const userRole = props.tokenDecoded.role
  const device_id = props.selectedDevice
  const doc_type = props.doc_type

  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState(null);

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [selectedFileUuid, setSelectedFileUuid] = useState(null)

  useEffect(() => {
    updateData()
    return function cleanup() {
        setFiles([])
    }
    
  }, [device_id])

  const updateData = async (data) => {
    await axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/files_request/' + device_id + '/' + doc_type, {
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        if(res.data.retCode === "000") {
            setFiles(res.data.info.files)
        }
    }).catch(err => {
        console.error('/files request fail: ' + err);
    });
  }


  const deleteSelectedFile = async () => {

    await axios.delete(process.env.REACT_APP_GEB_MIDDLEWARE + '/file/' + selectedFileUuid, {
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        if(res.data.retCode === "000") {
            let tempFiles = files.filter(file => file.fat_uuid !== selectedFileUuid)
            setFiles(tempFiles)
        }
    }).catch(err => {
        console.error('/files request fail: ' + err);
    });    
  }

  const downloadFile = async (device_id, doc_type, file) => {

    axios({
        url: process.env.REACT_APP_GEB_MIDDLEWARE + '/fat_download/' + device_id + '/' + doc_type,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', doc_type + '_' + file.filename); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch(err => {
        console.error('/files request fail: ' + err);
    });

  }

  const upadteDescription = (e) => {
    setDescription(e.target.value)
  }

  const openConfirmDeleteModal = (e, uuid) => {
    setShowConfirmDeleteModal(true)
    setSelectedFileUuid(uuid)
  }
  const closeConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false)
    setSelectedFileUuid(null)
  }


  return (
    <>
      {files && files.length > 0 ?
      <>
        <Row>
            <Col>
                <Card>
                    
                    <Card.Body>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Data di creazione</th>
                                        <th>Nome File</th>
                                        <th>Formato</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {   
                                        files.map(file => {
                                            if(file.trash == false)
                                            return (
                                            <tr>
                                                <td>{file.fat_create ?
                                                    Moment(file.fat_create, "DD/MM/yyyy HH:mm").format('D MMM yyyy')
                                                    :
                                                    'No Date'}
                                                </td>
                                                <td>{file.filename}</td>
                                                <td>{file.mime_type}</td>
                                                <td align="center">
                                                    {['am', 'sv'].indexOf(userRole) > -1 ?
                                                        <button 
                                                            style={{ padding: "0px 5px", marginRight: '10px', backgroundColor:'red', borderColor: 'red' }} 
                                                            class="btn btn-primary" title="Cancela File" 
                                                            onClick={(e) => openConfirmDeleteModal(e, file.fat_uuid)}
                                                        >
                                                            <FaTrashAlt />
                                                        </button>
                                                        :
                                                        <></>
                                                    }
                                                    
                                                    <button 
                                                        style={{ padding: "0px 5px" }} 
                                                        class="btn btn-primary" title="Scarica File" 
                                                        onClick={() => downloadFile(device_id, doc_type, file)}
                                                    >
                                                        <FaFileDownload/>
                                                    </button>

                                                </td>
                                            </tr>)
                                        })
                                    }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </Row> 
        <Modal
            show={showConfirmDeleteModal}
            //onHide={this.closeFilesModal.bind(this)}
            backdrop="static" 
            keyboard={false}
            size="sm"
            >
            <Modal.Header>
                
                <Modal.Body>Confermi cancellazione file ?</Modal.Body>
            </Modal.Header>
            <Modal.Footer>
                <button class="btn btn-primary" onClick={() => deleteSelectedFile()}>
                    Conferma
                </button>
                <button class="btn btn-secondary" onClick={() => closeConfirmDeleteModal()}>
                    Cancella
                </button>
            </Modal.Footer>
        </Modal>
      </>
      :
      <></>  
      } 


    </>
  )
}

export default FileManager
