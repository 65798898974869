import React , { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classes from './Movimenti.module.css';
import './MovimentiFilter.css'
import Select from 'react-select';
import MyMonthPicker from '../../components/UI/MyMonthPicker/MyMonthPicker'

import * as actions from '../../store/actions/index';

import promiscIcon from "../../assets/images/work_on_site.png"


const MovimentiFilter = (props) => {

    const [ ccOptions, setCcOptions] = useState([])

    useEffect(() => {
        if(props.role === 'am' || props.role === "sv") {
            const temp = props.cc.sort(function(a, b) {
                if(a.cognome < b.cognome) { return -1 }
                if(a.cognome > b.cognome) { return 1 }
                return false;
            });
            const options = []
            options.push({value:0, label: "Qualsiasi Capo Cantiere"})
            temp.map((item) => {
                options.push({
                    value: item.capo_cantiere_id,
                    label: item.cognome + ' ' + item.nome
                })
                return false;
            });
            setCcOptions(options);


            // Rellenar  select Options Targhe
            /**/
            if(props.selectedCC == 0) {
            
                props.onResetTargheOptions(props.targheOptionsReset)

            }
            
        }

    }, [props.cc])



    useEffect(() => {
        //if(props.selectedCC) props.onFetchMovimenti(props.selectedCC.value, props.year, props.month, props.promiscMode)
        if(props.role === "am" || props.role === "sv") {
            if(props.selectedCC && props.year) props.onFetchMovimenti(props.selectedCC.value, props.year, props.month, props.promiscMode)
        }
    //}, [props.selectedCC, props.year, props.month])
    }, [props.selectedCC, props.year, props.month, props.promiscMode])

    useEffect(() => {
        if(props.role === "cc") {
            props.onSetSelectedCC({
                label: props.ccName + ' ' + props.ccSurname,
                value: props.ccId
            });
            /*
            let currentCC = props.cc.filter(item => {
                if(item.email === props.username) return true;
                else return false;
            })
            console.log(currentCC)
            props.onFetchMovimenti(currentCC.capo_cantiere_id, props.year, props.month, props.promiscMode)
            */
            if(props.ccId) {
                props.onFetchMovimenti(props.ccId, props.year, props.month, props.promiscMode)
            }
            props.onGetCCTarghe(props.username)
        }
    }, [props.year, props.month])

    const handlePromicMode = (e) => {
        //console.log(e.target.checked)
        props.onSetFilterPromiscMode(e.target.checked)
    }

    const handlePromicMode2 = (checked) => {
        console.log(checked)
        props.onSetFilterPromiscMode(!checked)
        if(props.ccId && props.month)
            props.onFetchMovimenti(props.ccId, props.year, props.month, !checked)
    }

    const processTargaInput = (e) => {
        const newVal = e.replace(/\s/g, '');
        //setTarga(newVal)
        return newVal
    }

    const handleChange = (selectedOption, sourceId) => {

        //console.log(selectedOption, sourceId)

        if (selectedOption) {

            if( sourceId == 1 ) { // targhe
                props.onSetSelectedTarga(selectedOption)
            }
            else if ( sourceId == 2 ) { // capi cantiere
                props.onSetSpinner(true)
                props.onSetSelectedCC(selectedOption)
                //console.log(selectedOption)

                if(props.role === 'am' || props.role === 'sv') {
                    
                    const requestedCC = props.cc.filter(function(item) {
                        //console.log(item, selectedOption.value )
                        if(selectedOption.value === item.capo_cantiere_id) return true;
                        return false;
                    });
                    //console.log(requestedCC)
                    if(requestedCC.length > 0)
                        props.onGetCCTarghe(requestedCC[0].email)
                    //else props.onGetCCTarghe(0)
                }
            }
                        
        }

    };


    if(props.role === 'am' || props.role==="sv") // filtri per amministratori
        return (
            <div style={{marginTop: '10px'}}>
                <div className={classes.movimentiFilters}>
                    <div style={{width:"150px"}} >
                        <MyMonthPicker />
                    </div>

                    <div style={{width: '35%'}}>
                        <Select
                            options={ccOptions}
                            onChange={(e) => handleChange(e, 2)}
                            value={props.selectedCC}
                        />
                    </div>
                    
                    <div style={{width: '210px'}}>
                        <Select
                            placeholder="Qualsiasi Targa"
                            options={props.selectedCC.value == 0 ? props.targheOptionsReset : props.targheOptions}
                            onChange={(e) => handleChange(e, 1)}
                            onInputChange={(e) => processTargaInput(e)}
                            value={props.selectedTarga}
                        />
                    </div>

                    <div>
                        <OverlayTrigger
                            key={'top'}
                            placement={'right'}
                            overlay={
                                <Tooltip id={`tooltip-${'top'}`}>
                                    {props.promiscMode === false ? 
                                        'Clicka per visualizzare tutti i veicoli'
                                        :
                                        'Clicka per visualizzare solo i veicoli esclusivamente lavorativi'
                                    }
                                </Tooltip>
                            }
                        >
                            <img 
                                src={promiscIcon}
                                width="40"
                                height="40"
                                className={'promiscIcon ' + (!props.promiscMode ? classes.selected : '')}
                                onClick={() => handlePromicMode2(props.promiscMode)}
                            />
                        </OverlayTrigger>


                    </div>
                    <div style={{width:"170px", paddingTop: "10px"}} >
                        Totale automezzi: {Object.keys(props.movimenti.movements).length}
                    </div>
                </div>
            </div>
        );
    else // impostare filtri per i cc
        return (
            <div>
                <div className={classes.movimentiFiltersCC}>
                    <div style={{width:"150px"}} >
                        <MyMonthPicker />
                    </div>
                    
                    <div style={{width: '210px'}}>
                        <Select
                            placeholder="Qualsiasi Targa"
                            options={props.targheOptions}
                            onChange={(e) => handleChange(e, 1)}
                            onInputChange={(e) => processTargaInput(e)}
                            value={props.selectedTarga}
                        />
                    </div>

                    
                    <div>
                        <OverlayTrigger
                            key={'top'}
                            placement={'right'}
                            overlay={
                                <Tooltip id={`tooltip-${'top'}`}>
                                    {props.promiscMode ? 
                                        'Clicka per selezionare i mezzi ad uso esclusivamente lavorativo'
                                        :
                                        'Clicka per selezionare i mezzi ad uso promiscuo'
                                    }
                                </Tooltip>
                            }
                        >
                            <img 
                                src={promiscIcon}
                                width="40"
                                height="40"
                                className={'promiscIcon ' + (props.promiscMode ? classes.selected : '')}
                                onClick={() => handlePromicMode2(props.promiscMode)}
                            />
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
        );
}

const mapStateToProps = state => {
    /*
    const targheOptions = []
    targheOptions.push({value:0, label: "Qualsiasi Targa"})
    state.movimenti.selectOptions.map((item) => {

        targheOptions.push({
            value: item.value,
            label: item.label
        })
        return false;
    });
    */


    const temp = state.movimenti.selectOptions.sort(function(a, b) {
        if(a.cognome < b.cognome) { return -1 }
        if(a.cognome > b.cognome) { return 1 }
        return false;
    });
    const targheOptions = []
    targheOptions.push({value:0, label: "Qualsiasi Targa"})
    temp.map((item) => {
        targheOptions.push({
            value: item.value,
            label: item.label
        })
        return false;
    });

    const targheOptionsReset = []
    targheOptionsReset.push({value:0, label: "Qualsiasi Targa"})
    state.posizioni.selectOptions.map((item) => {
        targheOptionsReset.push({
            value: item.value,
            label: item.label
        })
        return false;
    });

    //console.log(state.movimenti.movimenti)

    return {
        token: state.auth.tokenDecoded,
        role: state.auth.tokenDecoded.role,
        username: state.auth.tokenDecoded.username,
        cc: state.posizioni.cc,
        year: state.movimenti.year,
        month: state.movimenti.month,
        targheOptions: targheOptions,
        targheOptionsReset: targheOptionsReset,
        selectedCC: state.movimenti.selectedCC,
        selectedTarga: state.movimenti.selectedTarga,
        promiscMode: state.movimenti.promiscMode,
        ccId: state.movimenti.ccId,
        ccName: state.movimenti.ccId,
        ccSurname: state.movimenti.ccId,
        movimenti: state.movimenti.movimenti,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetSpinner: (spinner) => dispatch(actions.setSpinner(spinner)),
        onSetSelectedCC: (cc) => dispatch(actions.setSelectedCC(cc)),
        onSetFilterPromiscMode: (checked) => dispatch(actions.setFilterPromiscMode(checked)),
        onSetSelectedTarga: (targa) => dispatch(actions.setSelectedTarga(targa)),
        onFetchMovimenti: (selectedCC, year, month, promiscMode) => dispatch(actions.fetchMovimenti(selectedCC, year, month, promiscMode)),
        onGetCCInfo: (username) => dispatch(actions.getCCInfo(username)),
        onGetCCTarghe: (selectedCC) => dispatch(actions.getCCTarghe(selectedCC)),
        onResetTargheOptions: (options) => dispatch(actions.resetTargheOptions(options))
    };
};

export default connect ( mapStateToProps, mapDispatchToProps ) (MovimentiFilter);