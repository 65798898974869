import React, { Component } from 'react';
import Layout from './components/Layout/Layout';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
//import GlobalTracker from './containers/GlobalTracker/GlobalTracker';
import Posizioni from './containers/Posizioni/Posizioni';
//import Percorsi from './containers/Percorsi/Percorsi';
import Storico from './containers/Storico/Storico';
import Refuelings from './containers/Refuelings/Refuelings';
import RefuelingsCheckbox from './containers/RefuelingsCheckbox/RefuelingsCheckbox';
import Garage from './containers/Garage/Garage';
import Movimenti from './containers/Movimenti/Movimenti';
import Interventi from './containers/Interventi/Interventi';
import Settings from './containers/Settings/Settings';
import Auth from './containers/Auth/Login/Auth';
import Manual from './containers/Manual/Manual';
import Logout from './containers/Auth/Logout/Logout';
import * as actions from './store/actions/index';


class App extends Component {

  componentDidMount() {
    this.props.onTryAutoSignup();
  };
  render() {

    let routes = (
      <Switch>
        <Route path="/auth" component={Auth} />
        <Redirect to="/auth" />
      </Switch>
    );

    if(this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route path="/" exact component={Posizioni} />
          <Route path="/percorsi/:id?" component={Storico} />
          <Route path="/garage" exact component={Garage} />
          <Route path="/movimenti" exact component={Movimenti} />
          <Route path="/interventi" exact component={Interventi} />
          <Route path="/refuelings/:device_id?/:data_fine?" component={Refuelings} />
          <Route path="/refuelings_checkbox" component={RefuelingsCheckbox} />
          <Route path="/settings" exact component={Settings} />
          <Route path="/manual" exact component={Manual} />
          <Route path="/logout" exact component={Logout} />
          <Redirect to="/" />
        </Switch>
      );
    }

    return (
        <Layout>
          {routes}
        </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null && !state.auth.isFirstAccess,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
