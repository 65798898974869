import React , { Fragment } from 'react';
import { Map, TileLayer } from 'react-leaflet'
import classes from './Posizioni.module.css'
import 'leaflet/dist/leaflet.css';
import Devices from './Devices'
import Legend from './Legend/Legend'
import { connect } from 'react-redux';

/*
    This version works with React-Leaflet 2.4
*/
class MyMap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstTime: true,
            zoom: 6
        };

        this.mapRef = React.createRef()

    }

    componentDidUpdate(prevProps) {
        if(this.props.selectedDevice !== null)
            if( prevProps.selectedDevice !== this.props.selectedDevice) {
                const { current = {} } = this.mapRef;
                const { leafletElement: map } = current;
                map.flyTo([this.props.selectedDevice.latitude, this.props.selectedDevice.longitude], 13)
            }
    }

    renderMarkers () {
        const map = this.mapRef.current; 
        this.setState( { zoom: map.leafletElement.getZoom()})
    }

    render() {
        if(this.bounds !== null) {

            return (                    
                <div className={classes.map}>
                    <Map
                        style={{ width: '100%', height: '100%', zIndex: 90 }}
                        maxZoom={19}
                        zoom={6}
                        scrollWheelZoom={true}
                        //bounds={this.bounds}
                        center={[42.04742983, 12.36848]}
                        boundsOptions={{padding: [10, 10]}}
                        ref={this.mapRef}
                        onzoomend={() => this.renderMarkers()}
                    >
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />  

                        <Legend />

                        <Devices devices={this.props.devices} selectedDevice={this.props.selectedDevice} zoom={this.state.zoom} />   
                
                    </Map>
                
                </div>
            );
        } else {
            //console.log("No bounds")
            return (
                <Fragment>
                </Fragment>
            );
        }
    }
}

const mapStateToProps =  state => {
    return {
            devices: state.posizioni.devices,
            selectedDevice: state.posizioni.selectedDevice
    }
}

export default connect(mapStateToProps, null)(MyMap);