import React , {useEffect, useState, forwardRef} from 'react'
import { connect } from 'react-redux'
import classes from './InterventiFilters.module.css'
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment'

import * as actions from '../../store/actions/index';

import { InputGroup, Button, Form, Row } from 'react-bootstrap';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isFirstDayOfMonth } from 'date-fns'


export const InterventiFilters = (props) => {

  const [ showOnlyAuthorized, setShowOnlyAuthorized] = useState(true);

    /* */
  useEffect(() => {
    props.onCercaInterventi(
        props.filterTarga, 
        props.filterDateStart,
        props.filterDateEnd,
        props.filterIntervento,
        props.filterSorting,
        props.filterAuthorization,
        props.filterModello,
        props.filterNumPratica,
        props.filterCodeType
    )
  }, [])

  useEffect(() => {
    if(props.modelliOptions.length === 0)
        props.onGetModelli()
  }, [props.modelliOptions])
   

  const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <button className={classes.refuel_date} onClick={onClick} ref={ref}>
      {value}
    </button>
  ));


  const handleChange = (e, source) => {
    //console.log(e, source)
    if(source === 1) { // select targa
        props.onSetFilterTarga(e)
        props.onCercaInterventi(e, props.filterDateStart, props.filterDateEnd, props.filterIntervento, props.filterSorting, props.filterAuthorization, props.filterModello, props.filterNumPratica, props.filterCodeType)
    } 
    else if(source === 2) {// set intervento
        props.onSetFilterIntervento(e)
        props.onCercaInterventi(props.filterTarga, props.filterDateStart, props.filterDateEnd, e, props.filterSorting, props.filterAuthorization, props.filterModello, props.filterNumPratica, props.filterCodeType)
    }
    else if(source === 3) { // Sorting
        props.onSetFilterSorting(e)
        props.onCercaInterventi(props.filterTarga, props.filterDateStart, props.filterDateEnd, props.filterIntervento, e, props.filterAuthorization, props.filterModello, props.filterNumPratica, props.filterCodeType)
    }
    else if(source === 4) { // select mostrare autorizzati
        props.onSetFilterAuthorization(e) // e = {value: 1, label: 'Autorizzati'}
        props.onCercaInterventi(props.filterTarga, props.filterDateStart, props.filterDateEnd, props.filterIntervento, props.filterSorting, e, props.filterModello, props.filterNumPratica, props.filterCodeType)
    }
    else if (source === 5) { //modello
        props.onSetFilterModello(e)
        props.onCercaInterventi(props.filterTarga, props.filterDateStart, props.filterDateEnd, props.filterIntervento, props.filterSorting, props.filterAuthorization, e, props.filterNumPratica, props.filterCodeType)
    }
    else if (source == 6) { // numero di pratica
        props.onSetFilterNumPratica(e.target.value)
        props.onCercaInterventi(props.filterTarga, props.filterDateStart, props.filterDateEnd, props.filterIntervento, props.filterSorting, props.filterAuthorization, props.filterModello, e.target.value, props.filterCodeType)
    }
    else if (source === 7) {
        props.onSetFilterCodeType(e)
        props.onCercaInterventi(props.filterTarga, props.filterDateStart, props.filterDateEnd, props.filterIntervento, props.filterSorting, props.filterAuthorization, props.filterModello, props.filterNumPratica, e)
    }
  }

  const handleChangeDataInizio = (date) => {
    props.onSetFilterDateStart(date)
    props.onCercaInterventi(props.filterTarga, date, props.filterDateEnd, props.filterIntervento, props.filterSorting, props.filterAuthorization, props.filterModello, props.filterNumPratica)
  }
  const handleChangeDataFine = (date) => {
    props.onSetFilterDateEnd(date)
    props.onCercaInterventi(props.filterTarga, props.filterDateStart, date, props.filterIntervento, props.filterSorting, props.filterAuthorization, props.filterModello, props.filterNumPratica)
  }

  const processTargaInput = (e) => {
    const newVal = e.replace(/\s/g, '');
    return newVal
  }

  const sortOptions = [
    {value: 0, label: "Ordina per..."},
    {value: 1, label: "Targa Decrescente"},
    {value: 2, label: "Targa Crescente"},
    {value: 3, label: "Data Decrescente"},
    {value: 4, label: "Data Crescente"},
    {value: 5, label: "Importo Decrescente"},
    {value: 6, label: "Importo Crescente"}
  ]

    const mostraOptions = [
        {value: 0, label: 'Da autorizzare'},
        {value: 1, label: 'Autorizzati'},
        {value: 2, label: 'Tutti'}
    ];

  const handleCheckboxChange = (e) => {
    console.log(e.target.value)
    setShowOnlyAuthorized(e.target.value)
  }

    /*
    var modelliOptions = [{value: '0', label: 'Tutti'}];
    for (var i in res.data.info.marca_modello) {
        var m = res.data.info.marca_modello[i];
        modelliOptions.push({
            value: m.car_id,
            label: m.marca + ' ' + m.modello + ' (' + m.posti + ' posti)'
        });
    }
    */


  return (
    <Row>
    <div style={{marginTop: '10px'}}>
        <div className={classes.movimentiFilters}>

            <div style={{width:"180px"}}>
                <Select
                    placeholder="Qualsiasi Targa"
                    options={props.targheOptions}
                    onChange={(e) => handleChange(e, 1)}
                    onInputChange={(e) => processTargaInput(e)}
                    value={props.filterTarga? props.filterTarga: {label: 'Qualsiasi targa', value: 0}}
                />
            </div>

            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}>
                <div style={{width: '205px'}}>
                    <InputGroup>
                        <InputGroup.Text id="inputGroup-sizing-sm">Data Inizio</InputGroup.Text>
                        <DatePicker 
                            locale="it"
                            selected={props.filterDateStart} 
                            dateFormat='dd/MM/yyyy'
                            style={{textAlign: "center"}}
                            customInput={<CustomDatePickerInput />}
                            onChange={(date) => handleChangeDataInizio(date)}
                            value={props.filterDateStart}
                        />
                    </InputGroup>
                </div>
                <div style={{width: '10px'}}></div>
                <div style={{width: '205px'}}>
                    <InputGroup>
                        <InputGroup.Text id="inputGroup-sizing-sm">Data Fine</InputGroup.Text>
                        <DatePicker 
                            locale="it"
                            selected={props.filterDateEnd} 
                            dateFormat='dd/MM/yyyy'
                            style={{textAlign: "center"}}
                            customInput={<CustomDatePickerInput />}
                            onChange={(date) => handleChangeDataFine(date)}
                            value={props.filterDateEnd}
                        />
                    </InputGroup>
                </div>
            </div>

            <div style={{width:"250px"}}>
                <Select
                    placeholder="Qualsiasi Intervento"
                    options={props.interventiOptions}
                    onChange={(e) => handleChange(e, 2)}
                    value={props.filterIntervento ? props.filterIntervento : {label: 'Qualsiasi Intervento', value: 0}}
                />
            </div>

            <div style={{width:"200px"}}>
                <Select
                    placeholder="Ordina per..."
                    options={sortOptions}
                    onChange={(e) => handleChange(e, 3)}
                    value={props.filterSorting? props.filterSorting: {label: 'Ordina per...', value: 0}}
                />
            </div>

            <div style={{width:"160px"}}>
                <Select
                    placeholder="Da autorizzare"
                    options={mostraOptions}
                    onChange={(e) => handleChange(e, 4)}
                    value={props.filterAuthorization ? props.filterAuthorization : {label: 'Da autorizzare', value: 0}}
                />
            </div>

            <div style={{width:"320px"}}>
                <Select
                    placeholder="Modelli"
                    options={props.modelliOptions}
                    onChange={(e) => handleChange(e, 5)}
                    value={props.filterModello ? props.filterModello : {label: 'Tutti i modelli', value: 0}}
                />
            </div>

            <div style={{width: "240px", paddingTop: '10px', height: '48px'}}>
                    <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">Num. Pratica</span>
                    <input 
                        type="text" 
                        class="form-control" 
                        size={8} 
                        aria-describedby="basic-addon1" 
                        onChange={(e) => handleChange(e, 6)}
                    />
                    </div>
            </div>
            {/*
            <div style={{width:"180px", paddingTop: '10px', height: '48px'}}>
                <Select
                    placeholder="Tipo Intervento"
                    options={[{label: 'Tutti', value: 0}, {label: "Intervento", value: "GCF_MM"}, {label: "Acquisto Gomme", value: "GCF_AG"}]}
                    onChange={(e) => handleChange(e, 7)}
                    value={props.filterCodeType ? props.filterCodeType : {label: 'Tutti Interventi', value: 0}}
                />
            </div>
            */}

            <div style={{width: "160px", paddingTop: '15px', marginLeft: 'auto', order: 2}}>
                Totale Interventi: 
                    <span style={{marginLeft: '7px'}}>
                        {props.interventi.length}
                    </span>
            </div>
        </div>
    </div>
</Row>
   
       
  )
}

const mapStateToProps = (state) => {

    const targheOptions = []
    targheOptions.push({value:0, label: "Qualsiasi Targa"})
    state.posizioni.selectOptions.map((item) => {
        targheOptions.push({
            value: item.value,
            label: item.label
        })
        return false;
    });


    const interventiOptions = []
    if(state.interventi.tipiInterventi.length > 0)
        interventiOptions.push({value: null, label: "Qualsiasi Intervento"})
        state.interventi.tipiInterventi.map((item) => {
            interventiOptions.push({
                value: item['operazione_id'],
                label: item['descrizione_operazione']
            })
            return false;
        });
        
    return {
        targheOptions: targheOptions,
        modelliOptions: state.interventi.modelliOptions,
        interventiOptions: interventiOptions,
        filterDateStart: state.interventi.filterDateStart,
        filterDateEnd: state.interventi.filterDateEnd,
        filterTarga: state.interventi.filterTarga,
        filterIntervento: state.interventi.filterIntervento,
        filterSorting: state.interventi.filterSorting,
        filterAuthorization: state.interventi.filterAuthorization,
        filterModello: state.interventi.filterModello,
        filterNumPratica: state.interventi.filterNumPratica,
        filterCodeType: state.interventi.filterCodeType,
        interventi: state.interventi.interventi,
        purchases: state.interventi.purchases
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetFilterTarga: (targa) => dispatch(actions.setFilterTarga(targa)),
        onSetFilterDateStart: (dateStart) => dispatch(actions.setFilterDateStart(dateStart)),
        onSetFilterDateEnd: (dateEnd) => dispatch(actions.setFilterDateEnd(dateEnd)),
        onSetFilterIntervento: (intervento) => dispatch(actions.setFilterIntervento(intervento)),
        onSetFilterSorting: (sortType) => dispatch(actions.setFilterSorting(sortType)),
        onSetFilterModello: (modelloType) => dispatch(actions.setFilterModello(modelloType)),
        onSetFilterCodeType: (codeType) => dispatch(actions.setFilterCodeType(codeType)),
        onSetFilterNumPratica: (numPratica) => dispatch(actions.setFilterNumPratica(numPratica)),
        onSetFilterAuthorization: (authorizationType) => dispatch(actions.setFilterAuthorization(authorizationType)),
        onCercaInterventi: (targa, dataStart, dataEnd, interventoType, sortingType, authorizationType, modelloType, numPratica, codeType) => dispatch(actions.cercaInterventi(targa, dataStart, dataEnd, interventoType, sortingType, authorizationType, modelloType, numPratica, codeType)),
        onGetModelli: () => dispatch(actions.getModelli())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(InterventiFilters)
