import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    token: null,
    tokenDecoded: null,
    error: null,
    loading: false,
    isFirstAccess: false,
    tempPassword: null, // first password on first login
    message: null
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.AUTH_START:
        case actionTypes.AUTH_FIRST_ACCESS_START:
            return updateObject(state, { 
                error: null,
                loading: true
            });
        case actionTypes.AUTH_SUCCESS:
            return updateObject(state, { 
                token: action.idToken,
                tokenDecoded: action.tokenDecoded,
                error: null,
                loading: false,
                isFirstAccess: action.isFirstAccess,
                tempPassword: action.tempPassword
            });
        case actionTypes.FIRST_ACCESS_SUCCESS:
            return updateObject(state, { 
                token: action.idToken,
                tokenDecoded: action.tokenDecoded,
                error: null,
                loading: false,
                isFirstAccess: action.isFirstAccess
            });
        case actionTypes.CHANGE_PASSWORD_SUCCESS:
            return updateObject(state, { 
                token: action.idToken,
                tokenDecoded: action.tokenDecoded,
                error: null,
                loading: false,
                message: 'Cambio Password effetuato con successo'
            });
        case actionTypes.AUTH_FAIL:
        case actionTypes.FIRST_ACCESS_FAIL:
        case actionTypes.CHANGE_PASSWORD_FAIL:
            return updateObject(state, {
                error: action.error,
                loading: false,
                message: null
            });
        case actionTypes.AUTH_LOGOUT:
            return updateObject(state, {
                token: null,
                username: null
            });
        default:
            return state;
    }
};

export default reducer;