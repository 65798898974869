import React , { Fragment } from 'react';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/it';
import { Modal, Alert, OverlayTrigger, Tooltip, Button} from 'react-bootstrap'

import { connect } from 'react-redux';

import { Link } from 'react-router-dom'

import Select from 'react-select';

import './Garage.scss';
import "bootstrap/dist/css/bootstrap.min.css";

import styled from 'styled-components'

import * as actions from '../../store/actions/index';

import { FaTrashAlt, FaCheckCircle, FaMapMarkerAlt, FaQuestionCircle } from "react-icons/fa";

import FileManager from '../../components/FileManager/FileManager'
import FileUploader from '../../components/FileUploader/FileUploader'
import MechanicLogBook from '../MechanicLogBook/MechanicLogBook'
import MechanicLogBookModal from '../MechanicLogBookModal/MechanicLogBookModal'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import it from "date-fns/locale/it";
registerLocale("it", it);




const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`


const RowState = (props) => {
    const refuel = props.refuel;

    if(refuel.carta_carburante_id !== undefined && refuel.carta_carburante_id !== 0) // Salvato
    {
        /*
        if(refuel.valid) 
            return <FaCheckCircle style={{color: "green", font: "1rem"}} title="Rifornimento Trovato"/>
        if(!refuel.valid)
            return <FaCheckCircle style={{color: "yellow", font: "1rem"}} title="Rifornimento Non Trovato"/>
        */
        if(refuel.refuel_id)
            return <FaCheckCircle style={{color: "green", font: "1rem"}} title="Rifornimento Trovato"/>
        else
            return <FaCheckCircle style={{color: "yellow", font: "1rem"}} title="Rifornimento Non Trovato"/>
    } else { // Non Salvato
        if(refuel.valid)
            return <FaCheckCircle style={{color: "green", font: "1rem"}} title="Rifornimento Trovato"/>
        else {
            if(refuel.eval_check)
                return <FaCheckCircle style={{color: "yellow", font: "1rem"}} title="Rifornimento Non Trovato"/>
            if(!refuel.eval_check)
                return <FaCheckCircle style={{color: "red", font: "1rem"}} title="Dati Non completi o errati"/>
        }
    }

}


class Garage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            token: props.token,
            tokenDecoded: props.tokenDecoded,

            targhe: [],
            devices: [],
            capi_cantiere: [],
            marca_modello: [],
            tipologie: [],
            results: [],
            filters: {
                targa_id: 0,
                capo_cantiere_id: 0,
                car_id: 0,
                order_by: 0
            },
            options: {
                targhe: [],
                modello: [],
                cc: []
            },
            transferVisible: false,
            transferDevice: null,
            transferTarget: null,
            transferOptions: [],
            transferOutputOk: false,
            transferOutputFail: false,

            refundsModal: false,
            refundsDevice: null,
            refundsData: [],
            refundOutputOk: false,
            refundOutputFail: false,
            saveRefundsOutputOk: false,
            saveRefundsOutputFail: false,
            checkMsg: '',
            refundsWarning: false,

            confirmModal: false,

            isModifiedFlag: false,

            responsabileModalVisible: false,
            selectedRow: null,

            filesModalVisible: false,
            fileManagerSelectedDevice: null,
            doc_type: null, // selected doc_type for fileManager popup window

            mechanicLogbookModalButtonPressed: false
            
        };

        this.exportUrl = process.env.REACT_APP_GEB_MIDDLEWARE + '/garage/csv/' + this.props.token;

        this.showScadenze = function(dev_id) {
            window.dispatchEvent(new CustomEvent('showScadenze', {
                detail: {device_id: dev_id, notificationMode: false}
            }));
        };

        this.onUpdateScadenze = function(e) {
            if (e.type === 'updateScadenze') {
                var device_id = e.detail.device_id;

                var newResults = this.state.results;

                for (var i in newResults)
                    if (newResults[i].device_id == device_id) {
                        console.log('Trovato ' + i);
                        newResults[i].revisione = e.detail.revisione;
                        newResults[i].bollo = e.detail.bollo;
                        newResults[i].assicurazione = e.detail.assicurazione;
                    }

                this.setState({results: newResults});

            }
        }.bind(this);

        this.filterChange = function(filter, e) {
            //console.log(filter)
            //console.log(e)
            if (e.target)
                this.state.filters[filter] = e.target.value;
            else
                this.state.filters[filter] = e.value;

            this.setState(this.state);
            this.fetchGarage(false);
        }

        this.redrawChart = function(div, data, first_call) {
            function firstDraw(data, div) {
                var opt = {
                    mode: "vega-lite",
                    renderer: "svg",
                    actions: {
                        export: true,
                        source: false,
                        editor: false
                    }
                };

                window.charts = [];
                window.vegaEmbed("#" + div, data, opt, function (error, result) {
                    window.vegaTooltip.vegaLite(result.view, '');
                }).then(function(p) {
                    window.charts[div] = p;
                }.bind(this));
            };

            function updateChart(data, div) {
                var datasetName = window.charts[div].spec.data.name;
                var newData = data.datasets[data.data.name];
                var changeset = window.vega.changeset().remove(() => true).insert(newData);
                window.charts[div].view.change(datasetName, changeset).run()
            }

            if (first_call)
                firstDraw.call(this, data, div)
            else
                updateChart.call(this, data, div);
        }

        this.fetchGarage = function(first_call) {

            let filters = null

            if(this.props.movimentiClickedTarga && !first_call) {

                const movimentiClickedTarga = props.selectOptions.filter(option => {
                    const myArr = option.label.split(" ");
                    if( myArr[0] === this.props.movimentiClickedTarga ) return true;
                    return false
                    
                })

                filters =  {
                    targa_id: movimentiClickedTarga[0].value,
                    capo_cantiere_id: 0,
                    tipo_veicolo: 0,
                    tipologia: 0,
                    car_id: 0,
                    order_by: 0
                }
            } else {
                filters = this.state.filters
            }

            axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/garage', {params: filters}, {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.data.retCode === "000") {
                    console.log('/garage request ok');
                    if (first_call)
                        this.setState(res.data.info);

                    var targheOptions = [{value: '0', label: 'Tutti'}];
                    for (var i in res.data.info.targhe) {
                        var t = res.data.info.targhe[i];
                        targheOptions.push({
                            value: t.device_id,
                            label: t.show_targa.replaceAll(' ', '') + ' ' + (t.codice_mezzo ? t.codice_mezzo : '')
                        });
                    }

                    var tipiVeicoloOptions = [{value: '0', label: 'Tutti'}];
                    for (var i in res.data.info.tipi_veicoli) {
                        var m = res.data.info.tipi_veicoli[i];
                        tipiVeicoloOptions.push({
                            value: m.veicolo,
                            label: m.veicolo
                        });
                    }

                    var tipologieOptions = [{value: '0', label: 'Tutti'}];
                    for (var i in res.data.info.tipologie) {
                        var m = res.data.info.tipologie[i];
                        tipologieOptions.push({
                            value: m,
                            label: m
                        });
                    }

                    var modelliOptions = [{value: '0', label: 'Tutti'}];
                    for (var i in res.data.info.marca_modello) {
                        var m = res.data.info.marca_modello[i];
                        modelliOptions.push({
                            value: m.car_id,
                            label: m.marca + ' ' + m.modello + ' (' + m.posti + ' posti)'
                        });
                    }

                    var ccOptions = [{value: '0', label: 'Tutti'}];
                    for (var i in res.data.info.capi_cantiere) {
                        var c = res.data.info.capi_cantiere[i];
                        ccOptions.push({
                            value: c.capo_cantiere_id,
                            label: c.cognome + ' ' + c.nome
                        });
                    }

                    var transferOptions = [];
                    for (var i in res.data.info.capi_cantiere) {
                        var c = res.data.info.capi_cantiere[i];
                        if (c.email !== this.state.tokenDecoded.username)
                            transferOptions.push({
                                value: c.email,
                                label: c.cognome + ' ' + c.nome
                            });
                    }

                    this.setState({
                        results: res.data.info.devices,
                        options: {
                            targhe: targheOptions,
                            modelli: modelliOptions,
                            cc: ccOptions,
                            tipiVeicolo: tipiVeicoloOptions,
                            tipologie: tipologieOptions // filtro richiesto da Tommaso
                        },
                        transferOptions: transferOptions
                    });

                    if (res.data.info.devices.length > 1) {
                        //if(this.props.movimentiClickedTarga) 
                            //this.redrawChart('histo_imm', res.data.info.histo_imm, true);

                        this.redrawChart('histo_imm', res.data.info.histo_imm, first_call);
                        this.redrawChart('histo_km', res.data.info.histo_km, first_call);
                        this.redrawChart('histo_km_annui', res.data.info.histo_km_annui, first_call);
                    }


                    if(this.props.movimentiClickedTarga && first_call) {
                        this.fetchGarage()
                        this.props.onSetClickedTarga(null)
                    }
                } else
                    console.warn('/garage request error: ' + res.data.info.error);
            }).catch(err => {
                console.error('/garage request fail: ' + err);
            });
        }


        this.showTransferModal = function(d) {
            this.setState({
                transferVisible: true,
                transferDevice: d
            });
        }

        this.showResponsabileModal = function(d) {
            this.setState({
                responsabileModalVisible: true,
                selectedRow: d
            })
        }

        // Doc Type ID:
        // 1. Libretto
        // 2. Assicurazione
        this.showFilesModal = function(d, docTypeId) {
            let tempDocType;
            if( docTypeId == 1 ) tempDocType = 'libretto';
            else if ( docTypeId == 2) tempDocType = 'assicurazione';

            if ( ['am', 'sv'].indexOf(this.state.tokenDecoded.role) > -1 )
                this.setState({
                    filesModalVisible: true,
                    fileManagerSelectedDevice: d.device_id,
                    doc_type: tempDocType
                });
            else { // cc
                this.checkFileExists(d.device_id, tempDocType)
            }
        }

        this.showMechanicLogBookModal = function(d) {
            this.props.onSetMechLogBookModalVisibility(true, d)
        }

        // Used only when user is CC
        // It checks if file is present in fat. If it i present it downloads it. Else it gives an alert message
        this.checkFileExists = async (device_id, doc_type) => {
            await axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/files_request/' + device_id + '/' + doc_type, {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.data.retCode === "000") {
                    if(res.data.info.files && res.data.info.files.length > 0) {
                        this.downloadFile(device_id, doc_type, res.data.info.files[0])
                    }
                    else {
                        alert(doc_type + " " +" non esistente")
                    }
                }
            }).catch(err => {
                console.error('/files request fail: ' + err);
            });
        }

        this.downloadFile = async (device_id, doc_type, file) => {

            axios({
                url: process.env.REACT_APP_GEB_MIDDLEWARE + '/fat_download/' + device_id + '/' + doc_type,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', doc_type + '_' + file.filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(err => {
                console.error('/files request fail: ' + err);
            });

        }


        this.showRefundsModal = function(d) {
            
            this.setState( {refundsWarning: false, checkMsg: ''} );

            axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/refunds?device_id=' + d.device_id, {
            //axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/refunds?', {
            //axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/refunds?device_id=51', {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.data.retCode === "000") {
                    console.log('/refunds request ok');
                    let tempRows = []
                    res.data.info.map((row) => {
                        //console.log(row)
                        tempRows.push({
                            carta_carburante_id: row.carta_carburante_id,
                            cc_km: row.cc_km,
                            datetime: row.datetime,
                            qta: row.qta.toFixed(2) ,
                            prezzo_totale: row.prezzo_totale.toFixed(2) ,
                            prezzo_litro: ( row.prezzo_totale / row.qta ).toFixed(3) ,
                            refuel_id: row.refuel_id,
                            num: row.num,
                            device_id: row.device_id,
                            valid: row.refuel_id ? true : false,
                            eval_check: row.refuel_id ? true : false,
                            //stato: ''
                        });
                        return false;
                    });

                    //console.log(tempRows)

                    //console.log(typeof(rows[0].datetime))
                    tempRows.push({
                        //carta_carburante_id: "",
                        cc_km: "",
                        //datetime: "",
                        qta: "",
                        prezzo_totale: "",
                        prezzo_litro: "",
                        refuel_id: 0,
                        num: "",
                        valid: false,
                        eval_check: false,
                        device_id: d.device_id,
                        //stato: ''
                    });
                    this.setState({refundsData: tempRows})

                } else {
                    console.warn('/refunds request error: ' + res.data.info.error);
                    this.setState({
                        refundsModal: false,
                        refundsOutputFail: true
                    });
                }
            }).catch(err => {
                console.error('/refunds request fail: ' + err);
                this.setState({
                    refundsModal: false,
                    refundsOutputFail: true
                });
            });
            this.setState({
                refundsModal: true,
                refundsDevice: d
            });
        }


        this.closeRefundsModal = function() {

            //let rowsToSend = this.state.refundsData.filter((row) => this.filterRefundsData(row))
            let notSavedRows = this.state.refundsData.filter((row) => {
                console.log(typeof(row.carta_carburante_id))
                if((row.carta_carburante_id === undefined || row.carta_carburante_id === 0) && row.isModifiedFlag) {
                return true
                }else return false
            })

            if (notSavedRows.length !== 0) 
                this.setState({ 
                    isModifiedFlag: true,
                    confirmModal: true 
                })
            else {
                this.setState({ 
                    refundsModal: false,
                    isModifiedFlag: false,
                    confirmModal: false
                })
            }
        }

        /*Second modal button actions: Confirmation */
        this.closeNoSaveModals = function() {
            this.setState({
                confirmModal: false,
                refundsModal: false,
                isModifiedFlag: false
            })
        }

        // Torna indietro
        this.closeConfirmModal = function() {
            this.setState( { confirmModal: false })
        }
        // End Second Modal Button actions

        this.closeTransferModal = function() {
            this.setState({transferVisible: false});
        }

        this.transferTargetChange = function(e) {
            this.setState({transferTarget: e.value});
        }

        this.closeFilesModal = function(e) {
            this.setState({filesModalVisible: false});
        }

        this.closeResponsabileModal = function(e) {
            this.setState({
                selectedRow: null,
                responsabileModalVisible: false
            })
        }


        this.confirmResponsabileModal = function(e) {

            axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/responsabile',
                {
                    device_id: this.state.selectedRow.device_id,
                    responsabile: this.state.selectedRow.responsabile
                }
            , {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.data.retCode === "000") {
                    console.log('/update responsabile request ok');

                    let tempArray = this.state.results;

                    tempArray.map((row) => {
                        if(row.device_id === this.state.selectedRow.device_id) {
                            row['responsabile'] = this.state.selectedRow.responsabile
                        }
                        return false;
                    });
                    
                    this.setState({ 
                        results: tempArray, 
                        responsabileModalVisible: false
                    });

                } else {
                    console.error('/update responsabile request error: ' + res.data.errorMessage);
                }
            }).catch(err => {
                console.error('/update responsabile request fail: ' + err);

            });
        }

        this.resetResponsabileModal = function(e) {
            axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/responsabile',
                {
                    device_id: this.state.selectedRow.device_id,
                    responsabile: ''
                }
            , {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.data.retCode === "000") {
                    console.log('/update responsabile request ok');

                    let tempArray = this.state.results;

                    tempArray.map((row) => {
                        if(row.device_id === this.state.selectedRow.device_id) {
                            row['responsabile'] = ''
                        }
                        return false;
                    });
                    
                    this.setState({ 
                        results: tempArray, 
                        responsabileModalVisible: false
                    });

                } else {
                    console.error('/update responsabile request error: ' + res.data.errorMessage);
                }
            }).catch(err => {
                console.error('/update responsabile request fail: ' + err);

            });           
        }

        this.filterRefundsData = function(row) {

            let isValid = true
            if(!row['num']
                && !row['datetime']
                && !row['cc_km']
                && !row['qta']
                && !row['prezzo_litro']
                && !row['prezzo_totale']
            ) {
                isValid = false;
            }

            // Check if row contains basic errors, and needs to be fixed before saving it, dont send it.
            // Send only yellow and green rows
            //if(row['valid'] && row['valid'] != false)
              //  isValid = false;

            if(!row['eval_check'] && !row['valid']){
                isValid = false
            }

            if(row['carta_carburante_id'] !== undefined && row['carta_carburante_id'] !== 0 ) {
                isValid = false;
            }

            if(row['isChecked'] && row['datetime'] === undefined) {
                isValid = false;
                this.setState( { checkMsg: "Inserisci data!", refundsWarning: true } )
            }

            
            return isValid;
        }

        this.submitRefundsModal = function() {

            let isValid = true
            this.state.refundsData.map((row) => {
                if(row['carta_carburante_id'] === 0 || row['carta_carburante_id'] === undefined) {
                    if(!row['num']
                        || !row['cc_km']
                        || !row['qta']
                        || !row['prezzo_litro']
                        || !row['prezzo_totale']
                    ) {
                        isValid = false;
                        return false;
                    }

                }
                return false;
            });
            if(!isValid) {
                this.setState( { checkMsg: "Dati incompleti!", refundsWarning: true } )
            }


            let rowsToSend = this.state.refundsData.filter((row) => this.filterRefundsData(row))
            
            if(rowsToSend.length === 0) return false;

            axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/save_refunds?device_id='+this.state.refundsDevice.device_id,
                rowsToSend
            , {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.data.retCode === "000") {
                    console.log('/save refunds request ok');

                        let tempArray = this.state.refundsData;

                        tempArray.map((sentRow) => {

                            res.data.info.map((receivedRow) => {
                                if( parseInt(sentRow.cc_km) === receivedRow.cc_km && parseInt(sentRow.num) === receivedRow.num)
                                {
                                    sentRow['carta_carburante_id'] = receivedRow.carta_carburante_id;
                                }
                                return false;
                            });
                            return false;
                        });
                    
                    this.setState({ 
                        refundsData: tempArray, 
                        refundsWarning: false,
                        //isModifiedFlag: false,
                        checkMsg: ''
                    });

                } else {
                    console.error('/save refunds request error: ' + res.data.errorMessage);

                    this.setState({
                        checkMsg: res.data.errorMessage,
                        confirmModal: false,
                        refundsWarning: true
                    })
                    /*
                    this.setState({
                        refundsModal: false,
                        refundsOutputFail: true
                    });
                    */
                }
            }).catch(err => {
                console.error('/save refunds request fail: ' + err);
                this.setState({
                    checkMsg: "Errore di salvatagio",
                    confirmModal: false,
                    refundsWarning: true
                })
            });
        }
        
/*
        function checkValidityBeforeCheckSubmit(tempRow) {
            let isValid = true
            console.log(tempRow)
            isValid = (tempRow['num'] != "") && isValid
            isValid = (tempRow['qta'] != "") && isValid
            isValid = (tempRow['cc_km'] != "") && isValid
            //if(!isValid) this.setState({refundOutputFail: true})
            return isValid;
        }
*/
        //this.parseDate = function(dateString, indexRow) {
        this.parseDate = function(dateString) {
            

            //console.log("-----------------")
            //console.log(dateString, indexRow) 
            
            if (dateString === undefined) {
                return '';
            } else {
                    //console.log(Moment(dateString).format('DD/MM/yyyy'))
                    
                    //const tempArrayDate =  Moment(dateString).format('DD/M/yyyy').split("/")
                    
                    //const day = tempArrayDate[0];
                    //const month = tempArrayDate[1];
                    //const year = tempArrayDate[2];
                    //console.log(year, month, day)

                    //let tempRefunds = this.state.refundsData;
                   // tempRefunds[indexRow]['datetime'] = Moment(dateString).format('yyyy-MM-DD') + ' 00:00:00+02'

                    return new Date(Moment(dateString).format('yyyy-MM-DD'))
                }
           

        }

        this.setDisabledDate = function(refuel) {
            //console.log(refuel)
//            refuel.eval_check  && refuel.valid ? true : false}
            if(refuel.carta_carburante_id !== undefined)
            {
                //if(refuel.valid) return true
                //if(!refuel.valid) return false
                return true
            } else {
                if(refuel.valid) return true
                else {
                    if(refuel.eval_check)
                        return false
                    if(!refuel.eval_check)
                        return true
                }
            }

        }

        this.checkRefund = function(rowIndex) {
            /*
            if (!checkValidityBeforeCheckSubmit(this.state.refundsData[rowIndex])) {
                this.setState({refundsWarning: true, checkMsg: 'Campi mancanti'})
                return false
            }
            */
            /*
            let isValid = true
            isValid = (this.state.refundsData[rowIndex]['num'] != "") && isValid
            if(!isValid) {
                this.setState({refundsWarning: true, checkMsg: 'Campo "Numero" non valido'})
                return false
            }
            */
            /*
            isValid = true
            isValid = (this.state.refundsData[rowIndex]['qta'] != "") && isValid
            if(!isValid) {
                this.setState({refundsWarning: true, checkMsg: 'Campo "Quantità" non valido'})
                return false
            }
            */
            /*
            isValid = true
            isValid = (this.state.refundsData[rowIndex]['cc_km'] != "") && isValid
            if(!isValid) {
                this.setState({refundsWarning: true, checkMsg: 'Campo "Km" non valido'})
                return false
            }*/


            const params = this.state.refundsData[rowIndex]

            if(params.num === "") params.num = 0
            if(params.cc_km === "") params.cc_km = 0
            if(params.qta === "") params.qta = 0

            if(params.prezzo_litro === undefined) params.prezzo_litro = ''

            params['device_id'] = this.state.refundsDevice.device_id

            axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/check_refund',
                params
            , {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.data.retCode === "000") {
                    console.log('/refund check request ok');

                    let tempArray = this.state.refundsData
                    //console.log(res.data.info.eval_check)

                    tempArray[rowIndex]['isChecked'] = true;

                    if(!res.data.info.eval_check) { // Se dati non sono completi
                        
                        tempArray[rowIndex]['eval_check'] = false
                        tempArray[rowIndex]['valid'] = false
                        this.setState({
                            refundsData: tempArray,
                            refundsWarning: true,
                            checkMsg: "Dati incompleti o non corretti"
                        })
                    } else { // se dati sono completi

                        tempArray[rowIndex]['eval_check'] = true

                        if(res.data.info.valid) { // se trovato rifornimento
                            //let tempArray = this.state.refundsData
                            tempArray[rowIndex]['valid'] = true
                            tempArray[rowIndex]['refuel_id'] = res.data.info.refuel_id

                            // new Date() -> Sat Jun 26 2021 10:52:41 GMT+0200 (Central European Summer Time)
                            // res.data.info -> 2021-04-11T21:12:23+02:00

                            tempArray[rowIndex]['datetime'] = Moment(res.data.info.fixtime_start ).toDate()
                            tempArray[rowIndex]['km_start'] = res.data.info.km_start
                            tempArray[rowIndex]['qta'] = res.data.info.qta.toFixed(2) 
                            tempArray[rowIndex]['prezzo_litro'] = res.data.info.prezzo_litro.toFixed(3) 
                            tempArray[rowIndex]['prezzo_totale'] = res.data.info.prezzo_totale.toFixed(2) 
                            //tempArray[rowIndex]['prezzo'] = res.data.info.prezzo_totale.toFixed(2) 
                            tempArray[rowIndex]['carta_carburante_id'] = 0
                            //tempArray[rowIndex]['stato'] = 'V'

                            this.setState({
                                refundsData: tempArray
                            })
                        } else { // se NON trovato nessun rifornimento
                            tempArray[rowIndex]['prezzo_litro'] = res.data.info.prezzo_litro.toFixed(3) 
                            tempArray[rowIndex]['prezzo_totale'] = res.data.info.prezzo_totale.toFixed(2) 
                            //tempArray[rowIndex]['prezzo'] = res.data.info.prezzo_totale.toFixed(2) 
                            tempArray[rowIndex]['qta'] = res.data.info.qta.toFixed(2) 

                            tempArray[rowIndex]['valid'] = false
                            //tempArray[rowIndex]['stato'] = 'N'
                            this.setState({
                                refundsData: tempArray,
                                refundsWarning: true,
                                checkMsg: "Nessun rifornimento trovato"
                            })
                        }
                    }


                } else {
                    console.warn('/refund check request error: ' + res.data.info.error);
                    /*
                    this.setState({
                        refundsModal: false,
                        refundsOutputFail: true
                    });
                    */
                }
            }).catch(err => {
                console.error('/refund check request fail: ' + err);
                /*
                this.setState({
                    refundsModal: false,
                    refundsOutputFail: true
                });
                */
            });
        }

        this.deleteRefund = function(rowIndex) {
            //this.state.refundsData[rowIndex]
            let rows = this.state.refundsData;
            rows.splice(rowIndex, 1)
            this.setState({refundsData: rows})
        }

        this.submitTransferModal = function() {
            axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/request_transfer', {
                device_id: this.state.transferDevice.device_id,
                target_user: this.state.transferTarget
            }, {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.data.retCode === "000") {
                    console.log('/transfer request ok');

                    this.setState({
                        transferVisible: false,
                        transferOutputOk: true
                    });
                } else {
                    console.warn('/transfer request error: ' + res.data.info.error);
                    this.setState({
                        transferVisible: false,
                        transferOutputFail: true
                    });
                }
            }).catch(err => {
                console.error('/transfer request fail: ' + err);
                this.setState({
                    transferVisible: false,
                    transferOutputFail: true
                });
            });
        }

        Moment.locale('it');

        this.addRow = function() {
            var rows = this.state.refundsData
            rows.push({
                //carta_carburante_id: "",
                cc_km: "",
                qta: "",
                prezzo_litro: "",
                prezzo_totale: "",
                refuel_id: 0,
                num: "",
                valid: false,
                eval_check: false
            });
            this.setState({refundsData: rows})
        }

        this.handleItemChanged = function (i, event) {

            const formattedValue = event.target.value.replace(/\,/g, '.');
            if (!checkValidity(event.target.name, formattedValue)) return false //TODO: If it is false, signal the error somehow (i.e: change css of the input, or alert)

            let temp = this.state.refundsData
            temp[i][event.target.name] = formattedValue;
            temp[i]['isModifiedFlag'] = true

            this.setState({ 
                refundsData: temp, 
            })
        }

        this.handleResponsabileChanged = function (event) {
            let temp = this.state.selectedRow;
            temp['responsabile'] = event.target.value
            this.setState({selectedRow: temp })
        }

        this.handeDateChange = function (i, dateString) {

            let temp = this.state.refundsData
            temp[i]['datetime'] = dateString;
            this.setState({ refundsData: temp})
            
        }


        function checkValidity(name, value) {
            let isValid = true;
            let pattern = /^$/;
            switch(name) {
                case 'num':
                    pattern = /^\d*$/;
                    isValid = pattern.test(value) && isValid
                    break;
                case 'qta':
                    pattern = /^([0-9]*\.?[0-9]{0,2}|\.[0-9]+)$/;
                    isValid = pattern.test(value) && isValid
                    break;
                case 'cc_km':
                    pattern = /^\d*$/;
                    isValid = pattern.test(value) && isValid
                    break;
                case 'prezzo_totale':
                    pattern = /^([0-9]*\.?[0-9]{0,2}|\.[0-9]+)$/;
                    isValid = pattern.test(value) && isValid
                    break;
                case 'prezzo_litro':
                    pattern = /^([0-9]*\.?[0-9]{0,3}|\.[0-9]+)$/;
                    isValid = pattern.test(value) && isValid
                    break;
                default:
                    break;
            }
            return isValid
        }
    }




    componentDidMount(pros) {
        /*
        if(this.props.movimentiClickedTarga) {
            
            this.fetchGarage(true);
            
        } else {
            this.fetchGarage(true);
        }
        */
        this.fetchGarage(true)
        window.addEventListener('updateScadenze', this.onUpdateScadenze);
    }

    componentDidUpdate(prevProps) {

    }




    render() {

        var d = new Date();
        d.setMonth(d.getMonth() - 1);

        return (
            <Fragment>
                <div class="container" className="garage">
                    <div class="alerts">
                        <Alert key='transferOutputOk' show={this.state.transferOutputOk} variant='success' onClose={() => this.setState({transferOutputOk: false})} dismissible>
                            Richiesta di trasferimento inviata correttamente!
                        </Alert>
                        <Alert key='transferOutputFail' show={this.state.transferOutputFail} variant='danger' onClose={() => this.setState({transferOutputFail: false})} dismissible>
                            C'è stato un errore nell'invio della richiesta!
                        </Alert>
                    </div>

                    <p class="lead sect-lead">
                        <a class="float-right" target="blank" title="Esporta garage" href={this.exportUrl}>
                            Esporta garage
                        </a>
                        Filtro
                    </p>
                    <div class="container-fluid filters">
                        <div class="row">
                            <div class="col">
                                <label>
                                    Targa:
                                </label>
                                <Select
                                    placeholder={<div className="select_placeholder">Seleziona veicolo</div>}
                                    options={this.state.options.targhe}
                                    onInputChange={(e) => {
                                        return e.replace(/\s/g, '');
                                    }}
                                    onChange={this.filterChange.bind(this, 'targa_id')}
                                    value= {
                                        this.props.movimentiClickedTarga ? this.props.selectOptions.filter(option => {
                                        //console.log(option)
                                        //console.log(this.props.movimentiClickedTarga)
                                        const myArr = option.label.split(" ");
                                        if( myArr[0] === this.props.movimentiClickedTarga ) {console.log("FOUND"); return true;} 
                                        return false
                                        }): 
                                        this.props.selectOptions.filter( option => {
                                         
                                            if(option.value === this.state.filters['targa_id']) {console.log("..."); return true;} 
                                            return false;
                                        })
                                        /*props.selectOptions.filter(option => {
                                        const myArr = option.label.split(" ");
                                        if( myArr[0] === this.state.filters[] ) return true;
                                        return false
                                        })*/
                                    }
                                 />
                            </div>

                            { ['am', 'sv'].indexOf(this.state.tokenDecoded.role) > -1 ?
                                <div class="col offset-1">
                                    <label>
                                        Capo cantiere:
                                    </label>
                                    <Select
                                        placeholder={<div className="select_placeholder">Seleziona Capo cantiere</div>}
                                        options={this.state.options.cc}
                                        onChange={this.filterChange.bind(this, 'capo_cantiere_id')}
                                     />
                                </div>
                            : '' }
                            {/*
                            <div class="col offset-1">
                                <label>
                                    Tipologia:
                                </label>
                                <Select
                                    placeholder={<div className="select_placeholder">Seleziona Tipologia</div>}
                                    options={this.state.options.tipologie}
                                    onChange={this.filterChange.bind(this, 'tipologia')}
                                 />
                            </div>
                            */}
                            <div class="col-2 offset-1">
                                <label>
                                    Marca/modello:
                                </label>
                                <Select
                                    placeholder={<div className="select_placeholder">Seleziona marca/modello</div>}
                                    options={this.state.options.modelli}
                                    onChange={this.filterChange.bind(this, 'car_id')}
                                 />
                            </div>
                            <div class="col offset-1">
                                <label>
                                    Tipo:
                                </label>
                                <Select
                                    placeholder={<div className="select_placeholder">Seleziona tipo veicolo</div>}
                                    options={this.state.options.tipiVeicolo}
                                    onChange={this.filterChange.bind(this, 'tipo_veicolo')}
                                 />
                            </div>

                            <div class="col offset-1">
                                <label>
                                    Ordina per :
                                </label>
                                <select value={this.state.filters.order_by} name="order_by" class="form-select" aria-label="Default select example" onChange={this.filterChange.bind(this, 'order_by')}>
                                    <option value="0" selected>Targa</option>
                                    <option value="1">prima i più nuovi</option>
                                    <option value="2">prima i più vecchi</option>
                                    <option value="5">prima i più utilizzati</option>
                                    <option value="6">prima i meno utilizzati</option>
                                    <option value="7">prossime scadenze</option>
                                </select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col align-self-end text-end">
                                <br/>
                                <span class="total">Totale automezzi: {this.state.results.length}</span>
                            </div>
                        </div>
                    </div>

                    <p class="lead sect-lead">Sintesi</p>
                    <div class="container-fluid histograms">
                        <div class="row">
                            <div class="col-4">
                                <p>Istogramma per anno di immatricolazione</p>
                                <div id="histo_imm"></div>
                            </div>
                            <div class="col-4">
                                <p>Istogramma per km</p>
                                <div id="histo_km"></div>
                            </div>
                            <div class="col-4">
                                <p>Istogramma per km/anno</p>
                                <div id="histo_km_annui"></div>
                            </div>
                        </div>
                    </div>

                    <p class="lead sect-lead">Dettaglio</p>
                    <div class="container-fluid details">
                        {this.state.results.map((d)=>(
                            <div key={d.device_id} class="row entry">
                                <div class="col">
                                    <div class="id-mezzo">
                                        <div style={{display: 'flex'}}>
                                            <div class="targa">{d.show_targa}</div>

                                            <div style={{marginLeft: '5px', marginTop: '0px'}} 
                                                title="Clicka sull'icona per visualizzare il mezzo sulla mappa"
                                            >
                                                <Button 
                                                    variant="outline-primary" 
                                                    style={{fontSize: "21px"}}
                                                    size="sm"
                                                    onClick={(e) => {
                                                        const {history} = this.props;
                                                        if (history) {
                                                            const selectedDevice = this.props.devices.filter((device) => device.deviceId === d.device_id)[0];
                                                            this.props.onSetSelectedDevice(selectedDevice)
                                                            history.push('/');
                                                        }
                                                    }}
                                                >
                                                    <FaMapMarkerAlt />
                                                </Button>
                                            </div>
                                        </div>

                                        <p>Codice mezzo: <span class="val">{d.codice_mezzo}</span></p>
                                        <p>Capo cantiere:  <span class="val">{d.nome} {d.cognome}</span></p>
                                        <p>
                                            Responsabile:&nbsp;
                                            <span class="val">
                                                {d.responsabile !== "" ?
                                                    <span class="button" onClick={this.showResponsabileModal.bind(this, d)}>{d.responsabile}</span>
                                                    :
                                                    <span class="button nd" onClick={this.showResponsabileModal.bind(this, d)}>n/d</span>
                                                }
                                            </span>
                                        </p>
                                        <p title={'Ultimo aggiornamento: ' + Moment(d.fixtime).format('D MMM yyyy')}>
                                            Stato:&nbsp;
                                            <span class="val">
                                                { d.error_code !== ''
                                                    ? <span class="scaduta">errore</span>
                                                    : Moment(d.fixtime) > Moment().subtract('days', 7) ? <span class="regolare">regolare</span> : <span class="nd">obsoleto</span>
                                                }
                                            </span>
                                        </p>                                        
                                    </div>
                                </div>
                                <div class="col">
                                    <p class="lead">Dettagli</p>
                                    <p>Modello: {d.marca} {d.modello}</p>
                                    <p>Posti: {d.posti ? d.posti : '?'}</p>
                                    <p>Tipo: {d.veicolo}</p>
                                    <p>
                                        Immatricolazione: &nbsp;
                                        {
                                            d.immatricolazione && d.immatricolazione !== "" ?
                                                Moment(d.immatricolazione).format('D MMM yyyy')
                                            :
                                                <span class="nd">n/d</span>
                                        }
                                    </p>
                                    <p>
                                        {
                                            d.particolarita && d.particolarita !== "" ?
                                                <>
                                                    <span style={{color: '#915400'}}>Segni particolari:</span> &nbsp;
                                                    {(d.particolarita.length > 25) ? d.particolarita.substr(0, 25-1) : d.particolarita}
                                                </>
                                            :
                                                <></>
                                        }
                                    </p>
                                </div>
                                <div class="col">
                                    { d.error_code !== ''
                                        ? ''
                                        : <Fragment>
                                            <p class="lead">Consumo</p>
                                            <p>Stato:&nbsp;
                                                <span class="val">
                                                    <span class="regolare">regolare</span>
                                                </span>
                                            </p>

                                            <p>
                                                <Link
                                                    title="Elenco rifornimenti"
                                                    to={"/refuelings/"+ d.device_id}
                                                >
                                                    Elenco rifornimenti
                                                </Link>
                                            </p>
                                            <p>
                                                <p class="button" onClick={this.showRefundsModal.bind(this, d)}>Richieste Rimborsi</p>
                                                
                                            </p>
                                        </Fragment>
                                    }
                                </div>
                                <div class="col">
                                { d.error_code !== ''
                                    ? ''
                                    : <Fragment>
                                        <p class="lead">Utilizzo</p>
                                        <p>
                                            Totale:&nbsp;
                                            <span class="val">
                                                { d.can_errors
                                                    ? <span class="nd" title="Dato non disponibile">n/d</span>
                                                    : <Fragment><NumberFormat value={Math.round(d.km)} displayType={'text'} thousandSeparator={true} /> Km</Fragment>
                                                }
                                            </span>
                                        </p>
                                        <p>
                                            Media anno:&nbsp;
                                            <span class="val">
                                                { d.can_errors
                                                    ? <span class="nd" title="Dato non disponibile">n/d</span>
                                                    : <Fragment><NumberFormat value={Math.round(d.km_annui)} displayType={'text'} thousandSeparator={true} /> Km</Fragment>
                                                }
                                            </span>
                                        </p>
                                        <p>
                                            Ultimo mese:&nbsp;
                                            <span class="val">
                                                { d.km_errors || d.can_errors
                                                    ? <span class="nd" title="Dato non disponibile">n/d</span>
                                                    : <Fragment><NumberFormat value={Math.round(d.km_month)} displayType={'text'} thousandSeparator={true} /> Km</Fragment>
                                                }
                                            </span>
                                        </p>
                                            
                                    </Fragment>
                                }
                                </div>
                                <div class="col">
                                    <p class="lead">Scadenze</p>
                                    <p>
                                        Revisione:&nbsp;
                                        <span class="val">
                                            <span className={[Moment(d.revisione) < Moment() ? 'scaduta' : 'regolare', Moment(d.revisione) < Moment().add('months', 2) ? 'in_scadenza' : ''].join(' ')}>
                                                { d.revisione !== ''
                                                    ? Moment(d.revisione).format('D MMM yyyy')
                                                    : <span class="nd" title="Dato non disponibile">n/d</span>
                                                }
                                            </span>
                                        </span>
                                    </p>
                                    <p>
                                        Bollo:&nbsp;
                                        <span class="val">
                                            <span className={[Moment(d.bollo) < Moment() ? 'scaduta' : 'regolare', Moment(d.bollo) < Moment().add('months', 2) ? 'in_scadenza' : ''].join(' ')}>
                                                { d.bollo !== ''
                                                    ? Moment(d.bollo).format('D MMM yyyy')
                                                    : <span class="nd" title="Dato non disponibile">n/d</span>
                                                }
                                            </span>
                                        </span>
                                    </p>
                                    <p>
                                        Assicurazione:&nbsp;
                                        <span class="val">
                                            <span className={[Moment(d.assicurazione) < Moment() ? 'scaduta' : 'regolare', Moment(d.assicurazione) < Moment().add('months', 2) ? 'in_scadenza' : ''].join(' ')}>
                                                { d.assicurazione !== '-'
                                                    ? Moment(d.assicurazione).format('D MMM yyyy')
                                                    : <span class="nd" title="Dato non disponibile">n/d</span>
                                                }
                                            </span>
                                        </span>
                                    </p>
                                    <p class="button" onClick={this.showFilesModal.bind(this, d, 2)}>Stampa Assicurazione</p>
                                    <p class="button" onClick={this.showScadenze.bind(this, d.device_id)}>Aggiorna scadenza</p>
                                </div>
                                <div class="col">
                                    <p class="lead">Funzioni</p>
                                    <p><a disabled href="#">Segnala un guasto</a></p>
                                    <p>
                                        <a disabled href="#">
                                            Rottama/Vendi
                                        </a>
                                    </p>
                                    <a class="button" onClick={this.showMechanicLogBookModal.bind(this, d)} disabled={['am', 'sv'].indexOf(this.state.tokenDecoded.role) > -1 ? false: 'disabled'}>Intervento meccanico</a>
                                    <p><a class="button" onClick={this.showFilesModal.bind(this, d, 1)}>Stampa Libretto</a></p>
                                    <p><a class="button" onClick={this.showTransferModal.bind(this, d)}>Trasferisci ad altro canitere</a></p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>


                <Modal show={this.state.confirmModal} onHide={this.closeConfirmModal.bind(this)} backdrop="static" keyboard={false}>
                    <Modal.Header>
                    </Modal.Header>
                    <Modal.Body>ATTENZIONE, se esci perderai le righe non "salvate".</Modal.Body>
                    <Modal.Footer>
                        <button class="btn btn-secondary" onClick={this.closeNoSaveModals.bind(this)}>
                            Non Salvare
                        </button>
                        <button class="btn btn-primary" onClick={this.closeConfirmModal.bind(this)}>
                            Tornare indietro
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.responsabileModalVisible} onHide={this.closeResponsabileModal.bind(this)} backdrop="static" keyboard={false}>
                    <Modal.Header>Modifica Responsabile</Modal.Header>
                    <Modal.Body>
                        <input 
                            name="responsabile" value={this.state.selectedRow ? this.state.selectedRow.responsabile : ''}
                            size="30"
                            maxlength="30"
                            onChange={this.handleResponsabileChanged.bind(this)}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button class="btn btn-secondary" onClick={this.closeResponsabileModal.bind(this)}>
                            Chiudi
                        </button>
                        <button class="btn btn-primary" onClick={this.confirmResponsabileModal.bind(this)}>
                            Salva
                        </button>
                        <button class="btn btn-primary" onClick={this.resetResponsabileModal.bind(this)}>
                            Reset
                        </button>
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.transferVisible} onHide={this.closeTransferModal.bind(this)} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>
                            Trasferisci mezzo
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Mezzo da trasferire: { this.state.transferDevice ? this.state.transferDevice.codice_mezzo : '-' }
                        <br />
                        <span class="targa">
                        { this.state.transferDevice ? this.state.transferDevice.show_targa : '' }
                        </span>
                        <br />
                        <br />
                        Capo cantiere destinatario:&nbsp;
                         <Select
                            placeholder={<div className="select_placeholder">Seleziona capocantiere</div>}
                            options={this.state.transferOptions}
                            onChange={this.transferTargetChange.bind(this)}
                         />
                    </Modal.Body>
                    <Modal.Footer>
                        <button class="btn btn-secondary" onClick={this.closeTransferModal.bind(this)}>
                            Annulla
                        </button>
                        <button class="btn btn-primary" onClick={this.submitTransferModal.bind(this)}>
                            Invia richiesta
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.filesModalVisible}
                    onHide={this.closeFilesModal.bind(this)}
                    backdrop="static" 
                    keyboard={false}
                    size="lg"
                    >
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.doc_type === 'libretto' ? 'Libretto di Circolazione': ''}
                            {this.state.doc_type === 'assicurazione' ? 'Assicurazione': ''}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FileManager 
                            selectedDevice={this.state.fileManagerSelectedDevice} 
                            doc_type={this.state.doc_type}
                            tokenDecoded={this.props.tokenDecoded}
                        />
                        <br/>
                        <FileUploader 
                            selectedDevice={this.state.fileManagerSelectedDevice} 
                            doc_type={this.state.doc_type}
                            tokenDecoded={this.props.tokenDecoded}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button class="btn btn-secondary" onClick={this.closeFilesModal.bind(this)}>
                            Chiudi
                        </button>
                    </Modal.Footer>
                </Modal>

                <MechanicLogBookModal />

                <div className="modal-90w">
                    <Modal dialogClassName="modal-90w" show={this.state.refundsModal} onHide={this.closeRefundsModal.bind(this)} backdrop="static" keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>
                                Rimborsi
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Alert key='refundsWarning' show={this.state.refundsWarning} variant='warning' onClose={() => this.setState({refundsWarning: false})} dismissible>
                                {this.state.checkMsg}
                            </Alert>
                            <Styles>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Numero</th>
                                            <th>Data</th>
                                            <th>
                                                km &nbsp;
                                                <OverlayTrigger
                                                    key={'top'}
                                                    placement={'top'}
                                                    overlay={
                                                        <Tooltip id={`tooltip-${'top'}`}>
                                                            Inserire 0 se il valore è sconosciuto
                                                        </Tooltip>
                                                    }
                                                    >
                                                    <FaQuestionCircle />
                                                </OverlayTrigger>
                                            </th>
                                            <th>Qta [litri]</th>
                                            <th>Prezzo al litro [€]</th>
                                            <th>Totale [€]</th>
                                            <th>Stato</th>
                                            <th>Azioni</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.refundsData.map((refuel, index) => {
                                            
                                            return <tr key={index}>
                                                <td>
                                                    <input 
                                                        name="num" 
                                                        id={"refuel_field_0_"+index} 
                                                        value={refuel.num} 
                                                        size="6" 
                                                        onChange={this.handleItemChanged.bind(this, index)} 
                                                        disabled={refuel.isChecked || refuel.valid || refuel.carta_carburante_id !== undefined ? true : false}
                                                    />
                                                </td>
                                                <td>
                                                    
                                                    <DatePicker 
                                                        name="datetime"
                                                        id={"refuel_field_1_"+index}
                                                        locale="it"
                                                        selected={this.parseDate(refuel.datetime, index)}
                                                        onChange={this.handeDateChange.bind(this, index)} 
                                                        dateFormat='dd/MM/yyyy'
                                                        style={{textAlign: "center"}}
                                                        disabled={ this.setDisabledDate(refuel)}
                                                        openToDate={d}
                                                    />
                                                    
                                                </td>
                                                <td>
                                                    <input 
                                                        name="cc_km" id={"refuel_field_2_"+index} value={refuel.cc_km}
                                                        size="15"
                                                        onChange={this.handleItemChanged.bind(this, index)}
                                                        disabled={refuel.isChecked || refuel.valid || refuel.carta_carburante_id  ? true : false}
                                                    />
                                                </td>
                                                <td>
                                                    <input 
                                                        name="qta" id={"refuel_field_3_"+index} value={refuel.qta}
                                                        size="10"
                                                        onChange={this.handleItemChanged.bind(this, index)}
                                                        disabled={refuel.isChecked || refuel.valid || refuel.carta_carburante_id  ? true : false}
                                                    />
                                                </td>
                                                <td>
                                                    <input name="prezzo_litro" id={"refuel_field_4_"+index} value={refuel.prezzo_litro}
                                                        size="10"
                                                        onChange={this.handleItemChanged.bind(this, index)}
                                                        disabled={refuel.isChecked || refuel.valid || refuel.carta_carburante_id  ? true : false}
                                                    />
                                                </td>
                                                <td>
                                                    <input name="prezzo_totale" id={"refuel_field_5_"+index} value={refuel.prezzo_totale}
                                                        size="10" 
                                                        onChange={this.handleItemChanged.bind(this, index)}
                                                        disabled={refuel.isChecked || refuel.valid || refuel.carta_carburante_id  ? true : false}
                                                    />
                                                </td>

                                                <td align="center">
                                                    <RowState refuel={refuel}/>
                                                </td>
                                                <td>
                                                    <div style={{display: "block"}}>
                                                    
                                                        {
                                                            refuel.carta_carburante_id !== undefined && refuel.carta_carburante_id !== 0 ?
                                                                <div style={{padding: "4px 4px 4px 4px", backgroundColor:"#ccc", margin: "4px", borderRadius: "5px"}}>Salvato</div>
                                                            :
                                                            !refuel.valid || !refuel.eval_check || !refuel.refuel_id ?    
                                                                <button style={{ padding: "0px 5px", marginRight: "5px" }} title="Check Row" class="btn btn-primary" onClick={this.checkRefund.bind(this, index)}>
                                                                    Check
                                                                </button>
                                                            :
                                                            ''
                                                        }

                                                 
                                                        {!refuel.carta_carburante_id && !refuel.refuel_id  ?
                                                            <button style={{ padding: "0px 5px" }} class="btn btn-primary" title="Delete Row" onClick={this.deleteRefund.bind(this, index)}>
                                                                <FaTrashAlt/>
                                                            </button>
                                                            :
                                                            ''
                                                        }
                                                    </div>

                                                </td>
                                            </tr>
                                                
                                        })}

                                    </tbody>
                                </table>
                                <br />
                                <button class="btn btn-secondary" onClick={this.addRow.bind(this)}>
                                    Aggiungi Riga
                                </button>
                            </Styles>
                        </Modal.Body>
                        <Modal.Footer>
                            <button class="btn btn-secondary" onClick={this.closeRefundsModal.bind(this)}>
                                Chiudi
                            </button>
                            <button class="btn btn-primary" onClick={this.submitRefundsModal.bind(this)}>
                                Salva
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>                
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        tokenDecoded: state.auth.tokenDecoded,
        devices: state.posizioni.devices,
        movimentiClickedTarga: state.movimenti.clickedTarga,
        selectOptions: state.posizioni.selectOptions,
        mechLogBookModalVisibility : state.garage.mechLogBookModalVisibility
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetSelectedDevice: (selectedDevice) => dispatch(actions.setSelectedDevice(selectedDevice)),
        onSetClickedTarga: (targa) => dispatch(actions.setClickedTarga(targa)),
        onSetMechLogBookModalVisibility: (isVisible, device) => dispatch(actions.setMechLogBookModalVisibility(isVisible, device))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Garage);