import * as actionTypes from './actionTypes';
import axios from 'axios';
import Moment from 'moment';

export const setCalendarDate = (year, month) => {
    return {
        type: actionTypes.SET_CALENDAR_DATE,
        year: year,
        month: month,
        spinner: true
    }
}

export const setSelectedDate = (date) => {
    return {
        type: actionTypes.SET_DATE,
        selectedDate: date
    }
}

export const setSelectedTarga = (targa) => {
    //console.log(targa)
    return {
        type: actionTypes.SET_TARGA,
        selectedTarga: targa
    }
}

export const setSelectedCC = (cc) => {
    //console.log(cc)
    return {
        type: actionTypes.SET_CC,
        selectedCC: cc
    }
}

export const setClickedTarga = (targa) => {
    //console.log(targa)
    return {
        type: actionTypes.SET_CLICKED_TARGA,
        clickedTarga: targa
    }
}

export const setSpinner = (spinner) => {
    return {
        type: actionTypes.SET_SPINNER,
        spinner: spinner
    }
}


export const fetchMovOK = (movimenti, year, month) => {
    
    //console.log(Moment(year + "-" + month, "YYYY-MM").daysInMonth())
    //console.log(movimenti.movements)

    var d = new Date();
    let todayMonth = d.getMonth() + 1
    let todayDay = d.getDate()
    //console.log(todayMonth, todayDay)
    //console.log(new Date().getDate()) //today number

    /*
    //days to add
    let daysToAdd = []

    Object.entries(movimenti.movements).some(([targaTemp, mm]) => {
        let daysCounter = 1;

        Object.entries(mm).some(([dateTemp, v]) => {
            //console.log(dateTemp)
            let day = Moment(dateTemp, 'YYYY-MM-DD').format('D')
            //console.log(daysCounter, day)
            if ( daysCounter != day ) {
                console.log("fechas Diferentes")
                // añadir dia al diccionario con valor = 0.
                daysToAdd.push(daysCounter)
                //daysCounter = day

                daysCounter++;
                while (daysCounter != day ) {
                    daysToAdd.push(daysCounter)
                    daysCounter++;
                }
            }

            if( month == todayMonth && daysCounter == Moment(year + "-" + month, "YYYY-MM").daysInMonth()) return true;
            
            daysCounter++;
            
            
        });
        console.log("------------------------------------------------------")
        
        return true; // make only the first loop
    });
    */


    //-------------------------------
    /* 

    let daysToAnalize = 0
    if (month == todayMonth) {
        daysToAnalize = todayDay
    } else {
        daysToAnalize = Moment(year + "-" + month, "YYYY-MM").daysInMonth()
    }

    console.log("days to Analize: " + daysToAnalize)
    let tempObject = {}
    let tempDaysObject = {}
    let tempMovementsObject = {}
    month = month < 10 ? '0'+ month : month

    let dayTempCounter = 0

    Object.entries(movimenti.movements).map(([targaTemp, mm]) => {
        tempMovementsObject[targaTemp] = {} 
    });


    for(var i = 1; i <=daysToAnalize; i++) {
        const temp = year + "-" + month + '-' + i
        dayTempCounter = i < 10 ? '0'+i : i
        if(movimenti.days[year + "-" + month + '-' + dayTempCounter] === undefined) {
            tempDaysObject[year + "-" + month + '-' + dayTempCounter] = false
        }
        else {
            tempDaysObject[year + "-" + month + '-' + dayTempCounter] = movimenti.days[year + "-" + month + '-' + dayTempCounter];
        }
        
        Object.entries(movimenti.movements).map(([targaTemp, mm]) => {
            if(mm[year + "-" + month + '-' + dayTempCounter] === undefined) {
                tempMovementsObject[targaTemp][year + "-" + month + '-' + dayTempCounter] = 0
            }
            else {
                tempMovementsObject[targaTemp][year + "-" + month + '-' + dayTempCounter] = mm[year + "-" + month + '-' + dayTempCounter]
            }

        });
        
    }
   

    tempObject = {
        days: tempDaysObject,
        movements: tempMovementsObject
    }
    */
    

    return {
        type: actionTypes.FETCH_MOVIMENTI_OK,
        //movimenti: tempObject,
        movimenti: movimenti,
        //colorFactor: createColorMap(tempObject)
        colorFactor: createColorMap(movimenti)
    }
}


export const fetchMovFail = () => {
    return {
        type: actionTypes.FETCH_MOVIMENTI_FAIL
    }
}

export const fetchMovimenti = (cc, year, month, promiscuo) => {

    let tempPromiscuo = null
    if(promiscuo) tempPromiscuo = 1;
    else tempPromiscuo = 0
    return dispatch => { 
        axios.get([process.env.REACT_APP_GEB_MIDDLEWARE, 'movimenti', cc, year, month, tempPromiscuo].join('/') , {}, {
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            if(res.data.retCode === "000") {
                console.log('/movimenti request ok');
                dispatch(fetchMovOK(res.data.info, year, month))
            } else {
                console.warn('/movimenti request error: ' + res.data.info.error);
                dispatch(fetchMovFail())
            }
        }).catch(err => {
            console.error('/movimenti request fail: ' + err);
            dispatch(fetchMovFail())
        });
    }

};

const createColorMap = (movimenti) => {
    var min = Infinity;
    var max = -Infinity;

    for (var i in movimenti.movements)
        for (var j in movimenti.movements[i]) {
            var v = movimenti.movements[i][j];
            min = Math.min(min, v);
            max = Math.max(max, v);
        }

    var f = 256 / (max - min);
    return f
};


export const setFilterPromiscMode = (checked) => {
    return {
        type: actionTypes.SET_MOVIMENTI_FILTER_PROMISCUOS,
        promiscMode: checked
    }
}

// Used to retrieve ccId mainly. So if the user is a CC, we can use the same function as there was the filter with all CCs.
// Admin function
export const getCCInfo = () => {
    return dispatch => { 
        axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/getCCInfo', {}, {
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            if(res.data.retCode === "000") {
                console.log('/getCCInfo request ok');
                dispatch(getCCInfoOK(res.data.info))
            } else {
                console.warn('/getCCInfo request error: ' + res.data.info.error);
                dispatch(getCCInfoFail())
            }
        }).catch(err => {
            console.error('/getCCInfo request fail: ' + err);
            dispatch(getCCInfoFail())
        });
    }
}

export const getCCInfoOK = (data) => {
    return {
        type: actionTypes.SET_SINGLE_CC_DATA_OK,
        capo_cantiere_id:  data.capo_cantiere_id,
        nome: data.nome,
        cognome: data.cognome,
        customZoom: data.customZoom ? data.customZoom : process.env.REACT_APP_POSITIONS_DEFAULT_ZOOM
    }
}


export const getCCInfoFail = () => {
    return {
        type: actionTypes.SET_SINGLE_CC_DATA_FAIL
    }
}

// Admin function
export const getCCTarghe = (ccEmail = 0) => {
    let data = {}
    data['cc'] = [ccEmail]
    return dispatch => {
        axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/actual', data)
        .then(response => {
            if(response.data.retCode === "000") {
                dispatch(ccTargheSuccess(response.data.info));
            } else {
                dispatch(ccTargheFail(response.data.info.error));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(ccTargheFail(err));
        })
    }
}

export const ccTargheSuccess = (devices) => {
    const temp = devices.sort(function(a, b) {
        if(a.targa < b.targa) { return -1 }
        if(a.targa > b.targa) { return 1 }
        return false;
    });
    const options = []

    //options.push({ label: "Qualsiasi Targa", value: 0 })

    temp.map((item) => {
        options.push({
            label: item.targa + ' ' + (item.codice_mezzo ? item.codice_mezzo : '###'),
            value: item.deviceId
        })
        return false;
    });

    return {
        type: actionTypes.CC_TARGHE_SUCCESS, 
        selectOptions: options,
    };
};

export const ccTargheFail = (error) => {
    return {
        type: actionTypes.CC_TARGHE_FAIL,
        error: error
    };
};

export const resetTargheOptions = (options) => {
    return {
        type: actionTypes.RESET_TARGHE_OPTIONS,
        selectOptions: options
    }
}