import * as actionTypes from '../actions/actionTypes';

let d = new Date();

const initialState = {
    refuelingsData: null, // array che contiene i rifornimenti di una targa dentro Garaje->Dettagli rifornimenti
    bounds: null,
    error: '',
    gestori: null,
    spinner: true,
    // CercaX sono delle variabile da tenere in memoria Redux per poter accedere a loro per caricare i filtri della pagina Rifornimenti
    cercaRif: null, //array che contiene i risultati della pagina Rifornimenti
    cercaFilterTarga: null,
    cercaFilterDateStart: d.setDate(0),
    cercaFilterDateEnd: d,
    cercaFilterKm: '',
    cercaFilterImporto: '',
    cercaFilterGestore: null
};

const reducer = (state = initialState, action) => {
    let tempDataRefuel = state.cercaRif;
    switch(action.type) {
        case actionTypes.REFUELINGS_SUCCESS:
            return {
                ...state,
                refuelingsData: action.data,
                bounds: action.bounds
            }
        case actionTypes.REFUELINGS_FAIL:
            return {
                ...state,
                error: action.error,
                refuelingsData: null
            }
        case actionTypes.RESET_REFUELINGS:
            return {
                ...state,
                refuelingsData: null
            }
        case actionTypes.GESTORI_SUCCESS:
            return {
                ...state,
                gestori: action.data
            }
        case actionTypes.GESTORI_FAIL:
            return {
                ...state,
                error: action.error,
                gestori: null
            }
        case actionTypes.CERCA_RIF_SUCCESS:
            return {
                ...state,
                cercaRif: action.data,
                spinner: false             
            }
        case actionTypes.CERCA_RIF_FAIL:
            return {
                ...state,
                error: action.error,
                cercaRif: null,
                spinner: false
            }
        case actionTypes.SET_CERCA_RIF_FILTERS:
            if(action.filterName === 'targa')
                return {
                    ...state,
                    cercaFilterTarga: action.filterValue
                }
            else if(action.filterName === 'km')
                return {
                    ...state,
                    cercaFilterKm: action.filterValue
                }
            else if(action.filterName === 'importo')
                return {
                    ...state,
                    cercaFilterImporto: action.filterValue
                }
            else if(action.filterName === 'data_inizio')
                return {
                    ...state,
                    cercaFilterDateStart: action.filterValue
                }
            else if(action.filterName === 'data_fine')
                return {
                    ...state,
                    cercaFilterDateEnd: action.filterValue
                }
            else if(action.filterName === 'gestore')
                return {
                    ...state,
                    cercaFilterGestore: action.filterValue
                }

            /*
            return {
                ...state,
                cercaFilterTarga: action.cercaFilterTarga,
                cercaFilterDateStart: action.cercaFilterDateStart,
                cercaFilterDateEnd: action.cercaFilterDateEnd,
                cercaFilterKm: action.cercaFilterKm,
                cercaFilterImporto: action.cercaFilterImporto,
                cercaFilterGestore: action.cercaFilterGestore   
            }
            */
        case actionTypes.SET_SPINNER_REFUEL:
            return {
                ...state,
                cercaRif: [],
                spinner: action.spinner,
            }
        case actionTypes.UPDATE_CHECKBOX_SUCCESS:

            tempDataRefuel.refuel.map((item) => {
                if(item.refuel_id === action.vR.refuel_id) {
                    if(action.checked) item.stato = 'm'
                    else item.stato = 'n'
                }
            })
            return {
                ...state,
                cercaRif: tempDataRefuel,
                error: null
            }
        case actionTypes.UPDATE_CHECKBOX_FAIL:
            return {
                error: 'Manual Update error'
            }
        case actionTypes.UPDATE_SUSPICTIOUS_CHECKBOX_SUCCESS:

            tempDataRefuel.refuel.map((item) => {
                if(item.refuel_id === action.vR.refuel_id) {
                    if(action.checked) item.suspictious = '1'
                    else item.suspictious = '0'
                }
            })
            return {
                ...state,
                cercaRif: tempDataRefuel,
                error: null
            }
        case actionTypes.UPDATE_SUSPICTIOUS_CHECKBOX_FAIL:
            return {
                error: 'Suspictious Update error'
            }
        case actionTypes.UPDATE_SPECIFIC_LITERS_SUCCESS:

            tempDataRefuel.refuel.map((item) => {
                if(item.refuel_id === action.vR.refuel_id) {
                    item.litri_dichiarati = action.sL
                }
            })
            console.log(tempDataRefuel)
            return {
                ...state,
                cercaRif: tempDataRefuel,
                error: null
            }
        case actionTypes.UPDATE_SPECIFIC_LITERS_FAIL:
            return {
                error: 'Specific liters Update error'
            }
        default:
            return state;
    }
};

export default reducer;