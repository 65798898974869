import L from 'leaflet';

export function calcMapBoundaries (markers, addedBound = 0.0) {
    if(markers.length > 0 ) {
        let left = 0.0, right = 0.0, top = 0.0, bottom = 0.0;
        markers.map((marker, index) => {
            if( marker.latitude === null || marker.longitude === null) return false;
            if(index === 0) { top = marker.latitude; bottom = marker.latitude; left= marker.longitude; right = marker.longitude; }
            if(marker.latitude > top) top = marker.latitude;
            if(marker.latitude < bottom) bottom = marker.latitude;
            if(marker.longitude > left) left = marker.longitude;
            if(marker.longitude < right) right = marker.longitude;
            return false;
        });
        if( top === bottom && left === right ) { // in case there is only 1 marker  
            left = 6.259889;
            right = 24.514390;
            top = 46.525775
            bottom = 36.130547;
        }
        //Add Bounds
        bottom = bottom - addedBound;
        left = left + addedBound;
        top = top + addedBound;
        right = right - addedBound;

        const corner1 = L.latLng( bottom, left ), //SW
        corner2 = L.latLng( top, right ); //NE
        //console.log(corner1, corner2)
        return L.latLngBounds(corner1, corner2)
    }       
}

function degreesToRadians(degrees) {
  return degrees * Math.PI / 180;
}

// distanceInKmBetweenEarthCoordinates
export function measureDistance(lat1, lon1, lat2, lon2) {
  var earthRadiusKm = 6371;
  var dLat = degreesToRadians(lat2-lat1);
  var dLon = degreesToRadians(lon2-lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 

  return earthRadiusKm * c;
}

/** 
 * Given "0-360" returns the nearest cardinal direction "N/NE/E/SE/S/SW/W/NW"  
 */
export function calcCardinal(angle) {
  /** 
   * Customize by changing the number of directions you have
   * We have 8
   */
  const degreePerDirection = 360 / 8;

  /** 
   * Offset the angle by half of the degrees per direction
   * Example: in 4 direction system North (320-45) becomes (0-90)
   */
  const offsetAngle = angle + degreePerDirection / 2;

  return (offsetAngle >= 0 * degreePerDirection && offsetAngle < 1 * degreePerDirection) ? "N"
    : (offsetAngle >= 1 * degreePerDirection && offsetAngle < 2 * degreePerDirection) ? "NE"
      : (offsetAngle >= 2 * degreePerDirection && offsetAngle < 3 * degreePerDirection) ? "E"
        : (offsetAngle >= 3 * degreePerDirection && offsetAngle < 4 * degreePerDirection) ? "SE"
          : (offsetAngle >= 4 * degreePerDirection && offsetAngle < 5 * degreePerDirection) ? "S"
            : (offsetAngle >= 5 * degreePerDirection && offsetAngle < 6 * degreePerDirection) ? "SW"
              : (offsetAngle >= 6 * degreePerDirection && offsetAngle < 7 * degreePerDirection) ? "W"
                : "NW";
}