import React, { useState } from 'react'
import { connect } from 'react-redux';
import Moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/it';
import moment from 'moment-timezone';

import classes from './Movimenti.module.css';

import { Modal, Button } from 'react-bootstrap'


import { Row, Col} from 'react-bootstrap'

import * as actions from '../../store/actions/index';

import Storico from '../Storico/Storico'

import './MovimentiResults.css'


export const MovimentiResults = (props) => {

    const [showModal, setShowModal] = useState(false);

    const goToStorico = (e, targa, days, col, distanza) => {
        /*
        console.log(e)
        console.log(targa)
        console.log(days)
        console.log(col)
        */
        let selectedDay = null;
        Object.entries(days).map(([day, km], index) => {
            //console.log(day, index-1)
            if(col === index)
            {
                selectedDay = day;
                return false;
            }
        });

        targa = targa.split(" ")[0]

        //console.log(selectedDay)
        props.onSetSelectedDate(selectedDay)
        props.onSetClickedTarga(targa)
        //props.history.push('/percorsi')

        setShowModal(true)
    }

    const colorMapFun = (v) => {
        if (v == 0) return '#AAA';
        else if(0 < v && v <= 300) return '#A5FF70'
        else if (300 < v && v <= 700) return '#FFA500'
        else if (v > 700 ) return '#FF7070'
        else return '#FF00FF' // caso impossibile, solo DEBUG
    }

    const closeModal = () => {
        setShowModal(false)
    }

    return (
        <div>

            <div  className={classes.movimenti} style={{marginTop: '10px', marginLeft: '8px'}}>
                <Row style={{display: "contents"}}>
                    <Col>
                        <div className={classes.fixTableHead}>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{backgroundColor: "#fff", zIndex: '99'}}></th>
                                    {
                                        Object.entries(props.movimenti.days).map(([k, v]) => {
                                            //console.log(k, v)
                                            const d = new Date(k)
                                            let isWeekend = d.getDay()%6==0
                                            //console.log(isWeekend)
                                            //let isSaturday = d.getDay()
                                            if(isWeekend) 
                                                return <th className={classes.weekend}>{Moment(k).format('DD')}</th>
                                            else
                                                return <th className={ v ? classes.festivo : '' }>
                                                    { Moment(k).format('DD') }
                                                </th>;
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    
                                    Object.entries(props.movimenti.movements).map(([targaTemp, mm]) => {
                                        //if (targa && targa.value !== 0 && ccId) {
                                        //console.log("1111")
                                        if (props.selectedTarga && props.selectedTarga.value !== 0 && props.selectedCC) {
                                            //console.log("2222")
                                            if( props.selectedTarga.label.split(" ")[0] === targaTemp.split(" ")[0] ) {
                                                //console.log("3333")
                                                return <tr>
                                                    <td>
                                                        {targaTemp}
                                                    </td>
                                                    {
                                                        Object.entries(mm).map(([k, v], index) => {
                                                            if (v > 0) {
                                                                if ( v > 3000)
                                                                    return (
                                                                        <td className={classes.cella} style={{backgroundColor: colorMapFun(v)}}>
                                                                            ?
                                                                        </td>
                                                                    )
                                                                else 
                                                                    return (
                                                                        <td className={classes.cella} style={props.clickedTarga ? {border: '1px solid black', background: '#ACF'} : {backgroundColor: colorMapFun(v)} } onClick={(e) => goToStorico(e, targaTemp, mm, index, v)}>
                                                                            {v}
                                                                        </td>
                                                                    )
                                                            }
                                                            else {
                                                                return(
                                                                    <td className={classes.cella} style={props.clickedTarga ? {border: '1px solid black', background: '#ACF'} : {backgroundColor: colorMapFun(v)} } onClick={(e) => goToStorico(e, targaTemp, mm, index, 0)}>
                                                                        0
                                                                    </td>
                                                                )
                                                            }

                                                        })
                                                    }
                                                </tr>;
                                            }
                                        } else {
                                            return <tr>
                                                <td>
                                                    {/* 
                                                    <Button 
                                                        variant="outline-primary" 
                                                        style={{fontSize: "21px"}}
                                                        size="sm"
                                                        onClick={(e) => {
                                                            
                                                            const {history} = props;
                                                            if (history) {
                                                                const clickedTarga = e.target.innerHTML
                                                                props.onSetClickedTarga(clickedTarga)
                                                                history.push('/garage')
                                                            }
                                                        }}
                                                    >
                                                        { targaTemp }
                                                    </Button>
                                                    */}
                                                    <div 
                                                        className={classes.targa}
                                                        onClick={(e) => {
                                                            const {history} = props;
                                                            if (history) {
                                                                const clickedTarga = e.target.innerHTML
                                                                props.onSetClickedTarga(clickedTarga)
                                                                history.push('/garage')
                                                            }
                                                        }}
                                                    >
                                                        { targaTemp.split(" ")[0] }
                                                    </div>
                                                    &nbsp;
                                                    <span style={{fontSize:"12px", float: 'right', marginTop: '8px'}}>{targaTemp.split(" ")[1]}</span>
                                                </td>
                                                
                                                {
                                                    Object.entries(mm).map(([k, v], index) => {
                                                        //console.log(index, k, v)
                                                        if (v > 0) {
                                                            if ( v > 3000)
                                                                return (
                                                                    <td className={classes.cella} style={{backgroundColor: colorMapFun(v)}}>
                                                                        ?
                                                                    </td>
                                                                )
                                                            else 
                                                                return (
                                                                    <td className={classes.cella} 
                                                                        style={props.clickedTarga && props.clickedTarga ===  targaTemp.split(" ")[0]&& props.clickedDay == (index+1) ? {border: '2px solid #262626', background: '#ACF'} : {backgroundColor: colorMapFun(v)}} 
                                                                        onClick={(e) => goToStorico(e, targaTemp, mm, index)}
                                                                    >
                                                                        {v}
                                                                    </td>
                                                                )
                                                        }
                                                        else {
                                                            return(
                                                                <td className={classes.cella} 
                                                                    style={props.clickedTarga && props.clickedTarga ===  targaTemp.split(" ")[0] && props.clickedDay == (index+1)? {border: '2px solid #262626', background: '#ACF'} : {backgroundColor: colorMapFun(v)}} 
                                                                    onClick={(e) => goToStorico(e, targaTemp, mm, index)}
                                                                >
                                                                    {0}
                                                                </td>
                                                            )
                                                        }

                                                    })
                                                }
                                            </tr>;
                                        }
                                    })
                                }
                            </tbody>
                        </table>
                        </div>

                    </Col>
                </Row>
            </div>

            <Modal show={showModal} 
                onHide={() => setShowModal(false)}
                dialogClassName="modal-90w"
                backdrop="static" keyboard={false}>
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    <div style={{height: "750px"}}>
                        <Storico />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button class="btn btn-primary" onClick={() => closeModal()}>
                        Chiudi
                    </button>
                </Modal.Footer>
            </Modal>


        </div>
    )
}

const mapStateToProps = state => {
    let clickedData = moment(state.movimenti.selectedDate);
    return {
        movimenti: state.movimenti.movimenti,
        selectedTarga: state.movimenti.selectedTarga,
        selectedCC: state.movimenti.selectedCC,
        clickedTarga: state.movimenti.clickedTarga,
        clickedDay: clickedData.format('D')
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetClickedTarga: (targa) => dispatch(actions.setClickedTarga(targa)),
        onSetSelectedDate: (date) => dispatch(actions.setSelectedDate(date)),
    };
};

export default connect ( mapStateToProps, mapDispatchToProps ) (MovimentiResults);