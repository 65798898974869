import React, {Component} from 'react';
import './MyMonthPicker.css';

import Picker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';

import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';

import Moment from 'moment';


class MonthBox extends Component {
    constructor(props) {
      super(props)
      this.state = {
          value: this.props.value || 'N/A',
      }
      this._handleClick = this._handleClick.bind(this);
    }

    componentWillReceiveProps(nextProps){
      this.setState({
          value: nextProps.value || 'N/A',
      })
    }

    render() {
      return (
        <div className="box" onClick={this._handleClick}>
            <label>{this.state.value}</label>
        </div>
      );
    }

    _handleClick(e) {
      this.props.onClick && this.props.onClick(e);
    }
}


class MyMonthPicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            mvalue: {
                year: parseInt(Moment().format('Y')),
                month: parseInt(Moment().format('M'))
            }
        }

        this.handleClickMonthBox = this.handleClickMonthBox.bind(this)
        this.handleAMonthChange = this.handleAMonthChange.bind(this)
        this.handleAMonthDissmis = this.handleAMonthDissmis.bind(this)

    }

    componentWillReceiveProps(nextProps){
        this.setState({
            value: nextProps.value || 'N/A'
        });
    }

    render() {

        const pickerLang = {
            months: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
        }
        /*
        console.log(this.state.mvalue)
        console.log(this.props.selectedYear)
        console.log(this.props.selectedMonth)
        */
        //const mvalue = this.state.mvalue ? this.state.mvalue : {year: this.props.selectedYear, month: this.props.selectedMonth}
        const mvalue = this.props.selectedYear ? {year: this.props.selectedYear, month: this.props.selectedMonth} : this.state.mvalue
        //console.log(mvalue)


        const makeText = m => {
            if (m && m.year && m.month) return (pickerLang.months[m.month-1] + '. ' + m.year)
            return '?'
        }

        return (
            <div>
                <div className="edit">
                    <Picker
                        ref="pickAMonth"
                        years={[2020, 2021, 2022, 2023, 2024, 2025]}
                        value={mvalue}
                        lang={pickerLang.months}
                        onChange={this.handleAMonthChange}
                        onDismiss={this.handleAMonthDissmis}
                        
                    >
                        <MonthBox value={makeText(mvalue)} onClick={this.handleClickMonthBox} />
                    </Picker>
                </div>

            </div>
        )
    }

    handleClickMonthBox(e) {
        this.refs.pickAMonth.show()
    }
    handleAMonthChange(year, month) {
        //
        //console.log(year, month)
        this.props.onSetCalendarDate(year, month);
        if(this.props.selectedCC) this.props.onSetSpinner(true)
    }

    handleAMonthDissmis(value) {
        this.setState( {mvalue: value} )
    }



}

const mapStateToProps = (state) => {
    return  {
        selectedYear: state.movimenti.year,
        selectedMonth: state.movimenti.month,
        selectedCC: state.movimenti.selectedCC
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        onSetCalendarDate: (year, month) => dispatch(actions.setCalendarDate(year, month)),
        onSetSpinner: (spinner) => dispatch(actions.setSpinner(spinner)),
        //onFetchMovimenti: (selectedCC, year, month) => dispatch(actions.fetchMovimenti(selectedCC, year, month))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyMonthPicker);

/*
class Main extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            value: this.props.value
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            value: nextProps.value
        })
    }

    render() {

        return (
            <div className="list-area">
                <List />
            </div>
        )
    }
}



export default () => {
  
  return (<Main/>);

}
*/