import * as actionTypes from '../actions/actionTypes';

const initialState = {
    refundsData: [],
    show: false
    
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.REFUNDS_SUCCESS:
            return {
                ...state,
                refundsData: action.refunds
            }
        case actionTypes.REFUNDS_FAIL:
            return {
                ...state,
                error: action.error
            }
        case actionTypes.OPEN_MODAL:
            return {
                ...state,
                show: true
            }
        case actionTypes.CLOSE_MODAL:
            return {
                ...state,
                show: false
            }
        default:
            return state;
    }
}

export default reducer;