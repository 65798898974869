import React from 'react';
import axios from 'axios';
import Moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/it';
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import { connect } from 'react-redux';

import "bootstrap/dist/css/bootstrap.min.css";
import "./Scadenze.scss";


class Scadenze extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            token: props.token,
            tokenDecoded: props.tokenDecoded,
            notificationMode: true, // la modalità notifica mostra il tasto "Postponi" invece di chiudi, logga quando viene premuto e itera su tutte le scadenze dell'utente
            modalVisible: false,
            device_id: null,
            targa: null,
            revisione: null,
            bollo: null,
            assicurazione: null,
            updateOk: false,
            updateFail: false,
            listaDevice: [],
            currentDevice: null
        };

        this.onShowScadenze = function(e) {
            if (e.type === 'showScadenze') {
                e.preventDefault();
                this.setState({notificationMode: e.detail.notificationMode});
                this.getScadenze(e.detail.device_id);
            }
        }.bind(this);

        this.getScadenze = function(device_id) {
            console.log('Requesting expirations for ' + device_id + '...');

            axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/expirations/' + device_id, {}, {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.data.retCode === "000") {
                    this.setState(res.data.info);
                    this.showModal();
                } else
                    console.warn('GET /expirations request error: ' + res.data.info.error);
            }).catch(err => {
                console.error('GET /expirations request fail: ' + err);
            });
        };

        this.onDateChange = function(name, event) {
            var stateUpdate = {};
            stateUpdate[name] = event.target.value;
            this.setState(stateUpdate);
        };

        this.updateScadenze = function() {
            console.log('Updating scadenze...');

            var device_id = this.state.device_id;

            axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/expirations/' + device_id, {
                revisione: this.state.revisione,
                bollo: this.state.bollo,
                assicurazione: this.state.assicurazione
            }, {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.data.retCode === "000") {
                    this.setState({updateOk: true});

                    window.dispatchEvent(new CustomEvent('updateScadenze', {
                        detail: {
                            device_id: device_id,
                            revisione: this.state.revisione,
                            bollo: this.state.bollo,
                            assicurazione: this.state.assicurazione
                        }
                    }));

                    this.showNextDevice();
                } else {
                    console.warn('POST /expirations request error: ' + res.data.info.error);
                    this.setState({updateFail: true});
                }
            }).catch(err => {
                console.error('POST /expirations request fail: ' + err);
                this.setState({updateFail: true});
            });
        };

        this.postpone = function() {
            axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/postpone_expirations/' + this.state.device_id, {
            }, {
                headers: {'Content-Type': 'application/json', 'authorization': this.state.token}
            }).then(res => {
                if(res.data.retCode != "000")
                    console.warn('GET /postpone_expirations request error: ' + res.data.info.error);
            }).catch(err => {
                console.error('GET /postpone_expirations request fail: ' + err);
            }).finally(() => {
                this.showNextDevice();
            });
        };

        this.showModal = function() {
            console.log('Showing expiration modal with notificationMode=' + this.state.notificationMode);
            this.setState({modalVisible: true});
        };

        this.hideModal = function() {
            this.setState({modalVisible: false});
        };

        this.checkScadenzeLoop = function() {
            console.log(this.state.tokenDecoded);
            console.log('Requesting check_expirations...');

            axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/check_expirations', {}, {
                headers: {'Content-Type': 'application/json', 'authorization': this.state.token}
            }).then(res => {
                if(res.data.retCode === "000") {
                    this.setState({listaDevice: res.data.info, notificationMode: true});
                    this.showNextDevice();
                } else
                    console.warn('GET /check_expirations request error: ' + res.data.info.error);
            }).catch(err => {
                console.error('GET /check_expirations request fail: ' + err);
            });
        };

        this.showNextDevice = function() {
            if (this.state.listaDevice.length == 0) {
                this.setState({currentDevice: null});
                this.hideModal();
                console.log('No more expiring devices!');
                return;
            }

            var currentDevice = this.state.listaDevice.splice(0, 1)[0];
            this.setState(currentDevice);
            this.showModal();

            console.log('Current expiring device:', currentDevice);
        };

        Moment.locale('it');
    }

    componentDidMount(pros) {
        window.addEventListener('showScadenze', this.onShowScadenze);
        window.addEventListener('loginSuccess', this.checkScadenzeLoop.bind(this));

        //setTimeout(this.checkScadenzeLoop.bind(this), 5000)
    }

    componentWillUnmount() {
        window.removeEventListener('showScadenze', this.onShowScadenze);
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        return (
            <div className="scadenze">
                <div class="alerts-scadenze">
                    <Alert key='updateScadenzeSuccess' show={this.state.updateOk} variant='success' onClose={() => this.setState({updateOk: false})} dismissible>
                        Date aggiornate correttamente!
                    </Alert>
                    <Alert key='updateScadenzeFail' show={this.state.updateFail} variant='danger' onClose={() => this.setState({updateFail: false})} dismissible>
                        Errore aggiornamento date!
                    </Alert>
                </div>
                <Modal show={this.state.modalVisible} style={{backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10001"}} className="modal-scadenze">
                    <Modal.Header>
                        <Modal.Title>
                            Aggiorna scadenze
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="row">
                            <div class="col-12 text-center">
                                <span class="targa">{this.state.targa}</span>
                            </div>
                            <div class="col-6">
                                <p className={[Moment(this.state.revisione) < Moment() ? 'scaduta' : 'regolare', Moment(this.state.revisione) < Moment().add('months', 2) ? 'in_scadenza' : ''].join(' ')}>
                                    Revisione:
                                </p>
                            </div>
                            <div class="col-6 text-right">
                                <input class="form-control" type="date" value={this.state.revisione ? this.state.revisione : ''} onChange={this.onDateChange.bind(this, 'revisione')} />
                            </div>
                            <div class="col-6">
                                <p className={[Moment(this.state.bollo) < Moment() ? 'scaduta' : 'regolare', Moment(this.state.bollo) < Moment().add('months', 2) ? 'in_scadenza' : ''].join(' ')}>
                                    Bollo:
                                </p>
                            </div>
                            <div class="col-6 text-right">
                                <input class="form-control" type="date" value={this.state.bollo ? this.state.bollo : ''} onChange={this.onDateChange.bind(this, 'bollo')} />
                            </div>
                            <div class="col-6">
                                <p className={[Moment(this.state.assicurazione) < Moment() ? 'scaduta' : 'regolare', Moment(this.state.assicurazione) < Moment().add('months', 2) ? 'in_scadenza' : ''].join(' ')}>
                                    Assicurazione:
                                </p>
                            </div>
                            <div class="col-6 text-right">
                                <input class="form-control" type="date" value={this.state.assicurazione ? this.state.assicurazione : ''} onChange={this.onDateChange.bind(this, 'assicurazione')} />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button class="btn btn-success" onClick={this.updateScadenze.bind(this)}>
                            Aggiorna
                        </button>
                        { this.state.notificationMode ?
                            <button class="btn btn-warning" onClick={this.postpone.bind(this)}>
                                Postponi
                            </button>
                            :
                            <button class="btn btn-warning" onClick={this.hideModal.bind(this)}>
                                Chiudi
                            </button>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        tokenDecoded: state.auth.tokenDecoded
    }
}

export default connect(mapStateToProps, null)(Scadenze);