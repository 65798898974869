import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container } from 'react-bootstrap'
import Plot from 'react-plotly.js';
import axios from 'axios';
import moment from 'moment'

export const InterventiCharts = (props) => {

  const [ dataChart1 , setDataChart1] = useState([]);
  const [ dataChart2 , setDataChart2] = useState([]);
  const [ dataChart3 , setDataChart3] = useState([]);
  const [ dataChart4 , setDataChart4] = useState([]);

  useEffect(() => {
    if(props.tipiInterventi && props.tipiInterventi.length > 0)
      getChartsData(props.filterTarga, props.filterDateStart, props.filterDateEnd, props.filterIntervento, props.filterAuthorization, props.filterModello, props.filterNumPratica)
    return () => {
      
    };
  }, [props.tipiInterventi, props.filterTarga, props.filterDateStart, props.filterDateEnd, props.filterIntervento, props.filterAuthorization, props.filterModello, props.filterNumPratica])

  const findInterventoDescription = (id) => {
    if(id){
      const selectedIntervento = props.tipiInterventi.filter(intervento => intervento.operazione_id == id)[0]
      return selectedIntervento.descrizione_operazione
    }
    else {
      return 'Non identificato'
    }
  }

  const sortData = (graphId,data) => {
    let output = {
      'x_axis': [],
      'y_axis': []  
    }

    if (graphId === 1)
      Object.entries(data.logbook_ts).forEach(([k,v]) => {
        output['x_axis'].push(k)
        output['y_axis'].push(v)
    })

    if (graphId === 2)
      Object.entries(data).forEach(([k,v]) => {
        //console.log("The key: ", k)
        //console.log("The value: ", v)
        output['x_axis'].push(k)
        output['y_axis'].push(v)
    })

    if(graphId === 3)
      data.map((item) => {
        output['x_axis'].push(findInterventoDescription(item.operazione_id))
        output['y_axis'].push(item.importo)
      });

    if(graphId === 4)
      data.map((item) => {
        output['x_axis'].push(findInterventoDescription(item.operazione_id))
        output['y_axis'].push(item.qty)
      });

    return output;
  }

  const getChartsData = async (targa = 0, dataStart, dataEnd, interventoType = 0, authorizationType = 0, modelloType = 0, numPratica = 0) => {
    const tempdataStart = moment(dataStart).format('YYYY-MM-DD')
    const tempdataEnd = moment(dataEnd).format('YYYY-MM-DD')
    let data = {
      "targa": targa && targa.value != 0 ? targa.label.split(" ")[0]: null,
      "data_inizio": tempdataStart,
      "data_fine": tempdataEnd,
      "intervento_type": interventoType? interventoType.value : null,
      "authorization_type": authorizationType? authorizationType.value : null,
      "modello_type": modelloType? modelloType.value : null,
      "numPratica": numPratica ? numPratica : null

    }

    await axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/interventiCharts', data
    ).then(res => {
        if(res.data.retCode === "000") {
          setDataChart1(sortData(1, res.data.info.chart_1))
          setDataChart2(sortData(2, res.data.info.chart_2))
          setDataChart3(sortData(3, res.data.info.chart_3))
          setDataChart4(sortData(4, res.data.info.chart_4))
        }
    }).catch(err => {
      console.error('/getCharts request fail: ' + err);
    });

  }

  let trace1 = {
    x: ['2021-10-02 22:23:00', '2021-11-17 22:23:00', '2021-12-04 22:23:00'],
    y: [1, 3, 6],
    mode: 'markers',
    type: 'scatter'
  };

  var trace2 = {
    x: ['2021-10-01 22:23:00', '2021-11-13 22:23:00', '2021-12-04 22:23:00'],
    y: [1, 3, 6],
    mode: 'lines',
    type: 'scatter'
  };

  var trace3 = {
    x: ['2021-10-04 22:23:00', '2021-11-14 22:23:00', '2021-12-04 22:23:00'],
    y: [1, 3, 6],
    mode: 'lines+markers',
    type: 'scatter'
  };

  var data = [trace1, trace2, trace3];

  return (
      <Row style={{marginTop: '10px', marginBottom: '10px'}}>

      {dataChart1 ? 
        <Col>
          <div style={{
          width: '420px', height: '290px', 
          border: '1px solid #ced4da', borderRadius:'4px'
          }}>
            <Plot
              data={[
                /*
                {
                  values: dataChart1['logbook_ts'],
                  labels: dataChart1['anno'],
                  type: 'pie'
                },
                */
                {
                  type: 'bar', 
                  y: dataChart1['y_axis'],
                  x: dataChart1['x_axis']
                },
              ]}
              layout={{
                width: 398, 
                height: 288, 
                title: 'Interventi / tempo (cumulativo)',
                xaxis: {
                  tickformat: ".0f",
                }
                /*
                plot_bgcolor:"black",
                paper_bgcolor:"#FFF3"
                */
              }}
              
            />
          </div>
        </Col>
        :
        <></>
      }


      {dataChart2 ? 
        <Col>
          <div style={{
          width: '420px', height: '290px', 
          border: '1px solid #ced4da', borderRadius:'4px'
          }}>
            <Plot
              data={[
                {
                  type: 'bar', 
                  y: dataChart2['y_axis'],
                  x: dataChart2['x_axis']
                },
              ]}
              layout={{
                width: 398, 
                height: 288, 
                title: 'Costo Interventi / tempo',
                xaxis: {
                  tickformat: ".0f",
                }
              }}
            />
          </div>
        </Col>
        :
        <></>
      }

      
      {dataChart3 ?
        <Col>
          <div style={{
          width: '420px', height: '290px', 
          border: '1px solid #ced4da', borderRadius:'4px'
          }}>
            <Plot
              data={[
                {
                  values: dataChart3['y_axis'],
                  labels: dataChart3['x_axis'],
                  type: 'pie'
                },
              ]}
              layout={ {width: 398, height: 308, title: '% Costo Tipo Intervento',  plot_bgcolor:"black", paper_bgcolor:"#FFF3"} }
            />
          </div>
        </Col>
        :
        <></>
      }

      {dataChart4 ?
        <Col>
          <div style={{
          width: '430px ', height: '290px', 
          border: '1px solid #ced4da', borderRadius:'4px',
          }}>
            <Plot
              data={[
                {
                  values: dataChart4['y_axis'],
                  labels: dataChart4['x_axis'],
                  type: 'pie'
                },
              ]}
              layout={ {width: 398, height: 310, title: '% Tipo Intervento',  plot_bgcolor:"black", paper_bgcolor:"#FFF3"} }
            />
          </div>
        </Col>
        :
        <></>
      }


    </Row>
  )
}


const mapStateToProps = (state) => {
  return {
    tipiInterventi: state.interventi.tipiInterventi,
    filterDateStart: state.interventi.filterDateStart,
    filterDateEnd: state.interventi.filterDateEnd,
    filterTarga: state.interventi.filterTarga,
    filterIntervento: state.interventi.filterIntervento,
    filterAuthorization: state.interventi.filterAuthorization,
    filterModello: state.interventi.filterModello,
    filterNumPratica: state.interventi.filterNumPratica,
    interventi: state.interventi.interventi
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(InterventiCharts)