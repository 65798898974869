import axios from 'axios';

import * as actionTypes from './actionTypes';

import { calcMapBoundaries } from '../../utils/calculus';

export const storicoSuccess = (deviceMarkers) => {
    return  {
        type: actionTypes.STORICO_SUCCESS,
        deviceMarkers: deviceMarkers,
        bounds: calcMapBoundaries(deviceMarkers)
    }
}

export const storicoFailed = (error) => {
    return {
        type: actionTypes.DEVICE_MARKERS_FAIL,
        error: error
    };
};


export const getDeviceMarkers = (targa = null, searchDate= null, hourStart = null, hourStop = null) => {
    return dispatch => {
        //console.log("STORICO ACTION: getDeviceMarkers!");
        let data=[
            "targa=" + targa,
            "day=" + searchDate,
            "h_start=" + hourStart,
            "h_stop=" + hourStop
        ]
        
/*
        if(!searchDate) { //First call
            data.push('searchDate='+ moment().format('YYYY-MM-DD') + 'T00:00:00Z');
            data.push('hourFrom='+ '0');
            data.push('hourTo=' + '24');
        } else {
            data.push('day='+ searchDate.format('YYYY-MM-DD') + 'T00:00:00Z');
            data.push('h_start='+ hourFrom);
            data.push('h_stop=' + hourTo);
        }
*/
        
        axios
        .get(process.env.REACT_APP_GEB_MIDDLEWARE + '/storico_posizione?' + data.join('&'),{
            headers: {
            'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            if(response.data.info.length > 0)
                dispatch(storicoSuccess(response.data.info))
            else {
                let data=[
                    "targa=" + targa,
                    "day=" + searchDate
                ]
                getVehicleLastPosition(dispatch, data)
            }
            //console.log("New Markers")          
        })
        .catch(function(error) {
            if (error.response) {
                console.log(error.response.headers);
                dispatch(storicoFailed(error.response.headers));
            } 
            else if (error.request) {
                console.log(error.request);
                dispatch(storicoFailed(error.request));
            } 
            else {
                console.log(error);
                dispatch(storicoFailed(error));
            }
        });
        
    }
    
}

function getVehicleLastPosition(dispatch, data) {
    console.log("getLastPosition...")
    axios
    .get(process.env.REACT_APP_GEB_MIDDLEWARE + '/storico_last_position?' + data.join('&'),{
        headers: {
        'Content-Type': 'application/json',
        }
    })
    .then((response) => {
        dispatch(storicoLastPosSuccess(response.data.info))
    })
    .catch(function(error) {
        if (error.response) {
            console.log(error.response.headers);
            dispatch(storicoLastPosFailed(error.response.headers));
        } 
        else if (error.request) {
            console.log(error.request);
            dispatch(storicoLastPosFailed(error.request));
        } 
        else {
            console.log(error);
            dispatch(storicoLastPosFailed(error));
        }
    });
}

export const storicoLastPosSuccess = (lastPositionMarker) => {
    return  {
        type: actionTypes.STORICO_LAST_POS_SUCCESS,
        lastPos: lastPositionMarker,
        bounds: calcMapBoundaries(lastPositionMarker)
    }
}

export const storicoLastPosFailed = (error) => {
    return {
        type: actionTypes.STORICO_LAST_POS_FAIL,
        error: error
    };
};