import React, { Fragment } from "react";


import MySideBar from './MySideBar';
import MyMap from './MyMap';

// Define our component. We will be using a functional component
// in this demo, but class-based works great as well:
const Percorsi = () => {

  return (

    <Fragment>
        <MySideBar/>
        <MyMap />
    </Fragment>
  );
};


export default Percorsi;