import React from 'react';

import Login from './Login'; 
import FirstAccess from './FirstAccess'; 

import { connect } from 'react-redux';


const Auth = (props) => {

    if(props.isFirstAccess) {
        //console.log("LOAD FIRST_ACCESS--------------------------------")
        return (
            <FirstAccess />
        );
    }else { 
        //console.log("LOAD LOGIN--------------------------------")
        return (
            <Login />
        );
    }
}

const mapStateToProps = state => {
    return {
        isFirstAccess: state.auth.token !== null && state.auth.isFirstAccess,
    }
}

export default connect(mapStateToProps, null) (Auth);