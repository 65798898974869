import React , { Fragment, useEffect, useState } from 'react';
import SideBar from './SideBar/SideBar'
import MyMap from './MyMap';
import { Modal } from 'react-bootstrap'
import { MdOutlineWarning } from 'react-icons/md'
import { FiLogOut } from 'react-icons/fi'


import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import NavigationItem from './../../components/Navigation/NavigationItems/NavigationItems';
import { Link } from 'react-router-dom'
import classes from './Posizioni.module.css';


// Define our component. We will be using a functional component
// in this demo, but class-based works great as well:
const Posizioni = (props) => {

    const [modalVisibility, setModalVisibility] = useState(true);

    useEffect(() => {
      if(props.role === 'cc' && !props.ccId) props.onGetCCInfo()
    },[props.ccId]);

    useEffect(() => {
        props.onGetUserPreferences()
    }, []);

    if(props.error == null)
        return (
        <Fragment>
            <SideBar/>
            <MyMap />
        </Fragment>
        );
    else {
        return(
        <Modal
            show={modalVisibility}
            backdrop="static" 
            keyboard={false}
        >
            <Modal.Header>
              <Modal.Title>
                  <MdOutlineWarning style={{color: 'orange', marginBottom: '5px'}}/>  &nbsp;Attenzione
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                C'è stato un problema di autenticazione.
                <br/><br/>
                <div>
                    Messaggio:&nbsp;&nbsp;
                    <span style={{}}><strong>{props.error}</strong></span>
                </div>
                
            </Modal.Body>
            <Modal.Footer>

              <NavigationItem link="/logout" title={"Log Out"}>
                <FiLogOut style={{color: "orange", fontSize: "1.4em"}} />
              </NavigationItem>

                <Link
                    title="LogOut"
                    to="/logout"
                >
                    <FiLogOut style={{color: "orange", fontSize: "1.4em"}} />
                </Link>
          </Modal.Footer>
        </Modal>
        )
    }

};

const mapStateToProps = state => {
    return {
        role: state.auth.tokenDecoded.role,
        ccId: state.movimenti.ccId,
        error: state.posizioni.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetCCInfo: () => dispatch(actions.getCCInfo()),
        onGetUserPreferences: () => dispatch(actions.getUserPreferences())
    };
};

export default connect ( mapStateToProps, mapDispatchToProps ) (Posizioni);