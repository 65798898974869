import * as actionTypes from '../actions/actionTypes';

let today = new Date();
let lastyear = new Date();

const initialState = {
    interventi: [],
    purchases: [],
    filterTarga: null,
    filterDateStart: lastyear.setFullYear( lastyear.getFullYear() - 1 ),
    filterDateEnd: today,
    filterIntervento: null,
    filterSorting: null,
    filterAuthorization: null,
    filterModello: null,
    filterCodeType: null,// usato per differenziare tra Interventi e Acquisti Gomme
    filterNumPratica: null,
    error: null,
    tipiInterventi: [],
    modifyModalVisibility: false,
    modifyIntervento: null, // selected intervento to modify when popup opens,
    //interventoFiles: [], // files of the selected intervento to modify when popup opens
    interventoData: null,
    modelliOptions: []
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.INTERVENTI_FETCH_TIPI_INTERVENTI_OK:
            return {
                ...state,
                tipiInterventi: action.tipiInterventi
            }
        case actionTypes.INTERVENTI_FETCH_TIPI_INTERVENTI_FAIL:
            return {
                ...state,
                error: action.error,
                tipiInterventi: []
            }
        case actionTypes.INTERVENTI_FILTER_SET_TARGA:
            return {
                ...state,
                filterTarga: action.filterTarga
            }
        case actionTypes.INTERVENTI_FILTER_SET_DATE_START:
            return {
                ...state,
                filterDateStart: action.filterDateStart
            }
        case actionTypes.INTERVENTI_FILTER_SET_DATE_END:
            return {
                ...state,
                filterDateEnd: action.filterDateEnd
            }
        case actionTypes.INTERVENTI_FILTER_SET_INTERVENTO:
            return {
                ...state,
                filterIntervento: action.filterIntervento
            }
        case actionTypes.INTERVENTI_FILTER_SET_SORTING:
            return {
                ...state,
                filterSorting: action.filterSorting
            }
        case actionTypes.INTERVENTI_FILTER_SET_AUTHORIZATION:
            return {
                ...state,
                filterAuthorization: action.filterAuthorization
            }
        case actionTypes.INTERVENTI_FILTER_SET_MODELLO:
            return {
                ...state,
                filterModello: action.filterModello
            }
        case actionTypes.INTERVENTI_FILTER_SET_CODETYPE:
            return {
                ...state,
                filterCodeType: action.filterCodeType
            }
        case actionTypes.INTERVENTI_FILTER_SET_NUM_PRATICA:
            return  {
                ...state,
                filterNumPratica: action.filterNumPratica
            }
        case actionTypes.INTERVENTI_FETCH_INTERVENTI_OK:
            return {
                ...state,
                interventi: action.interventi['interventi'],
                purchases: action.interventi['purchases'],
                error: null
            }
        case actionTypes.INTERVENTI_FETCH_INTERVENTI_FAIL:
            return {
                ...state,
                error: action.error,
                interventi: [],
                purchases: []
            }
        /**/
        case actionTypes.INTERVENTI_MODIFY_MODAL_VISIBILITY:
            return {
                ...state,
                modifyModalVisibility: action.modifyModalVisibility,
                modifyIntervento: action.modifyIntervento,
                //interventoFiles: action.interventoFiles
                interventoData: action.interventoData
            }
        case actionTypes.INTERVENTI_CHANGE_INTERVENTO_AUTHORIZATION:
            let tempInterventi = state.interventi
            tempInterventi.map(item => {
                if(item.logbook_id == action.logbook_id) {

                    const obj = item.logbook_extra
                    if(action.flag == true) {                        
                        obj.user_authorization = state.authorization.tokenDecoded.username;
                        obj.date_authorization = "asdasd"
                    }
                    else { // false
                        delete obj['user_authorization']
                        delete obj['date_authorization']
                    }
                    item.logbook_extra = obj
                    
                    return false;
                }
            })
            return {
                ...state,
                interventi: tempInterventi
            }
        case actionTypes.INTERVENTI_FETCH_MODELLI_OK:
            return {
                ...state,
                modelliOptions: action.modelliOptions,
                error: null
            }
        case actionTypes.INTERVENTI_FETCH_MODELLI_FAIL:
            return {
                ...state,
                error: action.error,
                modelliOptions: []
            }
        default:
            return state;
    }

}

export default reducer;