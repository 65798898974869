import React, {useState, useEffect, forwardRef, useRef} from 'react';
import { Col, Row, InputGroup, FormControl, Button, OverlayTrigger, Tooltip, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import Spinner from '../../components/UI/Spinner/Spinner'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';


import classes from './RefuelingsCheckbox.module.scss'

import * as actions from '../../store/actions/index';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import moment from 'moment'
import moment from 'moment-timezone'

import { getPetrolStationIcon } from '../../utils/petrolStationIcons'

import { FaArrowRight } from "react-icons/fa";
import { BsQuestionSquare } from "react-icons/bs";
import { FaCheckSquare } from "react-icons/fa";
import { ImCancelCircle } from 'react-icons/im'


//import axios from 'axios';


import it from "date-fns/locale/it";
registerLocale("it", it);



const RefuelingsCheckbox = (props) => {

    let d = new Date();

    const [searchStartDate, setSearchStartDate] = useState(new Date());
    const [searchEndDate, setSearchEndDate] = useState(new Date());
    const [km, setKm] = useState(0);
    const [importo, setImporto] = useState(0);
    const [gestore, setGestore] = useState(null);
    const [targa, setTarga] = useState(0);

    const [tempRefuelings, setTempRefuelings] = useState([])

    const processTargaInput = (e) => {
        const newVal = e.replace(/\s/g, '');
        return newVal
    }

    const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => (
        <button className={classes.refuel_date} onClick={onClick} ref={ref}>
        {value}
        </button>
    ));

    useEffect(() => {
        if(props.gestori == null) props.onGetGestori()
    }, [props.gestori]);

    useEffect(() => {
        if(!props.rifornimenti || props.rifornimenti.refuel.length === 0) {
            const tempStartDate = moment(props.cercaFilterDateStart).format('YYYY-MM-DD')
            const tempEndDate = moment(props.cercaFilterDateEnd).format('YYYY-MM-DD')
            props.onCercaRifornimenti(targa, km, importo, tempStartDate, tempEndDate, gestore);
        }
    }, [])

    const submitForm = (e) => {
        const tempStartDate = moment(props.cercaFilterDateStart).format('YYYY-MM-DD')
        const tempEndDate = moment(props.cercaFilterDateEnd).format('YYYY-MM-DD')
        props.onSetSpinner(true)
        props.onCercaRifornimenti(
            props.cercaFilterTarga ? props.cercaFilterTarga.value : 0,
            props.cercaFilterKm,
            props.cercaFilterImporto,
            moment(props.cercaFilterDateStart).format('YYYY-MM-DD'),
            moment(props.cercaFilterDateEnd).format('YYYY-MM-DD'),
            props.cercaFilterGestore ? props.cercaFilterGestore.value : 0
        );
    }


    const handleChangeTarga = (e) => {
        //setTarga(e.value)
        //console.log(e.value)

        let tempSelectedTarga = null
        //if (props.cercaFilterTarga) {

            tempSelectedTarga = props.selectOptionsTarghe.filter((item) => {
                if(item.value === e.value) return true;
                else return false;
            })
        //}
        //props.onSetCercaFilter('targa', e.value)
        props.onSetCercaFilter('targa', tempSelectedTarga[0]);
    }

    function checkValidity(name, value) {
        let isValid = true;
        let pattern = "";
        switch(name) {
            case 'km':
                pattern = /^\d*$/;
                isValid = pattern.test(value) && isValid                
                break;
            case 'importo':
                pattern = /^([0-9]*\.?[0-9]{0,2}|\.[0-9]+)$/;
                isValid = pattern.test(value) && isValid
                break;
            default:
                break;
        }
        return isValid
    }

    
    const handleChangeKm = (e) => {
        //if(e.target.value == '') setKm(0)
        //else setKm(e.target.value)

        if(e.target.value !== "") {
            
        }

        if (!checkValidity('km', e.target.value)) return false;
        //if(e.target.value == '') props.onSetCercaFilter('km', 0)
        //else props.onSetCercaFilter('km', e.target.value)
        props.onSetCercaFilter('km', e.target.value)
        //console.log(e.target.value)
    }
    const handleChangeImporto = (e) => {
        //setImporto(e.target.value)
        //console.log(e.target.value)
        if (!checkValidity('importo', e.target.value)) return false;
        //if(e.target.value == '') props.onSetCercaFilter('importo', 0)
        //else props.onSetCercaFilter('importo', e.target.value)
        props.onSetCercaFilter('importo', e.target.value)
    }
    const handleChangeDataInizio = (date) => {
        //setSearchStartDate(date)
        //console.log(date)
        props.onSetCercaFilter('data_inizio', date)
        props.onSetCercaFilter('data_fine', date)

    }
    const handleChangeDataFine = (date) => {
        //setSearchEndDate(date)
        //console.log(date)
        props.onSetCercaFilter('data_fine', date)
    }
    const handleChangeGestore= (e) => {
        //setGestore(e.value)
        //console.log(e.value)
        //props.onSetCercaGestore('gestore', e.value)

        let tempSelectedGestore = null
        tempSelectedGestore = props.gestori.filter((item) => {
            if(item.value === e.value) return true;
            else return false;
        })
        props.onSetCercaFilter('gestore', tempSelectedGestore[0]);
    }

    const resetForm = (e) => {
        console.log("RESETTING...")
        props.onSetCercaFilter('gestore', {label: "Qualsiasi Gestore", value: 0})
        props.onSetCercaFilter('targa', {label: "Qualsiasi Targa", value: 0})
        //props.onSetCercaFilter('targa', 0)
        props.onSetCercaFilter('importo', '')
        props.onSetCercaFilter('km', '')

        let d = new Date();
        props.onSetCercaFilter('data_inizio', d.setDate(0))
        props.onSetCercaFilter('data_fine', d)

    }

    return (
        <div style={{marginTop: '1rem'}}>
            <div>
                <div className={classes.filters}>
                    <div class="row">

                        <div class="filter col-2">
                                <InputGroup className="mb-2">
                                    <InputGroup.Text id="inputGroup-sizing-sm">Importo (€)</InputGroup.Text>
                                    <FormControl
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-sm"
                                    onChange={(e) => handleChangeImporto(e)}
                                    value={props.cercaFilterImporto}
                                    size="4"
                                    />
                                </InputGroup>
                        </div>
                        <div class="filter col-2">
                                <InputGroup className="mb-2" style={{flexWrap: "nowrap"}}>
                                    <InputGroup.Text id="inputGroup-sizing-sm">Data Inizio</InputGroup.Text>
                                    <DatePicker 
                                        locale="it"
                                        //selected={searchStartDate} 
                                        selected={props.cercaFilterDateStart} 
                                        dateFormat='dd/MM/yyyy'
                                        style={{textAlign: "center"}}
                                        customInput={<CustomDatePickerInput />}
                                        onChange={(date) => handleChangeDataInizio(date)}
                                        value={props.cercaFilterDateStart}
                                    />
                                </InputGroup>
                        </div>
                        <div class="filter col-2">
                                <InputGroup className="mb-2" style={{flexWrap: "nowrap"}}>
                                    <InputGroup.Text id="inputGroup-sizing-sm">Data Fine</InputGroup.Text>
                                    <DatePicker 
                                        locale="it"
                                        //selected={searchEndDate} 
                                        selected={props.cercaFilterDateEnd} 
                                        dateFormat='dd/MM/yyyy'
                                        style={{textAlign: "center"}}
                                        customInput={<CustomDatePickerInput />}
                                        onChange={(date) => handleChangeDataFine(date)}
                                        //value={props.cercaFilterDateEnd}
                                    />
                                </InputGroup>
                        </div>
                        <div class="filter col-2" >
                                <Select className="mb-2"
                                    placeholder="Qualsiasi Gestore"
                                    options={props.gestori}
                                    onChange={(e) => handleChangeGestore(e)}
                                    value={props.cercaFilterGestore}
                                />
                        </div>
                        <div class="filter col-2">
                                <InputGroup className="mb-2">
                                    <InputGroup.Text id="inputGroup-sizing-sm">Km</InputGroup.Text>
                                    <FormControl
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-sm"
                                    onChange={(e) => handleChangeKm(e)}
                                    value={props.cercaFilterKm}
                                    />
                                </InputGroup>
                        </div>
                        <div class="filter col-2">
                                <Select className="mb-2"
                                    placeholder="Qualsiasi Targa"
                                    options={props.selectOptionsTarghe}
                                    onChange={(e) => handleChangeTarga(e)}
                                    onInputChange={(e) => processTargaInput(e)}
                                    value={props.cercaFilterTarga}
                                />
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-3">
                            <div className={classes.RisultatiCounter}>
                                RISULTATI:&nbsp;<span className={classes.RisultatiCounterValue}>{ props.rifornimenti && props.rifornimenti.refuel ? props.rifornimenti.refuel.length : '0'} righe</span>
                            </div>
                        </div>

                        <div class="col-3 offset-6">
                            <div>
                                <div class="d-flex flex-row-reverse">
                                    <div class="mt-3">
                                        <Button
                                            variant="outline-secondary"
                                            onClick={(e) => resetForm(e)}
                                        >
                                            Reset Filtri
                                        </Button>
                                    </div>
                                    <div class="mt-3 mr-3">
                                        <Button
                                            type="submit"
                                            variant="outline-primary"
                                            onClick={(e) => submitForm(e)}
                                        >
                                            Invia
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {(!props.spinner) ? 
                    (
                    (props.rifornimenti) ? 
                        props.rifornimenti.refuel.map(item => {
                            return (
                                <RifRow 
                                    data={item} 
                                    carta_carburanti={props.rifornimenti.carta_carburanti} 
                                    onSetManualCheckbox={props.onSetManualCheckbox} 
                                    onSetSuspictiousCheckbox={props.onSetSuspictiousCheckbox}
                                    onSaveSpecificLiters={props.onSaveSpecificLiters}
                                />
                            );
                        })
                        : 
                        ''
                    ) : <Spinner />
                }
            </div>


        </div>
    )
}


const RifRow = (props) => {
    const vehicleRefuel = props.data
    //console.log(vehicleRefuel)

    const linkGmaps = "http://maps.google.com/maps?q=" + vehicleRefuel.car_lat + "," + vehicleRefuel.car_lon

    const [automatic, setAutomatic] = useState(false);
    const [manual, setManual] = useState(false);
    const [suspictious, setSuspictious] = useState(false);
    const [showSpecificLitersModal, setShowSpecificLitersModal] = useState(false);
    const [ specificLiters, setSpecificLiters ] = useState(null);
    const [ sLHasChanged, setSLHasChanged] = useState(false);

    //const innerRef = useRef();

    const isAutomatic = () => {
        props.carta_carburanti.some((item) => {
            if(item.refuel_id === vehicleRefuel.refuel_id) {
                //console.log("AUTOMATICO_FOUND: ", item.refuel_id, vehicleRefuel.refuel_id)
                setAutomatic(true)
            }
        });
    }

    const isManual = () => {
        if(vehicleRefuel.stato === 'm') setManual(true)
    }

    const isSuspictious = () => {
        if(vehicleRefuel.rifornimento_sospetto && vehicleRefuel.rifornimento_sospetto === true) setSuspictious(true)
    }

    useEffect(() => {
        console.log("ddd")
        if(vehicleRefuel.refuel_id == 11095) console.log(vehicleRefuel.litri_dichiarati)
        isAutomatic()
        isManual()
        isSuspictious()
        //if(vehicleRefuel.litri_dichiarati)
        setSpecificLiters(vehicleRefuel.litri_dichiarati);
        
    }, [])

    const changeManualCheckbox = (e, vR) => {
        //console.log(e.target.checked)
        props.onSetManualCheckbox(vR, e.target.checked)
        setManual(e.target.checked)
        
        //props.onSetManualCheckbox(vehicle)
    }

    const changeSuspictiousCheckbox = (e, vR) => {
        props.onSetSuspictiousCheckbox(vR, e.target.checked)
        setSuspictious(e.target.checked)
    }

    const openSpecificLitersModal = (sL) => {
        if(sL) setSpecificLiters(sL)
        setShowSpecificLitersModal(true)
        //console.log(innerRef)
        //innerRef.current && innerRef.current.focus()
        
    }

    const saveSpecificLiters = () => {
        if(specificLiters) {
            props.onSaveSpecificLiters(vehicleRefuel, specificLiters)
            setShowSpecificLitersModal(false)
        }
    }

    const handleSpecificLitersChanged = (e) => {
        //console.log(e)
        let isValid = true;
        let pattern = /^([0-9]*\.?[0-9]{0,2}|\.[0-9]+)$/;
        isValid = pattern.test(e.target.value) && isValid;
        console.log(e.target.value)
        //console.log(isValid)
        if(isValid) {
            console.log("IS VALID")
            setSpecificLiters(e.target.value);
            setSLHasChanged(true);
        }
    }

    const closeSpecificLitersModal = () => {
        setShowSpecificLitersModal(false)
    }

    const resetSpecificLiters = () => {
        props.onSaveSpecificLiters(vehicleRefuel, 0)
        setSpecificLiters(null)
        setShowSpecificLitersModal(false)
    }

    const handleSLButton = (buttonId) => {
        if(buttonId === 1) { //Accept
            console.log("1")
            console.log(specificLiters)
            props.onSaveSpecificLiters(vehicleRefuel, specificLiters)
        }
        else { // Reset
            console.log("2")    
            props.onSaveSpecificLiters(vehicleRefuel, 0)
            setSpecificLiters('')
        }
        setSLHasChanged(false);

    }

    let tempDate = moment(vehicleRefuel.data_fine);
    
    return (
        <div key={props.refuel_id} style={{border: '1px solid #ced4da', padding: '5px', margin: '0px 20px 10px 20px'}}>
            <Row>
                <Col md="3">
                    <Row><Col><div className={classes.targa}>{vehicleRefuel.show_targa}</div></Col></Row>
                    <Row>
                        <Col>
                            <p>Modello: {vehicleRefuel.modello}</p>
                            <p>Codice mezzo:  {vehicleRefuel.codice_mezzo}</p>
                            <p>Capo cantiere:  {vehicleRefuel.nome + ' ' + vehicleRefuel.cognome}</p>
                            <p>
                                Responsabile:  {vehicleRefuel.responsabile ? vehicleRefuel.responsabile : '-'}
                            </p>
                            {vehicleRefuel.fuel_station_distance > process.env.REACT_APP_LIMITE_DISTANZA_DAL_DISTRIBUTORE ? 
                                <></>
                                :
                                <p>
                                    <span style={{display: 'flex', gap: '25px'}}>
                                    
                                    <Link
                                        title="Dettaglio giorno"
                                        to={"/refuelings/"+ vehicleRefuel.device_id + '/' + vehicleRefuel.data_fine}
                                    >
                                        Dettaglio giorno
                                    </Link>
                                    </span>
                                </p>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col md="4">
                    <div style={{fontSize: '20px', marginBottom: '20px', borderBottom: '1px solid #e7e7e7'}}>Rifornimento (n. {vehicleRefuel.refuel_id})</div>
                    <Row>
                        <Col md="8">
                            <div>
                                <p>Data/ora: {tempDate.tz('Europe/Rome').format("D MMM yyyy HH:mm")}</p>
                                <p>Km: <NumberFormat value={Math.round(vehicleRefuel.km_start)} displayType={'text'} thousandSeparator={true} /> Km</p>
                                <p>Litri inseriti: {vehicleRefuel.fuel_diff_litri} lt</p>
                                <p>
                                    Litri dichiarati: &nbsp;
                                    <input  
                                        type="text"
                                        name="specific_ltr" 
                                        value={specificLiters}
                                        onChange={(e) => handleSpecificLitersChanged(e)}
                                        size="4"
                                    />
                                    <span style={{margin: "0px 10px"}}>lt</span>
                                        { sLHasChanged ? 
                                            <>
                                                <OverlayTrigger
                                                    key={'top'}
                                                    placement={'top'}
                                                    overlay={
                                                        <Tooltip id={`tooltip-${'top'}`}>
                                                            Conferma
                                                        </Tooltip>
                                                    }
                                                >
                                                        <Button 
                                                        variant="outline-primary" 
                                                        style={{padding: "0px 4px 2px 4px"}}
                                                        size="sm"
                                                        onClick={(e) => handleSLButton(1)}
                                                    >
                                                        <FaCheckSquare />
                                                    </Button>
                                                </OverlayTrigger>
                                            </>
                                            :
                                            <></>
                                        }
                                        { specificLiters ? 
                                            <>
                                                <span style={{margin: "0px 3px"}}></span>
                                            
                                                <OverlayTrigger
                                                    key={'top'}
                                                    placement={'top'}
                                                    overlay={
                                                        <Tooltip id={`tooltip-${'top'}`}>
                                                            Reseta
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button 
                                                        variant="outline-secondary"
                                                        style={{padding: "0px 4px 2px 4px"}}
                                                        size="sm"
                                                        onClick={(e) => handleSLButton(2)}
                                                    >
                                                        <ImCancelCircle />
                                                    </Button>
                                                </OverlayTrigger>
                                            </>
                                            :
                                            <></>
                                        }
                                </p>
                                {/*
                                <p>
                                    Litri dichiarati: &nbsp;
                                    <Button variant="outline-info" style={{padding: "0px 6px"}} onClick={(e) => openSpecificLitersModal(vehicleRefuel.litri_dichiarati)}>
                                        {
                                            vehicleRefuel.litri_dichiarati || vehicleRefuel.litri_dichiarati !== "" ? vehicleRefuel.litri_dichiarati : '-'
                                        }
                                    </Button>
                                    &nbsp;lt
                                </p>
                                */}
                            </div>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                SERBATOIO
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <div 
                                    style={{width:"76px", marginTop: '7px', marginLeft: '10px'}}
                                    title={vehicleRefuel.fuel_start + ' % a ' + vehicleRefuel.fuel_stop + ' %'}
                                >
                                    <div className={classes.fuel_bar}> 
                                        <div 
                                        className={classes.mask}
                                        style={{ 
                                            position: 'absolute', width: 'calc(100% - 4px)', 
                                            overflow: 'hidden', transform: 'rotate(180deg)', 
                                            bottom: '0', left: '2px', 
                                            height: vehicleRefuel.fuel_start 
                                        }}
                                        >
                                        <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'red'}}></div>
                                        <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        </div>
                                    </div>
                                    <div 
                                        class="fuel_bar_arrow" 
                                        style={{
                                        display: 'inline-block',
                                        verticalAlign: 'top',
                                        lineHeight: '106px',
                                        fontWeight: 'bold'
                                        }}
                                    >
                                        <FaArrowRight />
                                    </div>
                                    <div className={classes.fuel_bar}> 
                                        <div 
                                        className={classes.mask}
                                        style={{ 
                                            position: 'absolute', width: 'calc(100% - 4px)', 
                                            overflow: 'hidden', transform: 'rotate(180deg)', 
                                            bottom: '0', left: '2px', 
                                            height: vehicleRefuel.fuel_stop
                                        }}
                                        >
                                        <div className={classes.tacca} style={{height: '10px', marginTop: '1px', backgroundColor: 'red'}}></div>
                                        <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '10px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '9px', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        <div className={classes.tacca} style={{height: '9px  ', marginTop: '2px', backgroundColor: 'green'}}></div>
                                        </div>
                                    </div>  

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                    {Math.round((vehicleRefuel.fuel_start*vehicleRefuel.serbatoio/100) * 10) / 10} lt
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    {Math.round((vehicleRefuel.fuel_stop*vehicleRefuel.serbatoio/100) * 10) / 10} lt
                                
                                
                                
                            </Col>
                        </Row>

                        </Col>
                    </Row>
                </Col>
                <Col md="2">
                    <div style={{fontSize: '20px', marginBottom: '20px', borderBottom: '1px solid #e7e7e7'}}>Stazione di Servizio</div>
                    
                    {vehicleRefuel.fuel_station_distance > process.env.REACT_APP_LIMITE_DISTANZA_DAL_DISTRIBUTORE ? 
                        <>
                            <p> 
                                <Row>
                                    <Col md="3">
                                        <div>Gestore:</div>
                                    </Col>
                                    <Col>
                                        <div style={{fontSize: "25px", position: "absolute", top: '-10px'}}>
                                            <OverlayTrigger
                                                key={'top'}
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip id={`tooltip-${'top'}`}>
                                                        {'Distanza dalla Stazione più vicina: ' + vehicleRefuel.fuel_station_distance + ' m'}
                                                    </Tooltip>
                                                }
                                            >
                                                    <BsQuestionSquare />
                                            </OverlayTrigger>
                                        </div>
                                    </Col>
                                </Row>
                            </p>
                        </>
                    :
                        <>
                            <p>
                                <Row>
                                    <Col md="4">
                                        <div style={{paddingTop: '20px'}}>Gestore:</div>
                                    </Col>
                                    <Col>

                                        <div style={{ width: "60px", border: "1px solid #ccc", radius: "5px", padding: "5px" }} >
                                            <OverlayTrigger
                                                key={'top'}
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip id={`tooltip-${'top'}`}>
                                                        {vehicleRefuel.gestore ? vehicleRefuel.gestore : 'Gestore non disponibile'}
                                                    </Tooltip>
                                                }
                                            >
                                                <img 
                                                    src={getPetrolStationIcon(vehicleRefuel.gestore.toLowerCase())}
                                                    width="50"
                                                    height="50"
                                                    alt={vehicleRefuel.gestore}
                                                />
                                            </OverlayTrigger>

                                        </div>
                                    
                                    </Col>
                                </Row>
                            </p>
                            <p>Indirizzo: {vehicleRefuel.indirizzo}</p>
                        </>
                    }
                </Col>

                <Col md="2">
                    <div style={{fontSize: '20px', marginBottom: '20px', borderBottom: '1px solid #e7e7e7'}}>Funzioni</div>
                    <p><a href={linkGmaps} target="_blank">Posizione Veicolo</a></p>
                    <p>
                        <Form.Group className="mb-3" controlId={"manualCheckbox_"+ vehicleRefuel.refuel_id}>
                            <Form.Check 
                                type="checkbox" 
                                label="Verifica Manuale" 
                                name={vehicleRefuel.ref_code} 
                                value="m" type="checkbox" 
                                checked={manual}
                                onChange={(e) => changeManualCheckbox(e, vehicleRefuel)}
                                disabled={automatic ? true : false}
                            />
                        </Form.Group>
                    </p>
                    <p>
                        <Form.Group className="mb-3" controlId={"automaticCheckbox_"+ vehicleRefuel.refuel_id}>
                            <Form.Check 
                                type="checkbox" 
                                readOnly={true}
                                label="Verifica Automatica" 
                                name={vehicleRefuel.ref_code} 
                                value="v" 
                                type="checkbox" 
                                checked={automatic} 
                            />
                        </Form.Group>
                    </p>
                    <p>
                        <Form.Group className="mb-3" controlId={"suspictiousCheckbox_"+ vehicleRefuel.refuel_id}>
                            <Form.Check 
                                type="checkbox" 
                                label="Rifornimento Sospetto" 
                                name={vehicleRefuel.ref_code} 
                                value="m" type="checkbox" 
                                checked={suspictious}
                                onChange={(e) => changeSuspictiousCheckbox(e, vehicleRefuel)}
                            />
                        </Form.Group>
                    </p>
                </Col>

                
    
            </Row>
            {/* */}
            <Modal 
                show={showSpecificLitersModal} 
                onHide={(e) => closeSpecificLitersModal()} 
                backdrop="static" 
                keyboard={false}
                animation={false}
            >
                <Modal.Header>Modifica Litri Specifici Dichiarati</Modal.Header>
                <Modal.Body>
                    
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        
                        <Form.Label column sm="4">
                        Litri dichiarati:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control 
                                autoFocus 
                                type="text"
                                name="specific_ltr" 
                                value={specificLiters}
                                onChange={(e) => handleSpecificLitersChanged(e)}
                                //ref={innerRef}
                                size="sm"
                                
                            />
                        </Col>
                        <Form.Label column sm="4">
                        &nbsp; lt
                        </Form.Label>
                        
                    </Form.Group>

                   
                </Modal.Body>
                <Modal.Footer>
                    <button class="btn btn-secondary" onClick={(e) => closeSpecificLitersModal()}>
                        Chiudi
                    </button>
                    <button class="btn btn-primary" onClick={() => saveSpecificLiters()}>
                        Salva
                    </button>
                    <button class="btn btn-primary" onClick={() => resetSpecificLiters()}>
                        Reset
                    </button>
                </Modal.Footer>
            </Modal>
            
        </div>
    );
        
}



const mapStateToProps = state => {
    const options = []
    options.push({value:0, label: "Qualsiasi Targa"})
    state.posizioni.selectOptions.map((item) => {

        options.push({
            value: item.value,
            label: item.label
        })
        return false;
    });

    return {
        tokenDecoded: state.auth.tokenDecoded,
        selectOptionsTarghe: options,
        spinner: state.refuelings.spinner,
        //selectOptionsCodiceMezzo: state.refuelingsCheckbox.selectOptions
        gestori: state.refuelings.gestori,
        rifornimenti: state.refuelings.cercaRif,
        cercaFilterTarga: state.refuelings.cercaFilterTarga,
        cercaFilterDateStart: state.refuelings.cercaFilterDateStart,
        cercaFilterDateEnd: state.refuelings.cercaFilterDateEnd,
        cercaFilterKm: state.refuelings.cercaFilterKm,
        cercaFilterImporto: state.refuelings.cercaFilterImporto,
        cercaFilterGestore: state.refuelings.cercaFilterGestore       
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetSpinner: (spinner) => dispatch(actions.setSpinnerRefuel(spinner)),
        onGetGestori: () => dispatch(actions.getGestori()),
        onCercaRifornimenti: (targa, km, importo, dataStart, dataEnd, gestore) => dispatch(actions.cercaRifornimenti(targa, km, importo, dataStart, dataEnd, gestore)),
        onSetManualCheckbox: (vR, checked) => dispatch(actions.setManualCheckbox(vR, checked)),
        onSetCercaFilter: (cercaFilterRifName, cercaFilterRifValue) => dispatch(actions.setCercaFilter(cercaFilterRifName, cercaFilterRifValue)),
        onSetSuspictiousCheckbox: (vR, checked) => dispatch(actions.setSuspictiousCheckbox(vR, checked)),
        onSaveSpecificLiters:(vR, sL) => dispatch(actions.saveSpecificLiters(vR, sL))
    };
};
export default connect ( mapStateToProps, mapDispatchToProps ) (RefuelingsCheckbox);
