import React, {useState, useRef} from 'react'
import { connect } from 'react-redux'
import { Table, Button, Modal, FormControl, Form} from 'react-bootstrap'
import { FaFolderOpen, FaEdit } from 'react-icons/fa'
import { FaFileDownload } from "react-icons/fa";

import classes from './InterventiTable.module.css'

import * as actions from '../../store/actions/index';

import axios from 'axios';

import Moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/it';

//import InterventiModifyModal from './InterventiModifyModal'
import InterventiModal from './InterventiModal'
//import MechanicLogBookModal from '../MechanicLogBookModal/MechanicLogBookModal'


export const InterventiTable = (props) => {

  const [ showModal, setShowModal ] = useState(false)
  const [ showModifyModal, setShowModifyModal ] = useState(false)
  const [ interventoFiles, setInterventoFiles ] = useState([])
  const [ interventoData, setInterventoData ] = useState({})

  const refInterventiArray = useRef([]);

  const openModal = async (e, item) => {
    let device_id = 0
    await axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/interventoFiles/' + item.logbook_id, {
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        if(res.data.retCode === "000") {
            setInterventoFiles(res.data.info)
            setShowModal(true)
        }
    }).catch(err => {
        console.error('/operations request fail: ' + err);
        setShowModal(false)
    });  }

  const findInterventoDescription = (id) => {
    const selectedIntervento = props.tipiInterventi.filter(intervento => intervento.operazione_id == id)[0]
    return selectedIntervento ? selectedIntervento.descrizione_operazione : ''
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const closeModifyModal = () => {
    setShowModifyModal(false)
  }

  const downloadFile = async (device_id, doc_type, file) => {

    axios({
        url: process.env.REACT_APP_GEB_MIDDLEWARE + '/fat_download/' + device_id + '/' + doc_type,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', doc_type + '_' + file.filename); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch(err => {
        console.error('/files request fail: ' + err);
    });

  }

  /*
  const openModifyModal = async (e, item) => {
    await axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/interventoFiles/' + item.logbook_id, {
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        if(res.data.retCode === "000") {
            props.onSetModifyModalVisibility(true, item, res.data.info)
        }
    }).catch(err => {
        console.error('/operations request fail: ' + err);
        props.onSetModifyModalVisibility(false, null, [])
    });
  }
  */

  const openModifyModal = async (e, item) => {
    await axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/interventoData/' + item.logbook_id, {
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        if(res.data.retCode === "000") {
            props.onSetModifyModalVisibility(true, item, res.data.info)
        }
    }).catch(err => {
        console.error('/operations request fail: ' + err);
        props.onSetModifyModalVisibility(false, null, {})
    });
  }

  const handleCheckboxChange = async (e, item) => {

    console.log(e.target.checked)
    const isChecked = e.target.checked
    console.log(item)
    const params = new URLSearchParams();
    params.append('isAuthorized', e.target.checked)
    await axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/authorizeIntervento/' + item.logbook_id, {isAuthorized: isChecked}, {
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        if(res.data.retCode === "000") {
          props.onChangeInterventoAuthorization(item, isChecked);
          refInterventiArray.current[item.logbook_id].checked = isChecked;

        }
    }).catch(err => {
        console.error('/operations request fail: ' + err);
    });
  }

  const ischecked = (item) => {
    if(item.logbook_extra)
      if(item.logbook_extra.date_authorization)
        return true;

    return false;
  }

  return (
    <div className={classes.fixTableHead}>


      <Table>
        <thead style={{textAlign:'center'}}>
            <tr>
              <th width={100} style={{backgroundColor: "#f5faff", zIndex: '99'}}>Targa</th>
              <th width={200}>Data Inserimento</th>
              <th width={150}>Numero Pratica</th>
              <th width={200}>Tipo Intervento</th>
              <th width={150}>Importo (€)</th>
              <th width={150}>Fornitore</th>
              <th>Descrizione</th>
              {/*<th width={200}>Data Autorizazione</th>
              <th width={50}>Autorizzato</th>*/}
              <th>Azioni</th>
            </tr>
        </thead>
        <tbody>
          {props.interventi.map((item, index) => {
            return (
              <tr key={index}>
                <td style={{textAlign:'left'}}>
                  <div className={classes.targa}>{item.show_targa}</div>
                </td>
                <td >
                    {
                      Moment(item.logbook_ts).format('D MMM yyyy, HH:mm')
                    }
                </td>
                <td>
                  {
                    item.logbook_extra.num_pratica ? 
                    item.logbook_extra.num_pratica
                    :
                    <span className={classes.nd}>n/d</span>
                  }
                </td>

                <td>
                  {item.operazione_id? 
                    findInterventoDescription( item.operazione_id)
                    : '-'
                  }
                </td>
                <td>
                  {item.importo}
                </td>
                <td>
                  {item.logbook_extra.fornitore != 0 ? item.logbook_extra.fornitore : '-'}
                </td>
                {/*<td>
                  <Button 
                    size='sm' 
                    onClick={(e) => openModal(e, item)}
                    
                    >
                    <FaFolderOpen />
                </Button>
                </td>
                */}
                <td>{item.descrizione.toLowerCase()}</td>
                <td>
                  {
                    item.logbook_extra.date_authorization ?
                    Moment(item.logbook_extra.date_authorization).format('D MMM yyyy', 'HH:mm')
                    :
                    <span className={classes.nd}>n/d</span>
                  }
                </td>
                {/*
                <td>
                  <div>
                    <input
                      type="checkbox" 
                      ref={ref => {refInterventiArray.current[item.logbook_id] = ref}}
                      checked={ischecked(item)}
                      onChange={(e) => handleCheckboxChange(e, item)}
                      disabled={props.role === 'am' ? false : true}
                    ></input>
                  </div>
                  
                </td>
                <td>
                  {!item.logbook_extra.num_pratica && item.operazione_id.trim() != "" ?
                    <Button 
                      size='sm' 
                      onClick={(e) => openModifyModal(e, item)}
                      >
                        <FaEdit />
                    </Button>
                    :
                    <></>
                  }
                </td>
                */}
              </tr>
            )
          })}
        </tbody>

      </Table>

      <Modal
        show={showModal}
        backdrop="static" 
        keyboard={false}
        size="lg"
        >
          <Modal.Header>
              <Modal.Title>
                  File Intervento
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Table>
              <thead style={{textAlign:'center'}}>
                <tr>
                  <td>Nome</td>
                  <td>Formato</td>
                  <td>Azioni</td>
                </tr>
              </thead>
              <tbody style={{textAlign:'left'}}>
                {interventoData && interventoData.files ?
                  interventoData.files.map((file, index) => {
                  return <tr key={index}>
                    <td>{file.filename}</td>
                    <td>{file.mime_type}</td>
                    <td>
                      <button 
                          style={{ padding: "0px 5px" }} 
                          class="btn btn-primary"
                          onClick={() => downloadFile(file.fat_extra.device_id, 'intervento', file)}
                      >
                          <FaFileDownload/>
                      </button>
                    </td>
                  </tr>
                  })
                  : <></>
                }
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
              <button class="btn btn-secondary" onClick={() => closeModal()}>
                  Chiudi
              </button>
          </Modal.Footer>
        </Modal>

        {/*<InterventiModifyModal />*/}
        <InterventiModal />

        {/*
        <Modal
        show={showModifyModal}
        backdrop="static" 
        keyboard={false}
        size="lg"
        >
          <Modal.Header>
              <Modal.Title>
                  Modifica Intervento
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Table>
              <thead style={{textAlign:'center'}}>
                <tr>
                  <td>Nome</td>
                  <td>Formato</td>
                  <td>Azioni</td>
                </tr>
              </thead>
              <tbody style={{textAlign:'left'}}>
                {interventoFiles.map((file, index) => {
                  return <tr key={index}>
                    <td>{file.filename}</td>
                    <td>{file.mime_type}</td>
                    <td>
                      <button 
                          style={{ padding: "0px 5px" }} 
                          class="btn btn-primary"
                          onClick={() => downloadFile(file.fat_extra.device_id, 'intervento', file)}
                      >
                          <FaFileDownload/>
                      </button>
                    </td>
                  </tr>
                })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
              <button class="btn btn-secondary" onClick={() => closeModifyModal()}>
                  Chiudi
              </button>
          </Modal.Footer>
        </Modal>
        */}
                            
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    interventi: state.interventi.interventi,
    tipiInterventi: state.interventi.tipiInterventi,
    //username: state.auth.tokenDecoded.username,
    role: state.auth.tokenDecoded.role,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetModifyModalVisibility: (modifyModalVisibility, selectedIntervento, interventoData) => dispatch(actions.setModifyModalVisibility(modifyModalVisibility, selectedIntervento, interventoData)),
  //onSetMechLogBookModalVisibility(true, d)
    onChangeInterventoAuthorization: (item, flag) => dispatch(actions.changeInterventoAuthorization(item, flag))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InterventiTable)