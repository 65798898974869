import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Table, Row, Col, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { FaFileUpload, FaCheck, FaFolderOpen } from "react-icons/fa";
import { BiErrorCircle } from "react-icons/bi";
import { SpinningCircles } from 'react-loading-icons'

import { formatBytes } from '../../utils/utils'


export default function FileUploader(props) {

  const userRole = props.tokenDecoded.role

  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [fileIsSent, setFileIsSent] = useState(false);

  const [description, setDescription] = useState('');

  const hiddenFileInput = useRef(null);

  const options = { year:"numeric",month:"2-digit", day:"2-digit" };

  useEffect(() => {
    if (props.doc_type === 'libretto')
      setDescription('Libretto circolazione')
    else if (props.doc_type === 'assicurazione') {
      const myYear = new Date().getFullYear()
      setDescription('Assicurazione ' + myYear )
    }
  },[]);


  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
    setError();
    setFileIsSent(false);
  };

  const upadteDescription = (e) => {
    setDescription(e.target.value)
  }

  const handleSubmission = (e) => {
    setLoading(true)
    setFileIsSent(true)
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("device_id", props.selectedDevice);
    formData.append("doc_type", props.doc_type);
    formData.append("fat_description", description);
    formData.append("multi_filename", false);
    formData.append("multi_extra", false);
    axios.post(
        process.env.REACT_APP_GEB_MIDDLEWARE + '/fat_upload',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    ).then(response => {
        if(response.data.retCode === "000") {
            
        } else {
            setError(response.data.errorMessage)
        }
        setLoading(false);
    })
    .catch(err => {
        console.log(err);
        //dispatch(fileUploadedFail(err));
        setError(err)
    })



  }

  return (
    <>
      { ['am', 'sv'].indexOf(userRole) > -1 ?
      <Card>
        <Card.Header>
          Caricamento File
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
                <div style={{flex:'row', flexDirection: 'row'}}>
                  <span>Descrizione:</span>

                  <input 
                    type="text"
                    size={40}
                    style={{marginLeft: "10px" }}
                    onChange={(e) => upadteDescription(e)}
                    value={description}
                  />
                </div>
            </Col>
            <Col xs={2}>
              <div>
                <Button 
                  size="sm"
                  onClick={handleClick}
                >
                  <FaFolderOpen />
                </Button>
                <input type="file"
                  ref={hiddenFileInput}
                  onChange={changeHandler}
                  style={{display:'none'}} 
                /> 
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              

              {isFilePicked ? (
                <Table striped bordered hover size="sm" style={{marginTop: '20px'}}>
                  <thead>
                      <tr>
                          <th>Nome File</th>
                          <th>Formato</th>
                          <th>Dimensione</th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{selectedFile.name}</td>
                      <td>{selectedFile.type}</td>
                      <td>{formatBytes(selectedFile.size)}</td>
                      <td>

                        {loading? 
                          <Button 
                            size="sm" 
                          >
                            Inviando...&nbsp;
                            <SpinningCircles 
                              height={'1em'}
                              width={'1em'}
                            />
                          </Button>
                        : error ? 
                          <OverlayTrigger
                            key={'top'}
                            placement={'right'}
                            overlay={
                                <Tooltip id={`tooltip-${'top'}`}>
                                  {error}
                                </Tooltip>
                            }
                          >
                            <BiErrorCircle fill='red' />
                          </OverlayTrigger>                             
                          : fileIsSent ?
                            <OverlayTrigger
                              key={'top'}
                              placement={'right'}
                              overlay={
                                  <Tooltip id={`tooltip-${'top'}`}>
                                    'File Salvato'
                                  </Tooltip>
                              }
                            >
                              <FaCheck fill='green' />
                            </OverlayTrigger>                              
                          :
                            <Button 
                              size="sm" 
                              onClick={(e) => handleSubmission(e)}
                            >
                              Invia File &nbsp;
                              <FaFileUpload style={{marginTop: '-4px'}}/>
                            </Button>
                        }
    
                      </td>
                    </tr>
                  </tbody>
                </Table>
                
              ) : (
                  <></>
              )}

            </Col>
          </Row>

          
        </Card.Body>
      </Card>
      :
      <></>
      }
    </>
  );
}
