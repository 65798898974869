import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux'
import classes from './Layout.module.css';
import Toolbar from '../Navigation/Toolbar/Toolbar';
import SideDrawer from '../Navigation/SideDrawer/SideDrawer';
import Notificator from '../Notificator/Notificator.js';
import Scadenze from '../Scadenze/Scadenze.js';

class Layout extends Component {
    state = {
        showSideDrawer: false
    }

    sideDrawerClosedHandler = () => {
        this.setState( { showSideDrawer: false } );
    }

    sideDrawerToggleHandler = () => {
        this.setState( ( prevState ) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        } );
    }

    render () {
        return (
            <Fragment>

                <Toolbar
                    isAuth={this.props.isAuthenticated}
                    drawerToggleClicked={this.sideDrawerToggleHandler}
                    userEmail={this.props.userEmail}
                />

                <Notificator />
                <Scadenze />

                <SideDrawer
                    isAuth={this.props.isAuthenticated}
                    open={this.state.showSideDrawer}
                    closed={this.sideDrawerClosedHandler} />
                <main className={this.props.isAuthenticated ? classes.ContentAuthenticated : classes.Content}>
                    {this.props.children}
                </main>

            </Fragment>
        )
    }
}
const mapStateToProps = state => {
    //console.log(state.auth.tokenDecoded.username)
    return {
        isAuthenticated: state.auth.token !== null  && !state.auth.isFirstAccess,
        userEmail: (state.auth.token !== null) ? state.auth.tokenDecoded.username : ''
    };
};  

export default connect(mapStateToProps)(Layout);