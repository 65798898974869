import React, { useEffect, useState } from 'react'


import Select from 'react-select'
import { Row, Col } from 'react-bootstrap';

import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';


const Filtro = (props) => {
    
    const [ selectedOption, setSelectedOption ] = useState('')

    const minutes = 1; //update time in minutes

    useEffect(() => {
        props.onGetDevices(props.role, props.userMail, [], props.selectedState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cc]);

    useEffect(() => {
        const interval = setInterval(() => {
            props.onGetDevices(props.role, props.userMail, [], props.selectedState);
        }, minutes * 60 * 1000) //minutes * seconds * miliseconds
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedState]);


    const handleChange = (selectedOption) => {
        if (selectedOption) {
            //console.log(`Selected Label: ${selectedOption.label}`);
            //console.log(`Selected Value: ${selectedOption.value}`);
            let tempDevice = props.devices.filter(device => selectedOption.value === device.deviceId)
            if(tempDevice.length > 0) {
                props.onSetSelectedDevice(tempDevice[0])
            }
        }
    };

    const processUserInput = (e) => {
        const newVal = e.replace(/\s/g, '');
        setSelectedOption(newVal)
        return newVal
    }

    /*
    const resetFiltri = () => {
        props.onSetSelectedState(0)
        props.onGetDevices(props.role, props.userMail, [], 0);
    }
    */

    return (
        <>

            <Row>
                <Col>
                    <Select
                        placeholder="Seleziona veicolo"
                        options={props.selectOptions}
                        onChange={(e) => handleChange(e)}
                        onInputChange={(e) => processUserInput(e)}
                        inputValue={selectedOption}
                    />
                </Col>
            </Row>

        </>
    )
}


const mapStateToProps = state => {
    
    return {
        role: state.auth.tokenDecoded.role,
        userMail: state.auth.tokenDecoded.username,
        devices: state.posizioni.devices,
        selectOptions: state.posizioni.selectOptions,
        cc: state.posizioni.cc,
        reparti: state.posizioni.reparti,
        ccDevicesCounter: state.posizioni.ccDevicesCounter,
        selectedState: state.posizioni.selectedState
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onGetDevices: (role, userMail, mailList, selectedState) => dispatch(actions.getDevices(role, userMail, mailList, selectedState)),
        onSetSelectedDevice: (selectedDevice) => dispatch(actions.setSelectedDevice(selectedDevice)),
        onSetReduceMailList: (mailList) => dispatch(actions.setReduceMailList(mailList)),
        onSetSelectedState: (selectedState) => dispatch(actions.setSelectedState(selectedState))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtro);
