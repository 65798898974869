import * as actionTypes from './actionTypes';
import axios from 'axios';
import moment from 'moment'

export const fetchInterventiOk = (data) => {
    return {
        type: actionTypes.INTERVENTI_FETCH_INTERVENTI_OK,
        interventi: data
    }
}

export const fetchInterventiFail = (data) => {
    return {
        type: actionTypes.INTERVENTI_FETCH_INTERVENTI_FAIL,
        error: data
    }
}

export const fetchOperationsOk = (data) => {
    const temp = data.sort(function(a, b) {
        if(a.descrizione_operazione < b.descrizione_operazione) { return -1 }
        if(a.descrizione_operazione > b.descrizione_operazione) { return 1 }
        return false;
    });

    return {
        type: actionTypes.INTERVENTI_FETCH_TIPI_INTERVENTI_OK,
        tipiInterventi: temp
    }
}

export const fetchOperationsFail = (err) => {
    return {
        type: actionTypes.INTERVENTI_FETCH_TIPI_INTERVENTI_FAIL,
        error: err
    }
}

export const getOperations = () => {
    return dispatch => { 
        axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/operations', {
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            if(res.data.retCode === "000") {
                /*
                let tempOperations = []
                tempOperations.push({label: 'Altro', value: 0})
                res.data.info.map(op => {
                    tempOperations.push({
                        value: op.operazione_id,
                        label: op.descrizione_operazione
                    })
                })
                const temp = tempOperations.sort(function(a, b) {
                    if(a.label < b.label) { return -1 }
                    if(a.label > b.label) { return 1 }
                    return false;
                });

                //setOperationOptions([...temp])
                */
                dispatch(fetchOperationsOk(res.data.info))
            }
        }).catch(err => {
            console.error('/operations request fail: ' + err);
            dispatch(fetchOperationsFail(err))
        });
    }
}

export const fetchModelliOk = (data) => {
    /*
    const temp = data.sort(function(a, b) {
        if(a.descrizione_operazione < b.descrizione_operazione) { return -1 }
        if(a.descrizione_operazione > b.descrizione_operazione) { return 1 }
        return false;
    });
    */

    let modelliOptions = [{label: 'Tutti i modelli', value: 0}];
    for (var i in data) {
        var m = data[i];
        modelliOptions.push({
            value: m.car_id,
            label: m.marca + ' ' + m.modello + ' (' + m.posti + ' posti)'
        });
    }
    //console.log(modelliOptions)

    return {
        type: actionTypes.INTERVENTI_FETCH_MODELLI_OK,
        modelliOptions: modelliOptions
    }
}

export const fetchModelliFail = (err) => {
    return {
        type: actionTypes.INTERVENTI_FETCH_MODELLI_FAIL,
        error: err
    }
}

export const getModelli = () => {
    return dispatch => { 
        axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/modelli', {
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            if(res.data.retCode === "000") {
                dispatch(fetchModelliOk(res.data.info))
            }
        }).catch(err => {
            console.error('/operations request fail: ' + err);
            dispatch(fetchModelliFail(err))
        });
    }
}



export const cercaInterventi = (targa = 0, dataStart, dataEnd, interventoType = 0, sortingType = 0, authorizationType = 0, modelloType = 0, numPratica = 0, codeType = 0) => {
    const tempdataStart = moment(dataStart).format('YYYY-MM-DD')
    const tempdataEnd = moment(dataEnd).format('YYYY-MM-DD')
    let data = {
        "targa": targa && targa.value != 0 ? targa.label.split(" ")[0]: null,
        "data_inizio": tempdataStart,
        "data_fine": tempdataEnd,
        "intervento_type": interventoType? interventoType.value : null,
        "sorting_type": sortingType? sortingType.value : null,
        "authorization_type": authorizationType? authorizationType.value : null,
        "modello_type": modelloType? modelloType.value : null,
        "numPratica": numPratica? numPratica : null,
        "codeType": codeType? codeType.value : null
    }
    return dispatch => { 
        axios.post(process.env.REACT_APP_GEB_MIDDLEWARE + '/interventi', data)
        .then(response => {
            if(response.data.retCode === "000") {
                dispatch(fetchInterventiOk(response.data.info))
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(fetchInterventiFail(err))
        })
    }
}


export const setFilterTarga = (targa) => {
    return {
        type: actionTypes.INTERVENTI_FILTER_SET_TARGA,
        filterTarga: targa
    };
}

export const setFilterDateStart = (filterDateStart) => {
    return {
        type: actionTypes.INTERVENTI_FILTER_SET_DATE_START,
        filterDateStart: filterDateStart
    };
}

export const setFilterDateEnd = (filterDateEnd) => {
    return {
        type: actionTypes.INTERVENTI_FILTER_SET_DATE_END,
        filterDateEnd: filterDateEnd
    };
}

export const setFilterIntervento = (filterIntervento) => {
    return {
        type: actionTypes.INTERVENTI_FILTER_SET_INTERVENTO,
        filterIntervento: filterIntervento
    };
}

export const setFilterSorting = (filterSorting) => {
    return {
        type: actionTypes.INTERVENTI_FILTER_SET_SORTING,
        filterSorting: filterSorting
    };
}

export const setFilterAuthorization = (filterAuthorization) => {
    return {
        type: actionTypes.INTERVENTI_FILTER_SET_AUTHORIZATION,
        filterAuthorization: filterAuthorization
    };
}

export const setFilterModello = (filterModello) => {
    return {
        type: actionTypes.INTERVENTI_FILTER_SET_MODELLO,
        filterModello: filterModello
    };
}

export const setFilterNumPratica = (filterNumPratica) => {
    return {
        type: actionTypes.INTERVENTI_FILTER_SET_NUM_PRATICA,
        filterNumPratica: filterNumPratica
    };
}

export const setFilterCodeType = (filterCodeType) => {
    console.log(filterCodeType)
    return {
        type: actionTypes.INTERVENTI_FILTER_SET_CODETYPE,
        filterCodeType: filterCodeType
    };
}

export const setModifyModalVisibility = (showModal, modifyIntervento, interventoData) => {

    return {
        type: actionTypes.INTERVENTI_MODIFY_MODAL_VISIBILITY,
        modifyModalVisibility: showModal,
        modifyIntervento: modifyIntervento,
        //interventoFiles: interventoFiles,
        interventoData: interventoData
    };
}

export const changeInterventoAuthorization = (item, flag) => {
    return {
        type: actionTypes.INTERVENTI_CHANGE_INTERVENTO_AUTHORIZATION,
        logbook_id: item.logbook_id,
        flag: flag
    }
}