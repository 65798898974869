import React , { Fragment } from 'react';
import axios from 'axios';
import Moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/it';
import Modal from 'react-bootstrap/Modal'
import { connect } from 'react-redux';
import "bootstrap/dist/css/bootstrap.min.css";



class Notificator extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            token: props.token,
            tokenDecoded: props.tokenDecoded,
            modalVisible: null,
            notifications: [],
            current: null
        };

        this.fetchNotifications = function() {
            console.log('Requesting notifications...');

            axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/notifiche', {}, {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.data.retCode === "000") {
                    this.setState({notifications: res.data.info});

                    console.log('Start notification loop with');
                    console.log(res.data.info);

                    this.showNextNotification();
                } else
                    console.warn('/notifiche request error: ' + res.data.info.error);
            }).catch(err => {
                console.error('/notifiche request fail: ' + err);
            });
        };

        this.showNextNotification = function() {
            if (this.state.notifications.length === 0) {
                this.setState({current: null, modalVisible: null});
                console.log('No more notifications!');
                return;
            }

            var currentNotification = this.state.notifications.splice(0, 1)[0];
            this.setState({current: currentNotification, modalVisible: currentNotification.message_type.trim()});

            console.log('Current notification:');
            console.log(currentNotification);
        };

        this.dismissTransfer = function(action) {
            var message_uuid = this.state.current.message_uuid;

            console.log('Responding to transfer ' + message_uuid + ' (' + action + ')');

            axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/' + action + '/' + message_uuid, {}, {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.data.retCode === "000") {
                    console.log('Response ok!');
                    this.showNextNotification();
                } else
                    console.warn('/' + action + ' request error: ' + res.data.info.error);
            }).catch(err => {
                console.error('/' + action + ' request fail: ' + err);
            });
        };

        this.closeNotification = function() {
            var message_uuid = this.state.current.message_uuid;

            console.log('Dismiss on ' + message_uuid);

            axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/close_notification/' + message_uuid, {}, {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.data.retCode === "000") {
                    console.log('Dismiss ok!');
                    this.showNextNotification();
                } else
                    console.warn('/close_notification request error: ' + res.data.info.error);
            }).catch(err => {
                console.error('/close_notification request fail: ' + err);
            });
        };

        this.postponeNotification = function(delay) {
            var message_uuid = this.state.current.message_uuid;

            console.log('Postpone on ' + message_uuid);

            axios.get(process.env.REACT_APP_GEB_MIDDLEWARE + '/postpone_notification/' + message_uuid + '/' + delay, {}, {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.data.retCode === "000") {
                    console.log('Postpone ok!');
                    this.showNextNotification();
                } else
                    console.warn('/postpone_notification request error: ' + res.data.info.error);
            }).catch(err => {
                console.error('/postpone_notification request fail: ' + err);
            });
        };

        Moment.locale('it');
    }

    componentDidMount(pros) {
        window.addEventListener('loginSuccess', this.fetchNotifications.bind(this));

        //setTimeout(this.fetchNotifications.bind(this),3000);
    }

    componentDidUpdate(prevProps) {

    }

    render() {
        return (
            <Fragment>
                <Modal show={this.state.modalVisible === 'TR'} style={{backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000"}}>
                    <Modal.Header>
                        <Modal.Title>
                            Richiesta trasferimento mezzo
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { this.state.current && this.state.modalVisible === 'TR' ?
                            <p>
                                <b class="user">{this.state.current.json_data.mittente}</b>
                                <br />
                                ha richiesto il trasferimento del mezzo ({this.state.current.json_data.codice_mezzo}):
                                <br /><br />
                                <span class="targa">{this.state.current.json_data.targa}</span>
                            </p>
                        : ''}
                    </Modal.Body>
                    <Modal.Footer>
                        <button class="btn btn-success" onClick={this.dismissTransfer.bind(this, 'accept_transfer')}>
                            Accetta
                        </button>
                        <button class="btn btn-warning" onClick={this.dismissTransfer.bind(this, 'reject_transfer')}>
                            Rifiuta
                        </button>
                        <button class="btn btn-secondary" onClick={this.postponeNotification.bind(this, 0)}>
                            Postponi
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.modalVisible === 'TRA'} style={{backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000"}}>
                    <Modal.Header>
                        <Modal.Title>
                            Richiesta trasferimento mezzo accettata
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { this.state.current && this.state.modalVisible === 'TRA' ?
                            <p>
                                <b class="user">{this.state.current.json_data.destinatario}</b>
                                <br />
                                ha accettato il trasferimento del mezzo{this.state.current.json_data.codice_mezzo ? (' (' + this.state.current.json_data.codice_mezzo + ')') : ''}:
                                <br /><br />
                                <span class="targa">{this.state.current.json_data.targa}</span>
                            </p>
                        : ''}
                    </Modal.Body>
                    <Modal.Footer>
                        <button class="btn btn-secondary" onClick={this.closeNotification.bind(this)}>
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.modalVisible === 'TRR'} style={{backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000"}}>
                    <Modal.Header>
                        <Modal.Title>
                            Richiesta trasferimento mezzo rifiutata
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { this.state.current && this.state.modalVisible === 'TRR' ?
                            <p>
                                <b class="user">{this.state.current.json_data.cognome} {this.state.current.json_data.nome}</b>
                                <br />
                                ha rifiutato il trasferimento del mezzo ({this.state.current.json_data.codice_mezzo}):
                                <br /><br />
                                <span class="targa">{this.state.current.json_data.targa}</span>
                            </p>
                        : ''}
                    </Modal.Body>
                    <Modal.Footer>
                        <button class="btn btn-secondary" onClick={this.closeNotification.bind(this)}>
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        tokenDecoded: state.auth.tokenDecoded
    }
}

export default connect(mapStateToProps, null)(Notificator);