
//---------------------------------------------------------------------------------------------------
// ICONS
//---------------------------------------------------------------------------------------------------

import iconEmpty from "../assets/images/icons/icon_empty.png"


// VEHICLES ICONS
import iconCarOn from "../assets/images/icons/marker_car_V.png"
import iconCarOff from "../assets/images/icons/marker_car_R_195.png"

import iconFurgoneOn from "../assets/images/icons/marker_furgone_V.png"
import iconFurgoneOff from "../assets/images/icons/marker_furgone_R_195.png"

import iconMiniFurgoneOn from "../assets/images/icons/marker_mini_furgone_V.png"
import iconMiniFurgoneOff from "../assets/images/icons/marker_mini_furgone_R_195.png"

import iconMinibusOn from "../assets/images/icons/marker_minibus_V.png"
import iconMinibusOff from "../assets/images/icons/marker_minibus_R_195.png"

import iconPickUpOn from "../assets/images/icons/marker_pickup_V.png"
import iconPickUpOff from "../assets/images/icons/marker_pickup_R_195.png"

import iconTruckOn from "../assets/images/icons/marker_truck_V.png"
import iconTruckOff from "../assets/images/icons/marker_truck_R_195.png"

// SATELLITE ICONS
import satLevel_0 from "../assets/images/icons/satellite/satellite_0.png"
import satLevel_30 from "../assets/images/icons/satellite/satellite_30.png"
import satLevel_70 from "../assets/images/icons/satellite/satellite_70.png"
import satLevel_100 from "../assets/images/icons/satellite/satellite_100.png"

// GSM SIGNAL ICONS
import iconSignal_0 from "../assets/images/icons/signal/icon_signal_0.png" //TODO: Crear ICONO!
import iconSignal_1 from "../assets/images/icons/signal/icon_signal_1.png"
import iconSignal_2 from "../assets/images/icons/signal/icon_signal_2.png"
import iconSignal_3 from "../assets/images/icons/signal/icon_signal_3.png"
import iconSignal_4 from "../assets/images/icons/signal/icon_signal_4.png"
import iconSignal_5 from "../assets/images/icons/signal/icon_signal_5.png"

// BATTERY LEVEL ICONS
import iconBattery_0 from "../assets/images/icons/battery/battery_v1_0.png"
import iconBattery_20 from "../assets/images/icons/battery/battery_v2_20.png"
import iconBattery_40 from "../assets/images/icons/battery/battery_v1_40.png"
import iconBattery_60 from "../assets/images/icons/battery/battery_v1_60.png"
import iconBattery_80 from "../assets/images/icons/battery/battery_v1_80.png"
import iconBattery_100 from "../assets/images/icons/battery/battery_v1_100.png"

//START & FINISH ICONS
import iconStart from "../assets/images/icons/marker_start.png"
import iconEnd from "../assets/images/icons/marker_end.png"



//---------------------------------------------------------------------------------------------------
// FUNCTIONS
//---------------------------------------------------------------------------------------------------


    export function setVehicleIcon (device = null) {
        if(device.veicolo === null) return iconCarOn;
        switch(device.veicolo) {
            case 'Automobile':  // automobile
                return iconCarOn
            case 'Furgone': // furgone
                return iconFurgoneOn;
            case 'Mini Furgone': // mini-furgone
                return iconMiniFurgoneOn;
            case 'Minibus': // minibus
                return iconMinibusOn;
            case 'PickUp': // PickUp
                return iconPickUpOn;
            case 'Camion': // Camion
                return iconTruckOn;
            default:
                return iconCarOn;
        }
    }


    export function setTrackIcon ( marker, index , length) {
        switch(index) {
            case 0:  // Start
                return iconStart;
            case length:  // End
                return iconEnd;
            default:
                break;
        }
    }

    export function setGsmIcon (rssi = null) {
        if(!rssi) return iconSignal_0;
        if(rssi === 1) return iconSignal_1;
        else if(rssi === 2) return iconSignal_2;
        else if(rssi === 3) return iconSignal_3;
        else if(rssi === 4) return iconSignal_4;
        else if(rssi === 5) return iconSignal_5;
    }

    export function setBatteryIcon (batteryLevel) {
        const max = 4
        if (batteryLevel === undefined ) return iconBattery_0;
        
        if(batteryLevel > max ) batteryLevel = max;
        let percentage = batteryLevel* 100 / max;
        //console.log(percentage)
        if(percentage === 0) return iconBattery_0;
        else if (percentage <= 20) return iconBattery_20;
        else if (percentage <= 40) return iconBattery_40;
        else if (percentage <= 60) return iconBattery_60;
        else if (percentage <= 80) return iconBattery_80;
        else return iconBattery_100;
    }
    
    export function setSatelliteIcon (hdop) {
        if(hdop === undefined) return satLevel_0;
        else if(hdop < 2) return satLevel_0;
        else if ( hdop < 5) return satLevel_30;
        else if ( hdop < 10) return satLevel_70;
        else return satLevel_100;
    }

