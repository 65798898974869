import * as actionTypes from '../actions/actionTypes';

const initialState = {
    devices: [],
    selectOptions: [],
    cc: [],
    selectedDevice: null,
    reparti: [],
    mailList: [],
    ccDevicesCounter: [],
    selectedState: 0,
    error: null,
    customZoom: process.env.REACT_APP_POSITIONS_DEFAULT_ZOOM // Preferences

};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.POS_SUCCESS:
            return {
                ...state,
                devices: action.devices,
                selectOptions: action.selectOptions
            }
        case actionTypes.POS_FAIL:
            return {
                ...state,
                error: action.error,
                devices: [],
                selectOptions:[]
            }
        case actionTypes.CC_SUCCESS:
            return {
                ...state,
                cc: action.cc,
                reparti: action.reparti
            }
        case actionTypes.CC_FAIL:
        case actionTypes.CC_DEVICES_COUNTER_FAIL:
        case actionTypes.SET_PREFERENCES_FAIL:
            return {
                ...state,
                error: action.error
            }
        case actionTypes.FLY_TO_DEVICE:
            //console.log(action.selectedDevice)
            return {
                ...state,
                selectedDevice: action.selectedDevice
            }
        case actionTypes.SET_MAIL_LIST:
            return {
                ...state,
                mailList: action.mailList
            }
        case actionTypes.CC_DEVICES_COUNTER_SUCCESS:
            return {
                ...state,
                ccDevicesCounter: action.ccDevicesCounter
            }

        case actionTypes.SET_SELECTED_STATE:
            return {
                ...state,
                selectedState: action.selectedState
            }
        case actionTypes.SET_PREFERENCES_OK:
            return {
                ...state,
                customZoom: action.customZoom
            }
        default:
            return state;
    }
};

export default reducer;